.visible-small {
  display: none !important;
}
.visible-medium {
  display: none !important;
}
.hidden-desktop {
  display: none !important;
}
.visible-desktop {
  display: inherit !important;
}
@media only screen and (min-width: 471px) and (max-width: 700px) {
  .hidden-desktop {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important;
  }
  .visible-medium {
    display: inherit !important;
  }
  .hidden-medium {
    display: none !important;
  }
}
@media only screen and (max-width: 470px) {
  .hidden-desktop {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important;
  }
  .visible-small {
    display: inherit !important;
  }
  .hidden-small {
    display: none !important;
  }
}
.small-cuttoff-guideline {
  border-right: 1px solid red;
  width: 1px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 470px;
}
.medium-cuttoff-guideline {
  border-right: 1px solid blue;
  width: 1px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 700px;
}
.device-guideline {
  border-right: 1px solid #ddd;
  width: 2px;
  height: 100%;
  position: fixed;
  top: 0;
}
@media (min-width: 700px) {
  .right-align-subject-column-0 {
    text-align: right;
  }
  .right-align-subject-column-1 {
    text-align: right;
  }
  .right-align-subject-column-2 {
    text-align: right;
  }
  .right-align-subject-column-3 {
    text-align: right;
  }
  .right-align-subject-column-4 {
    text-align: right;
  }
  .right-align-subject-column-5 {
    text-align: right;
  }
}
/* For the GEN1 Web Link */
.equal-width-stack {
  display: inline-block;
  /* use inline-block so that it doesn't expand to entire container */
}
.equal-width-stack > .equal-width-item {
  display: block;
  margin: 0.5rem;
}
.equal-width-stack > .equal-width-container {
  display: flex;
  align-items: center;
}
.equal-width-stack > .equal-width-item > .equal-width-button,
.equal-width-stack .equal-width-container > .equal-width-button {
  width: 100%;
}
.equal-width-stack.equal-width-row > .equal-width-container {
  /* when horizontal display is desired, use inline-flex so that they will stack inline */
  display: inline-flex;
}
.equal-width-stack.equal-width-row > .equal-width-item {
  display: inline-block;
}
/* For UPM1 Comp Search */
@media (max-width: 700px) {
  /* For tables that aren't really tables. Just chop them up so they fit on the screen.*/
  .responsive-table-decorative {
    /* Break the table apart */
  }
  .responsive-table-decorative select {
    max-width: 80vw;
    text-overflow: ellipsis;
  }
  .responsive-table-decorative thead,
  .responsive-table-decorative tbody,
  .responsive-table-decorative th,
  .responsive-table-decorative tr,
  .responsive-table-decorative td {
    display: block;
  }
  .responsive-table {
    /* deal with overflowing select elements */
    /* Break the table apart */
    /* Make the head row invisible for visual users */
    /* (Non-visual users probably don't need visual responsive design.) */
    /* Border around each criterion */
    /* borders not needed within criteria */
  }
  .responsive-table select {
    max-width: 80vw;
    text-overflow: ellipsis;
  }
  .responsive-table thead,
  .responsive-table tbody,
  .responsive-table th,
  .responsive-table tr,
  .responsive-table td {
    display: block;
    background-color: white;
  }
  .responsive-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .responsive-table tr {
    border: 0.7px solid #e3e3e3;
  }
  .responsive-table tbody > tr > td,
  .responsive-table tbody > tr > th {
    border: none;
  }
  /* styling for "Use" columns in comparison/advanced search modules */
  .column-0-checkbox tr > :nth-child(1) {
    /* put on same row as label column */
    display: inline-block;
    /* float to the right */
    float: right;
    /* correct spacing */
    padding: 0px;
    margin-top: 7px;
    /* This needs to be less than 8, otherwise they behave _strangely_...*/
    margin-right: 7px;
    margin-left: 7px;
    /* no margin-bottom, otherwise they will exhibit strange stacking behavior */
  }
  .column-0-checkbox tr > :nth-child(1)::after {
    content: "Use";
  }
  .column-0-checkbox tr > :nth-child(1).no-checkbox::after {
    content: none;
  }
  .column-1-checkbox tr > :nth-child(2) {
    /* put on same row as label column */
    display: inline-block;
    /* float to the right */
    float: right;
    /* correct spacing */
    padding: 0px;
    margin-top: 7px;
    /* This needs to be less than 8, otherwise they behave _strangely_...*/
    margin-right: 7px;
    margin-left: 7px;
    /* no margin-bottom, otherwise they will exhibit strange stacking behavior */
  }
  .column-1-checkbox tr > :nth-child(2)::after {
    content: "Use";
  }
  .column-1-checkbox tr > :nth-child(2).no-checkbox::after {
    content: none;
  }
  .column-2-checkbox tr > :nth-child(3) {
    /* put on same row as label column */
    display: inline-block;
    /* float to the right */
    float: right;
    /* correct spacing */
    padding: 0px;
    margin-top: 7px;
    /* This needs to be less than 8, otherwise they behave _strangely_...*/
    margin-right: 7px;
    margin-left: 7px;
    /* no margin-bottom, otherwise they will exhibit strange stacking behavior */
  }
  .column-2-checkbox tr > :nth-child(3)::after {
    content: "Use";
  }
  .column-2-checkbox tr > :nth-child(3).no-checkbox::after {
    content: none;
  }
  .column-3-checkbox tr > :nth-child(4) {
    /* put on same row as label column */
    display: inline-block;
    /* float to the right */
    float: right;
    /* correct spacing */
    padding: 0px;
    margin-top: 7px;
    /* This needs to be less than 8, otherwise they behave _strangely_...*/
    margin-right: 7px;
    margin-left: 7px;
    /* no margin-bottom, otherwise they will exhibit strange stacking behavior */
  }
  .column-3-checkbox tr > :nth-child(4)::after {
    content: "Use";
  }
  .column-3-checkbox tr > :nth-child(4).no-checkbox::after {
    content: none;
  }
  .column-4-checkbox tr > :nth-child(5) {
    /* put on same row as label column */
    display: inline-block;
    /* float to the right */
    float: right;
    /* correct spacing */
    padding: 0px;
    margin-top: 7px;
    /* This needs to be less than 8, otherwise they behave _strangely_...*/
    margin-right: 7px;
    margin-left: 7px;
    /* no margin-bottom, otherwise they will exhibit strange stacking behavior */
  }
  .column-4-checkbox tr > :nth-child(5)::after {
    content: "Use";
  }
  .column-4-checkbox tr > :nth-child(5).no-checkbox::after {
    content: none;
  }
  .column-5-checkbox tr > :nth-child(6) {
    /* put on same row as label column */
    display: inline-block;
    /* float to the right */
    float: right;
    /* correct spacing */
    padding: 0px;
    margin-top: 7px;
    /* This needs to be less than 8, otherwise they behave _strangely_...*/
    margin-right: 7px;
    margin-left: 7px;
    /* no margin-bottom, otherwise they will exhibit strange stacking behavior */
  }
  .column-5-checkbox tr > :nth-child(6)::after {
    content: "Use";
  }
  .column-5-checkbox tr > :nth-child(6).no-checkbox::after {
    content: none;
  }
  /* styling for the "Category" columns in advanced/comparison search modules */
  .column-0-field tr > :nth-child(1) {
    /* put on same row as checkbox column, if it exists */
    display: inline-block;
  }
  .column-1-field tr > :nth-child(2) {
    /* put on same row as checkbox column, if it exists */
    display: inline-block;
  }
  .column-2-field tr > :nth-child(3) {
    /* put on same row as checkbox column, if it exists */
    display: inline-block;
  }
  .column-3-field tr > :nth-child(4) {
    /* put on same row as checkbox column, if it exists */
    display: inline-block;
  }
  .column-4-field tr > :nth-child(5) {
    /* put on same row as checkbox column, if it exists */
    display: inline-block;
  }
  .column-5-field tr > :nth-child(6) {
    /* put on same row as checkbox column, if it exists */
    display: inline-block;
  }
  /* styling for the input elements in advanced/comparison search modules */
  .column-0-inputs tr > :nth-child(1) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-0-inputs tr.hidden-criterion > :nth-child(1) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
  .column-1-inputs tr > :nth-child(2) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-1-inputs tr.hidden-criterion > :nth-child(2) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
  .column-2-inputs tr > :nth-child(3) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-2-inputs tr.hidden-criterion > :nth-child(3) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
  .column-3-inputs tr > :nth-child(4) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-3-inputs tr.hidden-criterion > :nth-child(4) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
  .column-4-inputs tr > :nth-child(5) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-4-inputs tr.hidden-criterion > :nth-child(5) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
  .column-5-inputs tr > :nth-child(6) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-5-inputs tr.hidden-criterion > :nth-child(6) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
  /* styling for the "Criteria for subject" content in comparison search modules */
  .column-0-subject tr > :nth-child(1) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-0-subject tr > :nth-child(1)::before {
    content: "Criteria for subject: ";
  }
  .column-0-subject tr.hidden-criterion > :nth-child(1) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
  .column-1-subject tr > :nth-child(2) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-1-subject tr > :nth-child(2)::before {
    content: "Criteria for subject: ";
  }
  .column-1-subject tr.hidden-criterion > :nth-child(2) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
  .column-2-subject tr > :nth-child(3) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-2-subject tr > :nth-child(3)::before {
    content: "Criteria for subject: ";
  }
  .column-2-subject tr.hidden-criterion > :nth-child(3) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
  .column-3-subject tr > :nth-child(4) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-3-subject tr > :nth-child(4)::before {
    content: "Criteria for subject: ";
  }
  .column-3-subject tr.hidden-criterion > :nth-child(4) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
  .column-4-subject tr > :nth-child(5) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-4-subject tr > :nth-child(5)::before {
    content: "Criteria for subject: ";
  }
  .column-4-subject tr.hidden-criterion > :nth-child(5) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
  .column-5-subject tr > :nth-child(6) {
    /* use color to indicate that this is the content, and not the subject, of a row */
    background-color: #f5f5f5;
  }
  .column-5-subject tr > :nth-child(6)::before {
    content: "Criteria for subject: ";
  }
  .column-5-subject tr.hidden-criterion > :nth-child(6) {
    /* hide this content if it is supposed to be hidden */
    display: none;
  }
}
