.photo-thumbnail,
.gen-image-thumbnail {
  float: left;
  margin: 10px;
  position: relative;
}
@media only screen and (max-width: 470px) {
  .photo-thumbnail,
  .gen-image-thumbnail {
    float: none;
  }
}
.photo-thumbnail img,
.gen-image-thumbnail img {
  height: 150px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid transparent;
}
.photo-thumbnail img:hover,
.gen-image-thumbnail img:hover {
  border-color: #aaa;
}
@media only screen and (max-width: 470px) {
  .photo-thumbnail img,
  .gen-image-thumbnail img {
    height: auto;
    width: 100%;
  }
}
.photo-thumbnail-caption {
  padding: 4px;
  text-align: center;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  font-weight: bold;
}
.photo-thumbnail-caption:empty {
  display: none;
}
.sketch-thumbnail {
  float: left;
  margin: 10px;
  position: relative;
}
@media only screen and (max-width: 470px) {
  .sketch-thumbnail {
    float: none;
  }
}
.sketch-thumbnail img {
  height: 150px;
  border-radius: 5px;
  cursor: pointer;
  padding-bottom: 15px;
  border: 1px solid #bbb;
}
.sketch-thumbnail img:hover {
  border-color: #999;
}
@media only screen and (max-width: 470px) {
  .sketch-thumbnail img {
    height: auto;
    width: 100%;
  }
}
@media only print {
  .sketch-thumbnail img {
    display: block;
    page-break-before: always;
    height: auto;
    width: 560px;
  }
}
.sketch-thumbnail-caption-print {
  padding: 4px;
  text-align: center;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  font-weight: bold;
}
.sketch-thumbnail-caption-print:empty {
  display: none;
}
@media only screen {
  .sketch-thumbnail-caption-print {
    display: none;
  }
}
.sketch-thumbnail-caption {
  padding: 4px;
  text-align: center;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  font-weight: bold;
}
.sketch-thumbnail-caption:empty {
  display: none;
}
@media only print {
  .sketch-thumbnail-caption {
    display: none;
  }
}
.rsDefault .rsGCaption {
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
}
.rsDefault .rsGCaption:empty {
  display: none;
}
.primary-photo-rs {
  width: 256px;
  height: auto;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.primary-photo-rs img {
  width: inherit;
}
@media only screen and (min-width: 700px) {
  .primary-photo-rs {
    float: right !important;
  }
}
@media print {
  .primary-photo-rs {
    float: right !important;
  }
}
.primary-photo-rs-print {
  width: 256px;
  height: auto;
  margin-bottom: 12px;
  float: right !important;
}
.primary-photo-rs-print img {
  width: inherit;
}
