﻿@import "Variables.less";
@import "Utilities.less";

.photo-thumbnail, .gen-image-thumbnail {
    float: left;
    margin: 10px;
    position: relative;

    @media only screen and (max-width: @small-layout-width) {
        float: none;
    }

    img {
        height: 150px;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid transparent;

        &:hover {
            border-color: #aaa;
        }

        @media only screen and (max-width: @small-layout-width) {
            height: auto;
            width: 100%;
        }
    }
}

.photo-thumbnail-caption {
    padding: 4px;
    text-align: center;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    .white-background-50();
    //color: #FFF;
    font-weight: bold;

    &:empty {
        display: none;
    }
}


.sketch-thumbnail {
    float: left;
    margin: 10px;
    position: relative;

    @media only screen and (max-width: @small-layout-width) {
        float: none;
    }

    img {
        height: 150px;
        border-radius: 5px;
        cursor: pointer;
        padding-bottom: 15px;
        border: 1px solid #bbb;

        &:hover {
            border-color: #999;
        }

        @media only screen and (max-width: @small-layout-width) {
            height: auto;
            width: 100%;
        }

        @media only print {
            display: block;
            page-break-before: always;
            height: auto;
            width: 560px;
        }
    }
}
.sketch-thumbnail-caption-print {
    padding: 4px;
    text-align: center;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    .white-background-50();
    //color: #FFF;
    font-weight: bold;

    &:empty {
        display: none;
    }

    @media only screen  {
        display: none
    }
}
.sketch-thumbnail-caption {
    padding: 4px;
    text-align: center;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    .white-background-50();
    //color: #FFF;
    font-weight: bold;

    &:empty {
        display: none;
    }

    @media only print {
        display: none;
    }


}


// additional styles to enhance royalslider css
// move global caption to the line right above the bullets
.rsDefault .rsGCaption {
    left: 0;
    right: 0;
    bottom: 20px;
    text-align: center;

    &:empty {
        display: none;
    }
}

.primary-photo-rs {
    width: 256px;
    height: auto;
    margin-bottom: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    img {
        width: inherit;
    }

    @media only screen and (min-width: (@medium-layout-width)) {
        float: right !important;
    }

    @media print {
        float: right !important;
    }
}

.primary-photo-rs-print {
    width: 256px;
    height: auto;
    margin-bottom: 12px;
    float: right !important;
    
    img {
        width: inherit;
    }
}
