﻿@import "Variables.less";
.notification-constraints {
    .headerrow {}
    .detailsrow td{}
}
.notification{
.notification-title {
    /*    font-family: lato,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 17px;
    color: #333333;
    font-weight: normal;
    display: flex;*/
    display:inline-block;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    width:250px;
}
.like-section {
    min-width: 300px;
}
.trashed {
    display: none;
}
.like-failure {
    margin-right: 6px;
    color: @error-red;
}
.notification-like {
   // float: right;
   font-size:large;

}
/*.notification-trash { display:block; min-width:300px;}*/
.notification-card {
    padding: 2px;
    cursor: pointer;
    box-shadow: 0 1px 4px 0 rgb(0, 0, 0);
    border-radius: 3px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.subject-top {
    background-color: #E7E9EB;
}
.notification-content-text {
    padding: 2px;
}

.notification-data {
    position: relative;
    width: calc(100% - 52px);
}

.notification-data-text {
    z-index: 500;
}

.dropdown-menu {
    max-height: 540px;
    overflow-y: auto;
}

.dropdown-menu.unread .notification-content-text {
   // margin-left: 20px;
    width: 100%
}

.dropdown-submenu.unread .notification-card .subject-top {
    background-image: url('../../icons/Notifications/icon-unread-dot.png');
    background-color: #E7E9EB;
    background-position: left top;
    background-repeat:no-repeat;
    background-size:7px;


}

.dropdown-submenu {
    position: relative;
    min-width: 300px;
}

ul.dropdown-submenu {
    box-sizing: border-box;
    flex-basis: 100%;
    color: rgb(23, 43, 77);
    /*border: 1px solid rgba(9, 30, 66, 0.08);*/
    border-radius: 3px;
    padding: 2px;
    word-break: break-word;
}

ul.dropdown-submenu:hover {
    background-color: rgba(9, 30, 66, 0.04);
}

.dropdown-menu.unread ul.no-bullets {
    margin-left: 20px;
}

ul.no-bullets {
    list-style-type: none; /* Remove bullets */
    padding-inline-start: 15px;
}

.notification-item {
    cursor: pointer;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
    border-radius: 3px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right:20px;
    margin-left:10px;
}
#notificationDropDown  ul a, a:visited, a:active {
    text-decoration: underline;
    color: @link-color;
}

}