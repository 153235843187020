table {
  border-color: #949494;
}
.normal-font-label {
  font-weight: normal;
}
.tabular-data {
  width: 100%;
  margin-bottom: 14px;
}
.tabular-data th,
.tabular-data td {
  padding: 3px 5px;
  line-height: 14px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #949494;
}
.tabular-data th[align='right'],
.tabular-data td[align='right'] {
  text-align: right;
}
.tabular-data th[align='center'],
.tabular-data td[align='center'] {
  text-align: center;
}
.tabular-data thead th,
.tabular-data tfoot th {
  font-weight: bold;
}
.tabular-data tbody th {
  font-weight: normal;
}
.tabular-data tbody tr.sdw-grid-bold th {
  font-weight: bold;
}
.tabular-data thead th {
  vertical-align: bottom;
}
.tabular-data caption + thead tr:first-child th,
.tabular-data caption + thead tr:first-child td,
.tabular-data colgroup + thead tr:first-child th,
.tabular-data colgroup + thead tr:first-child td,
.tabular-data thead:first-child tr:first-child th,
.tabular-data thead:first-child tr:first-child td {
  border-top: 0;
}
.tabular-data tbody + tbody {
  border-top: 2px solid #949494;
}
.tabular-data .tabular-data {
  background-color: white;
}
.tabular-data tbody tr:hover > td,
.tabular-data tbody tr:hover > th {
  background-color: #f5f5f5;
}
.tabular-data tbody tr.keyvalue > td,
.tabular-data tbody tr.keyvalue > th {
  background-color: #ffffc2;
}
.tabular-data tbody tr.keyvalue:hover > td,
.tabular-data tbody tr.keyvalue:hover > th {
  background-color: #ffff80;
}
.tabular-data th.value-column,
.tabular-data td.value-column {
  width: 15%;
  text-align: right;
}
/*table[ShowPointerOverRows] tbody tr
{
    cursor: pointer;
}*/
tr.double-bottom-line {
  border-bottom-style: double;
  border-bottom-width: 3px;
}
tr.double-total-line,
tr.double-total-line th {
  border-top-style: double;
  border-top-width: 3px;
  font-weight: bold;
}
tr.single-total-line {
  border-top-style: solid;
  border-top-width: 2px;
  font-weight: bold;
}
tr.sdw-grid-bold {
  font-weight: bold;
}
tr.sdw-grid-italic {
  font-style: italic;
}
tr.sdw-grid-gray {
  background-color: whitesmoke;
}
tr.sdw-grid-doubleline-top {
  border-top-style: double;
  border-top-width: 3px;
}
tr.sdw-grid-doubleline-bottom {
  border-bottom-style: double;
  border-bottom-width: 3px;
}
tr.sdw-grid-singleline-top {
  border-top-style: solid;
  border-top-width: 2px;
}
tr.sdw-grid-singleline-bottom {
  border-bottom-style: solid;
  border-bottom-width: 2px;
}
table.tabular-data-small {
  width: 100%;
  max-width: 400px;
}
/* for classic key/value tables - will shift 2nd col under first on small devices */
table.tabular-data-two-column td,
table.tabular-data-two-column th {
  vertical-align: top;
}
table.tabular-data-two-column td:first-child,
table.tabular-data-two-column th:first-child {
  font-weight: bold;
  padding-right: 10px;
}
@media only screen and (min-width: 700px) {
  table.tabular-data-two-column td:first-child {
    width: 170px;
  }
}
@media only screen and (max-width: 700px) {
  table.tabular-data-two-column td:first-child {
    width: 120px;
  }
}
@media only screen and (max-width: 470px) {
  table.tabular-data-two-column td {
    display: block;
    text-align: left;
  }
  table.tabular-data-two-column td:not(:first-child) {
    padding-left: 10px;
  }
  table.tabular-data-two-column td:last-child {
    margin-bottom: 5px;
  }
}
table.tabular-data-two-column-no-wrap td {
  vertical-align: top;
}
table.tabular-data-two-column-no-wrap td:first-child {
  font-weight: bold;
  padding-right: 10px;
  width: 200px;
}
table.tabular-data-two-column-no-wrap td td:not(:first-child) {
  padding-left: 10px;
}
table.tabular-data-two-column-no-wrap td td:last-child {
  margin-bottom: 5px;
}
.important-note {
  color: #DC2A2A;
}
.tabular-data-striped tbody > tr:nth-child(odd) > td,
.tabular-data-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}
tr.table-row-accent {
  background-color: #f9f9f9;
}
.module-content .tabular-data {
  clear: both;
}
.footable-column-picker-btn {
  float: right;
}
@media print {
  .footable-column-picker-btn {
    display: none;
  }
}
.footable-column-picker-btn span.glyphicon-ok {
  margin-left: -13px;
}
.footable-column-picker-btn span.glyphicon-list {
  font-size: 8px;
}
.footable-chart-btn {
  border: none;
  background-color: transparent;
  padding: 0 3px;
  margin-bottom: 10px;
}
@media print {
  .footable-chart-btn {
    display: none;
  }
}
.footable-chart-btn .icon {
  top: 1.5px;
}
.footable.breakpoint > tbody > tr > td > .footable-toggle,
.footable.breakpoint > tbody > tr > th > .footable-toggle {
  padding-right: 5px;
  font-size: 11px;
  line-height: 13px;
  vertical-align: middle;
  margin-left: -18px;
  text-decoration: none;
}
@media only screen and (max-width: 700px) {
  table.stack-table-medium > tbody > tr > td,
  table.stack-table-medium > tr > td {
    display: block;
    text-align: left;
  }
}
@media only screen and (max-width: 470px) {
  table.stack-table-small > tbody > tr > td,
  table.stack-table-small > tr > td {
    display: block;
    text-align: left;
  }
  table.stack-table-small {
    *zoom: 1;
  }
  table.stack-table-small:before,
  table.stack-table-small:after {
    display: table;
    content: "";
    line-height: 0;
  }
  table.stack-table-small:after {
    clear: both;
  }
  table.stack-table-small:before,
  table.stack-table-small:after {
    display: table;
    content: " ";
  }
  table.stack-table-small:after {
    clear: both;
  }
  table.stack-table-small > tbody > tr > td:not(:first-child),
  table.stack-table-small > tr > td:not(:first-child) {
    padding-left: 10px;
  }
  table.stack-table-small > tbody > tr > td:last-child,
  table.stack-table-small > tr > td:last-child {
    margin-bottom: 5px;
  }
}
.block-row {
  margin-right: -5px;
  margin-left: -5px;
  *zoom: 1;
}
.block-row:before,
.block-row:after {
  display: table;
  content: "";
  line-height: 0;
}
.block-row:after {
  clear: both;
}
.block-row:before,
.block-row:after {
  display: table;
  content: " ";
}
.block-row:after {
  clear: both;
}
@media only print {
  .two-column-blocks {
    position: relative;
    float: left;
    width: 50%;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media only screen and (min-width: 471px) {
  .two-column-blocks {
    position: relative;
    float: left;
    width: 50%;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media only screen and (max-width: 470px) {
  .two-column-blocks {
    margin-bottom: 12px;
  }
}
@media only screen, print and (min-width: 471px) {
  .three-column-blocks {
    position: relative;
    float: left;
    width: 33.33333333%;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media only screen, print and (max-width: 470px) {
  .three-column-blocks {
    margin-bottom: 12px;
  }
}
@media only screen and (min-width: 700px) {
  .four-column-blocks {
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media only screen and (min-width: 700px) and (min-width: 700px) {
  .four-column-blocks {
    float: left;
    width: 25%;
  }
}
@media only screen and (max-width: 700px) {
  .four-column-blocks {
    margin-bottom: 12px;
  }
}
table.footable {
  display: none;
}
table.footable.footable-loaded {
  display: table;
}
table.footable.ft-hide-0 > tbody > tr > :nth-child(1),
table.footable.ft-hide-0 > thead > tr > :nth-child(1),
table.footable.ft-hide-0 > tfoot > tr > :nth-child(1) {
  display: none;
}
table.footable.ft-hide-1 > tbody > tr > :nth-child(2),
table.footable.ft-hide-1 > thead > tr > :nth-child(2),
table.footable.ft-hide-1 > tfoot > tr > :nth-child(2) {
  display: none;
}
table.footable.ft-hide-2 > tbody > tr > :nth-child(3),
table.footable.ft-hide-2 > thead > tr > :nth-child(3),
table.footable.ft-hide-2 > tfoot > tr > :nth-child(3) {
  display: none;
}
table.footable.ft-hide-3 > tbody > tr > :nth-child(4),
table.footable.ft-hide-3 > thead > tr > :nth-child(4),
table.footable.ft-hide-3 > tfoot > tr > :nth-child(4) {
  display: none;
}
table.footable.ft-hide-4 > tbody > tr > :nth-child(5),
table.footable.ft-hide-4 > thead > tr > :nth-child(5),
table.footable.ft-hide-4 > tfoot > tr > :nth-child(5) {
  display: none;
}
table.footable.ft-hide-5 > tbody > tr > :nth-child(6),
table.footable.ft-hide-5 > thead > tr > :nth-child(6),
table.footable.ft-hide-5 > tfoot > tr > :nth-child(6) {
  display: none;
}
table.footable.ft-hide-6 > tbody > tr > :nth-child(7),
table.footable.ft-hide-6 > thead > tr > :nth-child(7),
table.footable.ft-hide-6 > tfoot > tr > :nth-child(7) {
  display: none;
}
table.footable.ft-hide-7 > tbody > tr > :nth-child(8),
table.footable.ft-hide-7 > thead > tr > :nth-child(8),
table.footable.ft-hide-7 > tfoot > tr > :nth-child(8) {
  display: none;
}
table.footable.ft-hide-8 > tbody > tr > :nth-child(9),
table.footable.ft-hide-8 > thead > tr > :nth-child(9),
table.footable.ft-hide-8 > tfoot > tr > :nth-child(9) {
  display: none;
}
table.footable.ft-hide-9 > tbody > tr > :nth-child(10),
table.footable.ft-hide-9 > thead > tr > :nth-child(10),
table.footable.ft-hide-9 > tfoot > tr > :nth-child(10) {
  display: none;
}
table.footable.ft-hide-10 > tbody > tr > :nth-child(11),
table.footable.ft-hide-10 > thead > tr > :nth-child(11),
table.footable.ft-hide-10 > tfoot > tr > :nth-child(11) {
  display: none;
}
table.footable.ft-hide-11 > tbody > tr > :nth-child(12),
table.footable.ft-hide-11 > thead > tr > :nth-child(12),
table.footable.ft-hide-11 > tfoot > tr > :nth-child(12) {
  display: none;
}
table.footable.ft-hide-12 > tbody > tr > :nth-child(13),
table.footable.ft-hide-12 > thead > tr > :nth-child(13),
table.footable.ft-hide-12 > tfoot > tr > :nth-child(13) {
  display: none;
}
table.footable.ft-hide-13 > tbody > tr > :nth-child(14),
table.footable.ft-hide-13 > thead > tr > :nth-child(14),
table.footable.ft-hide-13 > tfoot > tr > :nth-child(14) {
  display: none;
}
table.footable.ft-hide-14 > tbody > tr > :nth-child(15),
table.footable.ft-hide-14 > thead > tr > :nth-child(15),
table.footable.ft-hide-14 > tfoot > tr > :nth-child(15) {
  display: none;
}
table.footable.ft-hide-15 > tbody > tr > :nth-child(16),
table.footable.ft-hide-15 > thead > tr > :nth-child(16),
table.footable.ft-hide-15 > tfoot > tr > :nth-child(16) {
  display: none;
}
table.footable.ft-hide-16 > tbody > tr > :nth-child(17),
table.footable.ft-hide-16 > thead > tr > :nth-child(17),
table.footable.ft-hide-16 > tfoot > tr > :nth-child(17) {
  display: none;
}
table.footable.ft-hide-17 > tbody > tr > :nth-child(18),
table.footable.ft-hide-17 > thead > tr > :nth-child(18),
table.footable.ft-hide-17 > tfoot > tr > :nth-child(18) {
  display: none;
}
table.footable.ft-hide-18 > tbody > tr > :nth-child(19),
table.footable.ft-hide-18 > thead > tr > :nth-child(19),
table.footable.ft-hide-18 > tfoot > tr > :nth-child(19) {
  display: none;
}
table.footable.ft-hide-19 > tbody > tr > :nth-child(20),
table.footable.ft-hide-19 > thead > tr > :nth-child(20),
table.footable.ft-hide-19 > tfoot > tr > :nth-child(20) {
  display: none;
}
table.footable.ft-hide-20 > tbody > tr > :nth-child(21),
table.footable.ft-hide-20 > thead > tr > :nth-child(21),
table.footable.ft-hide-20 > tfoot > tr > :nth-child(21) {
  display: none;
}
table.footable.ft-hide-21 > tbody > tr > :nth-child(22),
table.footable.ft-hide-21 > thead > tr > :nth-child(22),
table.footable.ft-hide-21 > tfoot > tr > :nth-child(22) {
  display: none;
}
table.footable.ft-hide-22 > tbody > tr > :nth-child(23),
table.footable.ft-hide-22 > thead > tr > :nth-child(23),
table.footable.ft-hide-22 > tfoot > tr > :nth-child(23) {
  display: none;
}
table.footable.ft-hide-23 > tbody > tr > :nth-child(24),
table.footable.ft-hide-23 > thead > tr > :nth-child(24),
table.footable.ft-hide-23 > tfoot > tr > :nth-child(24) {
  display: none;
}
table.footable.ft-hide-24 > tbody > tr > :nth-child(25),
table.footable.ft-hide-24 > thead > tr > :nth-child(25),
table.footable.ft-hide-24 > tfoot > tr > :nth-child(25) {
  display: none;
}
table.footable.ft-hide-25 > tbody > tr > :nth-child(26),
table.footable.ft-hide-25 > thead > tr > :nth-child(26),
table.footable.ft-hide-25 > tfoot > tr > :nth-child(26) {
  display: none;
}
table.footable.ft-hide-26 > tbody > tr > :nth-child(27),
table.footable.ft-hide-26 > thead > tr > :nth-child(27),
table.footable.ft-hide-26 > tfoot > tr > :nth-child(27) {
  display: none;
}
table.footable.ft-hide-27 > tbody > tr > :nth-child(28),
table.footable.ft-hide-27 > thead > tr > :nth-child(28),
table.footable.ft-hide-27 > tfoot > tr > :nth-child(28) {
  display: none;
}
table.footable.ft-hide-28 > tbody > tr > :nth-child(29),
table.footable.ft-hide-28 > thead > tr > :nth-child(29),
table.footable.ft-hide-28 > tfoot > tr > :nth-child(29) {
  display: none;
}
table.footable.ft-hide-29 > tbody > tr > :nth-child(30),
table.footable.ft-hide-29 > thead > tr > :nth-child(30),
table.footable.ft-hide-29 > tfoot > tr > :nth-child(30) {
  display: none;
}
table.footable.ft-hide-30 > tbody > tr > :nth-child(31),
table.footable.ft-hide-30 > thead > tr > :nth-child(31),
table.footable.ft-hide-30 > tfoot > tr > :nth-child(31) {
  display: none;
}
table.footable.ft-hide-31 > tbody > tr > :nth-child(32),
table.footable.ft-hide-31 > thead > tr > :nth-child(32),
table.footable.ft-hide-31 > tfoot > tr > :nth-child(32) {
  display: none;
}
table.footable.ft-hide-32 > tbody > tr > :nth-child(33),
table.footable.ft-hide-32 > thead > tr > :nth-child(33),
table.footable.ft-hide-32 > tfoot > tr > :nth-child(33) {
  display: none;
}
table.footable.ft-hide-33 > tbody > tr > :nth-child(34),
table.footable.ft-hide-33 > thead > tr > :nth-child(34),
table.footable.ft-hide-33 > tfoot > tr > :nth-child(34) {
  display: none;
}
table.footable.ft-hide-34 > tbody > tr > :nth-child(35),
table.footable.ft-hide-34 > thead > tr > :nth-child(35),
table.footable.ft-hide-34 > tfoot > tr > :nth-child(35) {
  display: none;
}
table.footable.ft-hide-35 > tbody > tr > :nth-child(36),
table.footable.ft-hide-35 > thead > tr > :nth-child(36),
table.footable.ft-hide-35 > tfoot > tr > :nth-child(36) {
  display: none;
}
table.footable.ft-hide-36 > tbody > tr > :nth-child(37),
table.footable.ft-hide-36 > thead > tr > :nth-child(37),
table.footable.ft-hide-36 > tfoot > tr > :nth-child(37) {
  display: none;
}
table.footable.ft-hide-37 > tbody > tr > :nth-child(38),
table.footable.ft-hide-37 > thead > tr > :nth-child(38),
table.footable.ft-hide-37 > tfoot > tr > :nth-child(38) {
  display: none;
}
table.footable.ft-hide-38 > tbody > tr > :nth-child(39),
table.footable.ft-hide-38 > thead > tr > :nth-child(39),
table.footable.ft-hide-38 > tfoot > tr > :nth-child(39) {
  display: none;
}
table.footable.ft-hide-39 > tbody > tr > :nth-child(40),
table.footable.ft-hide-39 > thead > tr > :nth-child(40),
table.footable.ft-hide-39 > tfoot > tr > :nth-child(40) {
  display: none;
}
table.footable.ft-hide-40 > tbody > tr > :nth-child(41),
table.footable.ft-hide-40 > thead > tr > :nth-child(41),
table.footable.ft-hide-40 > tfoot > tr > :nth-child(41) {
  display: none;
}
table.footable.ft-hide-41 > tbody > tr > :nth-child(42),
table.footable.ft-hide-41 > thead > tr > :nth-child(42),
table.footable.ft-hide-41 > tfoot > tr > :nth-child(42) {
  display: none;
}
table.footable.ft-hide-42 > tbody > tr > :nth-child(43),
table.footable.ft-hide-42 > thead > tr > :nth-child(43),
table.footable.ft-hide-42 > tfoot > tr > :nth-child(43) {
  display: none;
}
table.footable.ft-hide-43 > tbody > tr > :nth-child(44),
table.footable.ft-hide-43 > thead > tr > :nth-child(44),
table.footable.ft-hide-43 > tfoot > tr > :nth-child(44) {
  display: none;
}
table.footable.ft-hide-44 > tbody > tr > :nth-child(45),
table.footable.ft-hide-44 > thead > tr > :nth-child(45),
table.footable.ft-hide-44 > tfoot > tr > :nth-child(45) {
  display: none;
}
table.footable.ft-hide-45 > tbody > tr > :nth-child(46),
table.footable.ft-hide-45 > thead > tr > :nth-child(46),
table.footable.ft-hide-45 > tfoot > tr > :nth-child(46) {
  display: none;
}
table.footable.ft-hide-46 > tbody > tr > :nth-child(47),
table.footable.ft-hide-46 > thead > tr > :nth-child(47),
table.footable.ft-hide-46 > tfoot > tr > :nth-child(47) {
  display: none;
}
table.footable.ft-hide-47 > tbody > tr > :nth-child(48),
table.footable.ft-hide-47 > thead > tr > :nth-child(48),
table.footable.ft-hide-47 > tfoot > tr > :nth-child(48) {
  display: none;
}
table.footable.ft-hide-48 > tbody > tr > :nth-child(49),
table.footable.ft-hide-48 > thead > tr > :nth-child(49),
table.footable.ft-hide-48 > tfoot > tr > :nth-child(49) {
  display: none;
}
table.footable.ft-hide-49 > tbody > tr > :nth-child(50),
table.footable.ft-hide-49 > thead > tr > :nth-child(50),
table.footable.ft-hide-49 > tfoot > tr > :nth-child(50) {
  display: none;
}
table.footable.ft-hide-50 > tbody > tr > :nth-child(51),
table.footable.ft-hide-50 > thead > tr > :nth-child(51),
table.footable.ft-hide-50 > tfoot > tr > :nth-child(51) {
  display: none;
}
div.row.hover-highlight {
  background-color: white;
}
div.row.hover-highlight:hover {
  background-color: #f5f5f5;
}
.footable-hidden-column {
  display: none;
}
.compSearchTable fieldset.inline-fieldset,
.salesSearchTable fieldset.inline-fieldset,
.advanced-search-table fieldset.inline-fieldset {
  display: inline;
}
