﻿@import "Variables.less";
@import "Utilities.less";
@import '../bootstrap-3.4.1/less/mixins.less';
@import '../bootstrap-3.4.1/less/variables.less';


.beacon-header-container {
    background-color: #ffffff;
    .map-page-row {
        padding: 0 0px;
    }
}

.beacon-header-large {
    height: 56px;

    .leftside {

        .visible-large {
            display: none;

            @media only screen and (min-width: 701px) and (max-width: 991.98px) {
                display: inherit;
            }
        }

        .visible-extra-large {
            display: none;

            @media only screen and (min-width: 992px) {
                display: inherit;
            }
        }

        img {
            float: left;
            height: 40px;
            margin-top: 6px;
        }

        .header-title {
            float: left;
            .text-overflow();
            font-size: 24px;
            line-height: 56px;
            padding: 0 10px;
            margin: 0;
            font-weight: normal;


            @media only screen and (min-width: 701px) and (max-width: 991.98px) {
                font-size: 18px;
                max-width: 385px;
            }
        }
    }

    .rightside {
        float: right;

        .search {
            float: right;
            width: 160px;
            margin-top: 12px;
            position: sticky;

            .form-control-feedback {
                top: 0px; // this corrects some 20px offset from the bootstrap css
            }
        }

        .logo {
            float: left;
            padding-right: 10px;
            padding-top: 7px;
        }

        .myacct {
            float: left;
            height: 20px;
            margin-top: 20px;
            line-height: 20px;
            padding-right: 10px;

            a, a:visited, a:active {
                text-decoration: none;
                color: #444;
            }

            a:hover {
                text-decoration: none;
                color: #222;
            }

            a.dropdown-toggle {
                padding: 4px;

                &:focus {
                    .tab-focus();
                }
            }

            .icon-user {
                padding-right: 4px;
                color: @schneider-red;
            }
        }
    }
}

.beacon-header-small {
    height: 30px;

    .leftside {
        float: left;

        img {
            height: 30px;
            vertical-align: top;
            //margin: 2px 0;
        }

        .header-title {
            //float: left;
            .text-overflow();
            font-size: 18px;
            line-height: 30px;
            padding: 0 10px;
            display: inline;
            margin: 0;
            font-weight: normal;
        }
    }
}

.beacon-header-small-long-title {
    height: 30px;

    .leftside {
        float: left;

        img {
            height: 30px;
            vertical-align: top;
            //margin: 2px 0;
        }

        .header-title {
            //float: left;
            .text-overflow();
            font-size: inherit;
            line-height: 30px;
            padding: 0 10px;
            display: inline;
            margin: 0;
            font-weight: normal;
        }
    }
}



