.mapToolbar {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  cursor: default;
}
@media print {
  .mapToolbar {
    display: none;
  }
}
.mapToolbar div {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  margin: 2px;
  padding: 2px;
  border: 1px solid #440E11;
  background-color: #999;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 5px;
}
.mapToolbar div:hover {
  background-color: #b0b0b0;
}
.mapToolbar div .sprite {
  margin: 2px;
}
.mapToolbar div.ItemActive {
  background-color: #ccc;
}
.esri-ui-top-left {
  width: 100%;
}
.mapToolBar {
  width: 350px;
}
.tablink {
  background-color: #555;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
  font-size: 10px;
  width: 25%;
}
/* Change background color of buttons on hover */
.tablink:hover {
  background-color: #777;
}
/* Set default styles for tab content */
.tabcontent {
  color: white;
  display: none;
  background-color: white;
  font-size: 10px;
  /*padding: 50px;*/
  /*text-align: center;*/
}
.mapToolbarDangle {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  cursor: default;
}
.olControlMousePosition {
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
}
.spatialselectionPane {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  padding: 2px;
  /*INPUT[type=text] {
        width: 32px;
        font-size: 7pt;
    }*/
  /*SELECT {
       height: auto;
    }*/
}
.spatialselectionPane INPUT[type=radio] {
  vertical-align: text-bottom;
  margin-left: 4px;
}
.spatialselectionPane INPUT[type=checkbox] {
  vertical-align: text-bottom;
}
.spatialselectionPane .form-inline {
  margin-left: 4px;
}
.spatialselectionPane .spatialselectionPaneFrame {
  padding: 4px;
  background-color: #eee;
  line-height: 18px;
}
.spatialselectionPane hr {
  border-top: 1px solid #ccc;
  margin: 8px 0;
}
.client-added-layers {
  /*    li {
        display: inline-flex;
    }*/
}
.client-added-layers ul {
  list-style-type: none;
  padding-left: 5px;
  width: 100%;
}
.client-added-layers input[type=checkbox] {
  vertical-align: text-bottom;
  font-size: 16px;
  /*margin-left: -18px;*/
  padding-left: 5px;
  color: #555;
  width: 16px;
  top: 3px;
  cursor: default;
}
.client-added-layers input[type=file] {
  display: none;
}
.client-added-layers .map-tool-image {
  width: 13px;
  vertical-align: text-bottom;
}
.measurePane {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  padding: 2px;
}
.measurePane INPUT[type=text] {
  width: 32px;
  font-size: 7pt;
}
.measurePane .measurePaneFrame {
  padding: 4px;
  background-color: #eee;
  line-height: 18px;
}
/*.scalebar-dropdown {
    min-width: 0px;
}*/
#ZoomToXYPanel {
  line-height: 24px;
}
#ZoomToXYPanel input {
  width: 80px;
  line-height: normal;
}
#measureOverlay input[type='text'] {
  border-style: none;
  background-color: transparent;
  width: 72px;
  margin: 0 6px;
  text-align: right;
  font-size: 8pt;
}
#measureOverlay select {
  width: 63px;
}
#measureOverlay #btnMeasureDone {
  margin-left: 10px;
}
.zoomtoxypPane {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  padding: 2px;
}
.zoomtoxypPane .zoomtoxypPaneFrame {
  padding: 4px;
  background-color: #eee;
  line-height: 18px;
}
/* markup controls */
.mapmarkupFrame {
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  padding: 2px;
}
#MapMarkupPanel {
  background-color: #eee;
  line-height: 18px;
  height: 100px;
}
#MapMarkupPanel .mapMarkup {
  float: left;
  /*display: block;*/
  width: 20px;
  height: 20px;
  margin: 2px;
  padding: 2px;
  border: 1px solid #440E11;
  background-color: #999;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 5px;
}
#MapMarkupPanel .mapMarkup:hover {
  background-color: #b0b0b0;
}
#MapMarkupPanel .mapMarkup .sprite {
  margin: 2px;
}
#MapMarkupPanel .mmActive {
  background-color: #ccc;
}
#MapMarkupPanel .mmColorPalette {
  padding: 1px;
  background-color: #ccc;
  width: 144px;
}
#MapMarkupPanel .mmColorSwatch {
  float: left;
  padding: 1px;
  margin: 1px;
  border: 1px solid black;
  width: 12px;
  height: 12px;
}
#MapMarkupPanel .mmColorButton {
  display: inline-block;
  border: 1px solid black;
  width: 16px;
  height: 16px;
  background-color: #f00;
  margin-bottom: -4px;
}
#MapMarkupPanel .mmMarkerPalette {
  padding: 1px;
  background-color: #ccc;
  width: 110px;
}
#MapMarkupPanel .mmMarkerSwatch {
  float: left;
  padding: 1px;
  margin: 1px;
  border: 1px solid black;
  width: 16px;
  height: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}
#MapMarkupPanel .mmMarkerButton {
  display: inline-block;
  border: 1px solid black;
  width: 16px;
  height: 16px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: -4px;
}
#MapMarkupPanel #mmSize {
  height: 20px;
  vertical-align: text-bottom;
  margin-bottom: -4px;
}
#MapMarkupPanel .mmInputs {
  position: absolute;
  top: 36px;
  left: 4px;
  padding: 4px;
  line-height: 24px;
}
/* cogo tools */
.mapcogoFrame {
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  padding: 2px;
}
#CogoPanel {
  background-color: #eee;
  line-height: 18px;
  min-height: 32px;
}
#CogoPanel .cogoTool {
  float: left;
  /*display: block;*/
  width: 20px;
  height: 20px;
  margin: 2px;
  padding: 2px;
  border: 1px solid #440E11;
  background-color: #999;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 5px;
}
#CogoPanel .cogoTool:hover {
  background-color: #b0b0b0;
}
#CogoPanel .cogoTool .sprite {
  margin: 2px;
}
#CogoPanel .cogoTool.disabled-tool {
  pointer-events: none;
  opacity: 0.4;
}
#CogoPanel .mmActive {
  background-color: #ccc;
}
#CogoPanel .cogoInputs {
  padding: 4px;
}
#CogoPanel .cogo-tool-title {
  font-size: larger;
  font-weight: bold;
}
.sprite.MaptipClose {
  cursor: pointer;
  top: 5px;
  right: 33px;
  z-index: 2;
}
.sprite.MaptipPinUp,
.sprite.MaptipPinDown {
  cursor: pointer;
  right: 52px;
  top: 5px;
  z-index: 2;
}
.olMapTipFrameContent {
  padding: 5px 5px 0px 5px;
  overflow: hidden;
}
.olMapTipFrameContent .title {
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 11px;
}
.olMapTipFrameContent .content {
  font-size: 7pt;
}
.olMapTipFrameContent .loader {
  background-image: url(/Images/MapTips/loader.gif);
  background-repeat: no-repeat;
  background-position: center center;
  height: 12px;
  width: 100px;
  padding: 15px 5px 0px 5px;
}
.olCursorNotAllowed {
  cursor: not-allowed;
}
.mobile-collapsed {
  display: none;
}
.mapTip-mobile {
  position: absolute;
  background-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-color: lightgray;
  width: 100%;
}
.mapTip-mobile .header {
  height: 20px;
  background-color: "whitesmoke";
}
.mapTip-mobile .header .title {
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 11px;
}
.mapTip-mobile .content {
  font-size: 9px;
  padding: 2px;
}
.mapTip {
  position: absolute;
  background-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-color: lightgray;
  width: 240px;
  height: 129px;
  box-shadow: #333333 0px 0px 10px;
  overflow: hidden;
}
.mapTip .header {
  height: 20px;
  background-color: "whitesmoke";
}
.mapTip .header .title {
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 11px;
}
.mapTip .content {
  font-size: 9px;
  padding: 2px;
}
/* used by some tools to prevent mouse drags off the map from selecting other content */
.disableTextSelection {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* avoid pink tiles */
.olImageLoadError {
  /*background-color: transparent !important;*/
  visibility: hidden;
}
/* general overlay */
#generalOverlay {
  position: absolute;
  left: 50px;
  right: 50px;
  top: 50px;
  bottom: 50px;
  border: 1px solid black;
  background-color: White;
}
#generalOverlayClose {
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 20000;
}
/* ajax message display - UR corner of map */
#activityIndicator {
  position: absolute;
  z-index: 9000;
  right: 40px;
  top: 0px;
  background-color: #900000;
  color: #ffffff;
  font-weight: bold;
  font-size: 8pt;
  padding: 2px;
}
/* OL overview map */
.bControlOverviewMapElement {
  padding: 0px;
  background-color: #fff;
  -moz-border-radius: 1em 0 0 0;
  border-radius: 1em 0 0 0;
}
.bControlOverviewMapElement .bControlOverviewMapExtentRectangle {
  overflow: hidden;
  cursor: move;
  border: 2px dotted red;
}
.bControlOverviewMapElement .sprite.OverviewCrosshair {
  cursor: move;
}
.coordiantes {
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  padding: 1px 3px 1px 3px;
  position: absolute;
  max-width: 175px;
}
.coordiantes .text {
  font-size: 8pt;
  text-align: center;
  float: right;
  padding-right: 3px;
}
.coordiantes .toggle {
  width: 9px;
  height: 9px;
  margin: 2px 0px 2px 0px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAI0lEQVQY02NggID/eDBcAT7wH5ui/xQrwuUeGlmH13cEwwkAw80k3JlgCzsAAAAASUVORK5CYII=') /*/Images/plus_9px.png*/;
  *background-image: url("/Images/plus_9px.png");
  /* For IE 6 and 7 */
  background-repeat: no-repeat;
  float: right;
}
.bControlDisplayXY {
  bottom: 0px;
  right: 0px;
  display: block;
  position: absolute;
  padding: 1px 3px 1px 3px;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  width: 24px;
}
.bControlDisplayXY .bControlDisplayXYText {
  font-size: 8pt;
  text-align: center;
  float: right;
  padding-right: 3px;
}
.bControlDisplayXY .bControlDisplayXYToggle {
  width: 9px;
  height: 9px;
  margin: 2px 0px 2px 0px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAI0lEQVQY02NggID/eDBcAT7wH5ui/xQrwuUeGlmH13cEwwkAw80k3JlgCzsAAAAASUVORK5CYII=') /*/Images/plus_9px.png*/;
  *background-image: url("/Images/plus_9px.png");
  /* For IE 6 and 7 */
  background-repeat: no-repeat;
  float: right;
}
.bScalebar {
  position: absolute;
  left: 8px;
  bottom: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  padding: 1px;
  z-index: 1200;
}
@media print {
  .bScalebar {
    display: none;
  }
}
.bScalebar:hover {
  background-color: rgba(255, 255, 255, 0.75);
  background-image: url(/Images/pixel_white75.png) \9;
  background-repeat: repeat \9;
}
.bScalebar .bScalebarLine {
  width: 94px;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  border: 2px solid black;
  border-top: none;
}
.bScalebar .bScalebarLine .up-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  border-top: 0;
  border-bottom: 5px solid;
  vertical-align: middle;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.bScalebar #scaleDropupMenu {
  width: 96px;
  min-width: 96px;
}
.bEsriLogo {
  position: absolute;
  right: 0px;
  bottom: 12px;
  padding: 1px;
}
/* this is for the touch zoom panel */
div.bMobileZoomPanel {
  top: 14px;
  left: 14px;
  z-index: 1200;
  position: absolute;
}
div.bMobileZoomPanel div {
  position: absolute;
  height: 32px;
  width: 32px;
  background-color: white;
  border: 1px solid #333;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #333;
}
div.bMobileZoomPanel div:hover {
  background-color: #e6e6e6;
}
div.bMobileZoomPanel #btnZoomIn {
  top: 0px;
  border-radius: 5px 5px 0 0;
}
div.bMobileZoomPanel #btnZoomExtent {
  top: 31px;
  content: "H";
}
div.bMobileZoomPanel #btnZoomOut {
  top: 62px;
  border-radius: 0 0 5px 5px;
}
div.bMobileZoomPanel #btnGpsMode {
  display: none;
  top: 100px;
  border-radius: 5px;
}
div.bMobileZoomPanel #btnGpsMode.active {
  color: #00bee9;
}
#btnGpsModeTip {
  position: absolute;
  top: 120px;
  left: 46px;
}
body.map-view {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#olMap {
  margin: 0;
  padding: 0;
  top: 108px;
  bottom: 0;
  right: 0;
  left: 173px;
  background-color: white;
  position: fixed;
}
@media print {
  #olMap {
    display: none;
  }
}
#agsMap {
  margin: 0;
  padding: 0;
  top: 108px;
  bottom: 0;
  right: 0;
  left: 173px;
  background-color: white;
  position: fixed;
}
@media print {
  #agsMap {
    display: none;
  }
}
#agsToolBar {
  background: #fff;
  padding: 5px;
  margin-left: 50px;
}
#agsToolBar2 {
  background: #fff;
  padding: 5px;
  /*left: 60px !important;*/
}
.agsTool2 {
  float: left;
  /*font-size: 16px;
    background-color: transparent;
    border: 1px solid #d3d3d3;
    color: #6e6e6e;
    height: 32px;
    width: 32px;
    text-align: center;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);*/
}
.agsSubToolBar {
  background: #fff;
  padding: 5px;
  /*left: 60px;*/
}
#agsIdentifyOptionPanel {
  /*position: absolute;*/
  /*z-index: 1022;*/
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  padding: 2px;
  /*left: 60px;*/
  /*top: 42px;*/
  width: 150px;
}
#agsIdentifyOptionPanel div {
  background-color: #eee;
  padding: 3px 6px 0px 6px;
}
.optionDialogBottom {
  font-size: 10px;
  width: 75%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
}
.optionDialog {
  /*display:none;*/
  font-size: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  max-height: 500px;
}
.widget-dialog-floating {
  font-family: "Segoe UI", Verdana, Tahoma, Helvetica, sans-serif;
  cursor: default;
  font-size: 8pt;
  padding-top: 0px;
  border-bottom: 1px solid #8b9097;
  -webkit-box-shadow: #8b9097 0px 1px 3px;
  -moz-box-shadow: #8b9097 0px 1px 3px;
  box-shadow: #8b9097 0px 1px 3px;
  overflow: hidden;
  z-index: 25;
  background-color: rgba(250, 247, 243, 0.9);
}
.widget-dialog-floating .title-bar {
  margin: 4pt;
  white-space: nowrap;
  cursor: move;
}
.widget-dialog-floating .title-bar .title-actions {
  padding-left: 4pt;
  padding-right: 4pt;
  float: right;
  cursor: pointer;
}
.widget-dialog-floating .content {
  padding: 1px;
  margin-top: 5px;
  background: #FFF url('rib-back.png') repeat-x bottom;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#ffffff), to(#eaedf1));
  background: -moz-linear-gradient(#ffffff, #eaedf1);
  background-color: rgba(0, 0, 0, 0);
  overflow: visible;
  max-height: 400px;
  behavior: url(/PIE.htc);
  z-index: 30;
  -pie-watch-ancestors: 2;
  padding: 10pt;
}
.widget-dialog-floating .attribute-input-group {
  overflow: auto;
  max-height: 500px;
}
.widget-dialog-floating .button:hover {
  border: 1px solid #F1C43F;
  background: #fdeeb3;
  cursor: pointer;
}
.widget-dialog-floating .button {
  width: 20px;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}
.widget-dialog-floating .active {
  border: 1px solid #F1C43F;
  background: #F1C43F;
  cursor: pointer;
}
.widget-dialog-floating .tool-button {
  padding: 4px 6px;
  border: 1px solid transparent;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  text-align: center;
  min-width: 32px;
  float: left;
}
.widget-dialog-floating .disabled {
  pointer-events: none;
  opacity: 0.4;
}
.widget-dialog-floating .tool-button:hover {
  border: 1px solid #F1C43F;
  background: #fdeeb3;
  cursor: pointer;
}
.widget-dialog-floating .map-tool-image {
  width: 13px;
}
vaadin-grid-cell-content {
  font-size: x-small !important;
  --lumo-font-size-s: x-small;
}
.widget-dialog-dropbeneath {
  font-family: "Segoe UI", Verdana, Tahoma, Helvetica, sans-serif;
  cursor: default;
  /*font-size: 8pt;*/
  padding-top: 0px;
  background-color: rgba(250, 247, 243, 0.9);
  border-bottom: 1px solid #8b9097;
  -webkit-box-shadow: #8b9097 0px 1px 3px;
  -moz-box-shadow: #8b9097 0px 1px 3px;
  box-shadow: #8b9097 0px 1px 3px;
  overflow: hidden;
  z-index: 25;
}
.widget-dialog-dropbeneath .button:hover {
  border: 1px solid #F1C43F;
  background: #fdeeb3;
  cursor: pointer;
}
.widget-dialog-dropbeneath .button {
  width: 20px;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}
.widget-dialog-dropbeneath .button-title {
  color: #444;
  font-size: 7pt;
  padding-left: 5pt;
}
.widget-dialog-dropbeneath .active {
  border: 1px solid #F1C43F;
  background: #F1C43F;
  cursor: pointer;
}
.widget-dialog-dropbeneath .tool-button {
  padding: 4px 6px;
  border: 1px solid transparent;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  text-align: center;
  min-width: 32px;
  float: left;
}
.widget-dialog-dropbeneath .disabled {
  pointer-events: none;
  opacity: 0.4;
}
.widget-dialog-dropbeneath .tool-button:hover {
  border: 1px solid #F1C43F;
  background: #fdeeb3;
  cursor: pointer;
}
.widget-dialog-dropbeneath .map-tool-image {
  width: 13px;
}
.cogo-operation-list {
  max-height: 200px;
  overflow-y: auto;
  border-color: darkgray;
  border-style: solid;
  border-width: 1px;
  padding: 0;
  margin-top: 5px;
  /*list-style-type: none;*/
}
.cogo-operation-list li {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 2.5px;
  border: 1px solid rgba(0, 0, 0, 0);
}
.cogo-operation-list li .button {
  width: 15px;
  margin-left: 5px;
}
.cogo-operation-list li span {
  margin-left: 15px;
}
.cogo-operation-list li:hover {
  border: 1px solid #F1C43F;
  background: #fdeeb3;
}
.cogo-input-group {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-top: 5px;
}
.cogo-input-group label {
  padding-right: 10px;
  min-width: 100px;
}
.cogo-input-group input {
  width: 100px;
}
.cogo-input-group select {
  width: 100px;
}
.widget-input-group {
  display: flex;
  flex-direction: row;
  /*justify-content: right;*/
  align-items: center;
  padding-top: 5px;
}
.widget-input-group label {
  padding-right: 10px;
  min-width: 135px;
}
.widget-input-group input,
.widget-input-group select {
  width: 145px;
}
.widget-input-group img {
  width: 20px;
  cursor: pointer;
}
.widget-input-group .label-center {
  margin-left: auto;
  margin-right: auto;
}
.widget-input-group .button-right {
  margin-left: auto;
}
.widget-input-group .button-title {
  clear: left;
  display: block;
}
.map-measure-details-group {
  display: flex;
  flex-direction: row;
  /*justify-content: right;*/
  align-items: center;
  padding-top: 5px;
}
.map-measure-details-group label {
  padding-right: 10px;
  width: 70px;
}
.map-measure-details-group input,
.map-measure-details-group select {
  width: 120px;
}
.opacity-slider {
  -webkit-appearance: none;
  width: 100px;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.hidden {
  display: none;
}
/*.esri-icon {
    font-size: 300px;
}*/
.action-button {
  font-size: 14px;
  background-color: transparent;
  border: 0px;
  color: #6e6e6e;
  height: 22px;
  width: 22px;
  text-align: center;
  /*box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);*/
}
.action-button-left-menu {
  font-size: 16px;
  border: 0px;
  color: #6e6e6e;
  height: 26px;
  width: 26px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .ribbon-static {
    display: none !important;
  }
}
.page {
  border: 1px solid #a5acb5;
  margin: 20px;
  padding: 15px;
  -webkit-box-shadow: #000000 0px 0px 10px;
  -moz-box-shadow: #000000 0px 0px 10px;
  box-shadow: #000000 0px 0px 10px;
  background: #FFF;
}
#layer-option-tabs {
  font-family: "Segoe UI", Verdana, Tahoma, Helvetica, sans-serif;
  cursor: default;
  font-size: 8pt;
  padding-top: 0px;
  background-color: rgba(250, 247, 243, 0.9);
  border-bottom: 1px solid #8b9097;
  /*    -webkit-box-shadow: #8b9097 0px 1px 3px;
    -moz-box-shadow: #8b9097 0px 1px 3px;*/
  /*box-shadow: #8b9097 0px 1px 3px;*/
  /*overflow: scroll;*/
  z-index: 25;
}
#layer-option-tabs.backstage {
  height: auto;
  bottom: 0;
}
#layer-option-tabs #layer-option-tab-header-strip {
  margin: 4px 0 0 1px;
  white-space: nowrap;
}
#layer-option-tabs .layer-option-tab-header {
  display: inline;
  margin: 0 5px;
  padding: 5px 5px;
  font-size: 7pt;
  color: #444;
  /*border: 1px solid #eaedf1;*/
  /*    border-bottom: 1px solid #b6babf;*/
  -webkit-border-radius: 4px 4px 0px 0px;
  -moz-border-radius: 4px 4px 0px 0px;
  border-radius: 4px 4px 0px 0px;
  position: relative;
  z-index: 40;
}
#layer-option-tabs .layer-option-tab-header:hover {
  background: #f1f3f4;
  border: 1px solid #b6babf;
  margin: -1px 4px;
  cursor: pointer;
}
#layer-option-tabs .layer-option-tab-header.sel,
#layer-option-tabs .layer-option-tab-header.sel:hover {
  background: #fff;
  margin: -1px 4px;
  border: 1px solid #b6babf;
  border-bottom: 1px solid #fff;
  cursor: default;
  z-index: 45;
}
#layer-option-tabs .layer-option-tab {
  /*height: 80px;*/
  padding: 1px;
  margin-top: 5px;
  border-top: 1px solid #b6babf;
  background: #FFF url('rib-back.png') repeat-x bottom;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#ffffff), to(#eaedf1));
  background: -moz-linear-gradient(#ffffff, #eaedf1);
  /*-pie-background: linear-gradient(#ffffff, #eaedf1);*/
  overflow-y: auto;
  min-width: 250px;
  /*min-width: 100%;*/
  behavior: url(/PIE.htc);
  z-index: 30;
  -pie-watch-ancestors: 2;
}
#ribbon {
  font-family: "Segoe UI", Verdana, Tahoma, Helvetica, sans-serif;
  cursor: default;
  /*font-size: 8pt;*/
  padding-top: 0px;
  background-color: rgba(250, 247, 243, 0.9);
  border-bottom: 1px solid #8b9097;
  -webkit-box-shadow: #8b9097 0px 1px 3px;
  -moz-box-shadow: #8b9097 0px 1px 3px;
  box-shadow: #8b9097 0px 1px 3px;
  overflow: hidden;
  z-index: 25;
}
#ribbon.backstage {
  height: auto;
  bottom: 0;
}
#ribbon.backstage .ribbon-tab-header {
  border-bottom: 1px solid #1f48a1;
}
#ribbon .ribbon-tab-header:hover {
  background: #f1f3f4;
  border: 1px solid #b6babf;
  margin: -1px 4px;
  cursor: pointer;
}
#ribbon.backstage .ribbon-tab-header {
  border-bottom: 1px solid #1f48a1;
}
#ribbon .ribbon-tab-header-control {
  display: inline;
  /*padding: 5px 10px;*/
  /*font-size: 8pt;*/
  color: #444;
  /*border-bottom: 1px solid #b6babf;*/
  -webkit-border-radius: 4px 4px 0px 0px;
  -moz-border-radius: 4px 4px 0px 0px;
  border-radius: 4px 4px 0px 0px;
  /*position: relative;*/
  z-index: 40;
  float: right;
  margin: 0 5px;
}
#ribbon .ribbon-tab-header-control:hover {
  background: #f1f3f4;
  border: 1px solid #b6babf;
  margin: -1px 4px;
  cursor: pointer;
}
#ribbon .ribbon-tab {
  /*height: 80px;*/
  padding: 1px;
  /*margin-top: 5px;*/
  /*border-top: 1px solid #b6babf;*/
  background: #FFF url('rib-back.png') repeat-x bottom;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#ffffff), to(#eaedf1));
  background: -moz-linear-gradient(#ffffff, #eaedf1);
  /*-pie-background: linear-gradient(#ffffff, #eaedf1);*/
  overflow: hidden;
  /*min-width: 250px;*/
  /*min-width: 100%;*/
  behavior: url(/PIE.htc);
  z-index: 30;
  -pie-watch-ancestors: 2;
}
/*#ribbon .ribbon-tab.file {
    padding: 0;
    margin-top: 5px;
    border-top: 1px solid #1f48a1;
    background-color: #4488e5;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,startColorstr=#4488e5, endColorstr=#ffffff);
    background-image: -moz-linear-gradient(left, #4488e5 15%, #ffffff 100%);
    background-image: -webkit-linear-gradient(left, #4488e5 15%, #ffffff 100%);
    background-image: -ms-linear-gradient(left, #4488e5 15%, #ffffff 100%);
    background-image: linear-gradient(left, #4488e5 15%, #ffffff 100%);
    background-image: -o-linear-gradient(left, #4488e5 15%, #ffffff 100%);
    background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(15%,#4488e5), color-stop(100%,#ffffff));
    overflow: hidden;
    min-width: 250px;*/
/*min-width: 100%;*/
/*z-index: 30;
}*/
#ribbon .ribbon-backstage {
  display: none;
}
#ribbon.backstage .ribbon-backstage {
  display: block;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  background-color: #FFF;
}
#ribbon .section-title {
  color: #5B626C;
  text-align: center;
  clear: left;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
#ribbon .ribbon-section {
  float: left;
  position: relative;
  /*height: 82px;*/
}
#ribbon .ribbon-section-sep {
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #fff;
  margin: 0 5px;
  height: 45px;
  top: 0;
  bottom: 0;
  position: relative;
  width: 0;
  float: left;
}
#ribbon .ribbon-button-small-group {
  float: right;
}
#ribbon .ribbon-button {
  padding: 4px 6px;
  border: 1px solid transparent;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#ribbon .ribbon-button-large {
  text-align: center;
  /*height: 58px;*/
  min-width: 32px;
  float: left;
}
/*#ribbon .ribbon-button-small {*/
/*height: 14px;
    padding: 3px 4px;*/
/*text-align: left;
}

#ribbon .ribbon-button .button-help {
    display: none;
}*/
#ribbon .ribbon-button .button-title {
  color: #444;
  font-size: 7pt;
}
#ribbon .ribbon-button-large .button-title {
  clear: left;
  display: block;
}
#ribbon .ribbon-button:hover {
  border: 1px solid #F1C43F;
  background: #fdeeb3;
  cursor: pointer;
}
#ribbon .active {
  border: 1px solid #F1C43F;
  background: #F1C43F;
  cursor: pointer;
}
#ribbon .disabled {
  background: #e8e6e0;
  opacity: 0.5;
}
.ribbon-tooltip {
  font-family: "Segoe UI", Verdana, Tahoma, Helvetica, sans-serif;
  font-size: 10pt;
  position: fixed;
  z-index: 5000;
  border: 1px solid #8b9097;
  background-color: #e5e5f0;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#ffffff), to(#e5e5f0));
  background: -moz-linear-gradient(#ffffff, #e5e5f0);
  -pie-background: linear-gradient(#ffffff, #e5e5f0);
  padding: 5px;
  -webkit-box-shadow: #c6c9cd 1px 1px 3px;
  -moz-box-shadow: #c6c9cd 1px 1px 3px;
  box-shadow: #c6c9cd 1px 1px 3px;
  -webkit-border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
  width: 200px;
  top: 150px !important;
}
#resultsGrid {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.9);
  height: 200px;
  overflow: auto;
  border-top: 1px solid #aaa;
  padding: 6px;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
  z-index: 1;
  /*    #resultsGridContent {
        position: absolute;
        display:flex;
    }*/
}
@media print {
  #resultsGrid {
    display: none;
  }
}
#toolTip {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 200px;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.9);
  height: 170px;
  overflow: auto;
  border-top: 1px solid #aaa;
  padding-right: 5px;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
  z-index: 1;
  /*    #resultsGridContent {
        position: absolute;
        display:flex;
    }*/
}
@media print {
  #toolTip {
    display: none;
  }
}
#mapSearch {
  display: none;
  position: absolute;
  top: 108px;
  bottom: 0;
  right: 0;
  width: 160px;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.9);
  overflow-x: auto;
  border-left: 1px solid #aaa;
  padding: 4px;
  z-index: 1;
}
#mapJsDetailDiv {
  display: none;
  position: absolute;
  left: 173px;
  right: 160px;
  bottom: 0;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.9);
  max-height: 180px;
  overflow: auto;
  border-top: 1px solid #aaa;
  padding: 6px;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
  z-index: 1;
}
@media print {
  #mapJsDetailDiv {
    display: none;
  }
}
#mapJsDetailDiv #btnCloseDetailsPane {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 12px;
  color: #aaa;
  cursor: pointer;
}
#ResultsPaneDiv {
  position: absolute;
  top: 108px;
  bottom: 0;
  right: 0px;
  width: 160px;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.9);
  overflow-x: auto;
  border-left: 1px solid #aaa;
  padding: 4px;
  z-index: 1;
}
@media print {
  #ResultsPaneDiv {
    display: none;
  }
}
#map-pane-tab-results {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  -moz-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -o-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  position: absolute;
  border: 1px solid #999;
  border-top: none;
  background-color: #ccc;
  color: #333;
  padding: 2px 6px 6px 6px;
  border-radius: 0 0 4px 4px;
  cursor: pointer;
  display: none;
  right: 0;
  top: 333px;
  /* This is an IE9 hack to add text rotation to the side tabs */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1) \9;
}
@media print {
  #map-pane-tab-results {
    display: none;
  }
}
#map-pane-tab-results .no-results-available {
  display: none;
}
.map-wrap.hide-map-pane-results #olMap {
  right: 0;
}
.map-wrap.hide-map-pane-results #ResultsPaneDiv {
  display: none;
}
.map-wrap.hide-map-pane-results #map-pane-tab-results {
  display: block;
}
.map-wrap.hide-map-pane-results #map-pane-tab-results.no-results-available {
  display: none;
}
.map-wrap.hide-map-pane-results #mapJsDetailDiv {
  right: 0;
}
.map-wrap.show-map-grid-results #agsMap {
  bottom: 200px;
}
.map-wrap.show-map-grid-results .toc-wrapper {
  bottom: 200px;
}
.map-wrap.show-map-grid-results #mapSearch {
  bottom: 200px;
}
.map-wrap.show-map-grid-results #toolTip {
  display: none;
}
.map-wrap.show-map-grid-results #resultsGrid {
  display: flex;
}
.map-wrap.show-map-grid-results #togglePane.maximized-pane {
  transform: rotate(270deg);
}
.map-wrap.show-map-grid-results.show-tooltip #agsMap {
  bottom: 370px;
}
.map-wrap.show-map-grid-results.show-tooltip .toc-wrapper {
  bottom: 370px;
}
.map-wrap.show-map-grid-results.show-tooltip #mapSearch {
  bottom: 370px;
}
.map-wrap.show-map-grid-results.show-tooltip #toolTip {
  display: flex;
}
.map-wrap.show-map-grid-results.show-tooltip #resultsGrid {
  display: flex;
}
.map-wrap.show-map-grid-results.show-tooltip #togglePane.maximized-pane {
  transform: rotate(270deg);
}
.map-wrap.show-map-grid-results.show-map-grid-results-collapsed #agsMap {
  bottom: 50px;
}
.map-wrap.show-map-grid-results.show-map-grid-results-collapsed .toc-wrapper {
  bottom: 50px;
}
.map-wrap.show-map-grid-results.show-map-grid-results-collapsed #mapSearch {
  bottom: 50px;
}
.map-wrap.show-map-grid-results.show-map-grid-results-collapsed #toolTip {
  bottom: 36px;
  overflow: hidden;
}
.map-wrap.show-map-grid-results.show-map-grid-results-collapsed #resultsGrid {
  height: 36px;
  overflow: hidden;
}
.map-wrap.show-map-grid-results.show-map-grid-results-collapsed #togglePane.minimized-pane {
  transform: rotate(90deg);
}
.map-wrap.show-map-search #agsMap {
  right: 160px;
}
.map-wrap.hide-map-pane-layers #olMap {
  left: 0;
}
.map-wrap.hide-map-pane-layers #agsMap {
  left: 0;
}
.map-wrap.hide-map-pane-layers .toc-wrapper {
  display: none;
}
.map-wrap.hide-map-pane-layers #map-pane-tab-layers {
  display: block;
}
.map-wrap.hide-map-pane-layers #mapJsDetailDiv {
  left: 0;
}
@media (max-width: 700px) {
  #olMap {
    top: 83px;
  }
  #agsMap {
    top: 83px;
  }
  .toc-wrapper {
    top: 83px;
  }
  #ResultsPaneDiv {
    top: 83px;
  }
  #mapJsDetailDiv {
    max-height: 100px;
  }
}
#ovmap_img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2000;
  font-size: 20px;
  line-height: 20px;
  width: 20px;
  opacity: 0.8;
  text-shadow: 0 0 4px white;
  margin: 2px;
}
#ovmap_img.ov-closed {
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -moz-animation: ov-closed 0.3s ease-in-out;
  -o-animation: ov-closed 0.3s ease-in-out;
  -webkit-animation: ov-closed 0.3s ease-in-out;
  animation: ov-closed 0.3s ease-in-out;
}
#ovmap_img.ov-opened {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -moz-animation: ov-opened 0.3s ease-in-out;
  -o-animation: ov-opened 0.3s ease-in-out;
  -webkit-animation: ov-opened 0.3s ease-in-out;
  animation: ov-opened 0.3s ease-in-out;
}
@-webkit-keyframes ov-closed {
  0% {
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100% {
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}
@-moz-keyframes ov-closed {
  0% {
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100% {
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}
@keyframes ov-closed {
  0% {
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100% {
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}
@-webkit-keyframes ov-opened {
  0% {
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    -moz-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    transform: rotate(315deg);
  }
}
@-moz-keyframes ov-opened {
  0% {
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    -moz-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    transform: rotate(315deg);
  }
}
@keyframes ov-opened {
  0% {
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    -moz-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    transform: rotate(315deg);
  }
}
#overviewJsMapPane {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1990;
  width: 160px;
  height: 160px;
  border-left: 1px solid #777;
  border-bottom: 1px solid #777;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  padding: 6px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
}
#identifyOptionPanel {
  position: absolute;
  z-index: 1022;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  padding: 2px;
  left: 60px;
  top: 42px;
}
#identifyOptionPanel div {
  background-color: #eee;
  padding: 3px 6px 0px 6px;
}
#identifyOptionPanelSpacer {
  position: absolute;
  z-index: 1022;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url(/Images/pixel_white50.png) \9;
  background-repeat: repeat \9;
  width: 30px;
  height: 4px;
  left: 60px;
  top: 38px;
}
.map-print-warning {
  font-weight: bold;
  font-size: 24px;
  color: #ED0000;
}
@media screen {
  .map-print-warning {
    display: none;
  }
}
@media print {
  .map-print-warning {
    display: block;
  }
}
.map-tool-image {
  width: 13px;
}
.form-control-sm {
  /*    height: calc(1em + 1rem + 1px) !important;
    padding: .250rem .50rem !important;*/
  font-size: 1rem !important;
  /*    line-height: 1.5;
    border-radius: .2rem;*/
  width: 85px;
}
.vertical-center {
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
