﻿// Variables 
@charPlus: "\e000";
@charMinus: "\e001";

@charCircleFilledPlus: "\e002";
@charCircleFilledMinus: "\e003";

@charCirclePlus: "\e004";
@charCircleMinus: "\e005";

@charSquarePlus: "\e006";
@charSquareMinus: "\e007";

@charSquareFilledPlus: "\e008";
@charSquareFilledMinus: "\e009";

@charCircleFilledArrowUp: "\e00a";
@charCircleFilledArrowDown: "\e00b";
@charCircleFilledArrowLeft: "\e00c";
@charCircleFilledArrowRight: "\e00d";

@charArrowUp: "\e00e";
@charArrowDown: "\e00f";
@charArrowLeft: "\e010";
@charArrowRight: "\e011";

@charArrowSmallUp: "\e012";
@charArrowSmallDown: "\e013";
@charArrowSmallLeft: "\e014";
@charArrowSmallRight: "\e015";

@charArrowAltUp: "\e016";
@charArrowAltDown: "\e017";
@charArrowAltLeft: "\e018";
@charArrowAltRight: "\e019";

@charCircleArrowUp: "\e01a";
@charCircleArrowDown: "\e01b";
@charCircleArrowLeft: "\e01c";
@charCircleArrowRight: "\e01d";

@charArrowTinyUp: "\e01e";
@charArrowTinyDown: "\e01f";
@charArrowTinyLeft: "\e020";
@charArrowTinyRight: "\e021";

@charArrowSmallUpDown: "\e022";

@colorDarkGrey: #888;
@colorStriping: #F7F7F7;

@fontPath: "../../fonts/footable/";
@fontFootable: 'footable';

.ToggleTheme (@charPlus, @charMinus) {
    > tbody > tr {
        &.footable-detail-show > td > .footable-toggle:before,
        &.footable-detail-show > th > .footable-toggle:before {
            content: @charMinus;
        }

        > td > .footable-toggle:before,
        > th > .footable-toggle:before {
            content: @charPlus;
        }
    }
}

// Classes

@font-face {
	font-family: @fontFootable;
	src:url('@{fontPath}footable.eot');
	src:url('@{fontPath}footable.eot?#iefix') format('embedded-opentype'),
		url('@{fontPath}footable.woff') format('woff'),
		url('@{fontPath}footable.ttf') format('truetype'),
		url('@{fontPath}footable.svg#footable') format('svg');
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: @fontFootable;
    src: url('@{fontPath}footable.svg#footable') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

.footable {
    width: 100%;


    &.breakpoint {
        > tbody > tr {
            &.footable-detail-show > td,
            &.footable-detail-show > th {
                border-bottom: none;

                & > .footable-toggle:before {
                    content: @charMinus;
                }
            }

            &:hover:not(.footable-row-detail) {
                cursor: pointer;
            }

            > td.footable-cell-detail,
            > th.footable-cell-detail {
                background: #eee;
                border-top: none;
            }

            > td > .footable-toggle,
            > th > .footable-toggle {
                display: inline-block;
                font-family: @fontFootable;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                -webkit-font-smoothing: antialiased;
                padding-right: 5px;
                font-size: 14px;
                color: @colorDarkGrey;
                position: absolute;

                &:before {
                    content: @charPlus;
                }
            }
        }

        &.toggle-circle {
            .ToggleTheme(@charCirclePlus, @charCircleMinus);
        }

        &.toggle-circle-filled {
            .ToggleTheme(@charCircleFilledPlus, @charCircleFilledMinus);
        }

        &.toggle-square {
            .ToggleTheme(@charSquarePlus, @charSquareMinus);
        }

        &.toggle-square-filled {
            .ToggleTheme(@charSquareFilledPlus, @charSquareFilledMinus);
        }

        &.toggle-arrow {
            .ToggleTheme(@charArrowRight, @charArrowDown);
        }

        &.toggle-arrow-small {
            .ToggleTheme(@charArrowSmallRight, @charArrowSmallDown);
        }

        &.toggle-arrow-circle {
            .ToggleTheme(@charCircleArrowRight, @charCircleArrowDown);
        }

        &.toggle-arrow-circle-filled {
            .ToggleTheme(@charCircleFilledArrowRight, @charCircleFilledArrowDown);
        }

        &.toggle-arrow-tiny {
            .ToggleTheme(@charArrowTinyRight, @charArrowTinyDown);
        }

        &.toggle-arrow-alt {
            .ToggleTheme(@charArrowAltRight, @charArrowAltDown);
        }

        &.toggle-medium > tbody > tr {
            > td > .footable-toggle,
            > th > .footable-toggle {
                font-size: 18px;
            }
        }

        &.toggle-large > tbody > tr {
            > td > .footable-toggle,
            > th > .footable-toggle {
                font-size: 24px;
            }
        }
    }
    /** SORTING **/
    > thead > tr > th {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: -moz-none;
        -ms-user-select: none;
        user-select: none;

        &.footable-sortable:hover {
            cursor: pointer;
        }

        &.footable-sorted > span.footable-sort-indicator:before {
            content: @charArrowSmallDown;
        }

        &.footable-sorted-desc > span.footable-sort-indicator:before {
            content: @charArrowSmallUp;
        }

        > span.footable-sort-indicator {
            display: inline-block;
            font-family: @fontFootable;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            padding-left: 5px;

            &:before {
                content: @charArrowSmallUpDown;
            }
        }
    }
    /** PAGINATION **/
    > tfoot .pagination {
        margin: 0;
    }

    &.no-paging .hide-if-no-paging {
        display: none;
    }
}

.footable-row-detail-inner {
	display:table;
}

.footable-row-detail-row {
	display: table-row;
	line-height: 1.5em;
}

.footable-row-detail-group  {
	display: block;
	line-height: 2em;
	font-size: 1.2em;
	font-weight: bold;
}

.footable-row-detail-name {
	display: table-cell;
	font-weight: bold;
	padding-right: 0.5em;
}

.footable-row-detail-value {
	display: table-cell;
}

.footable-odd {
	background-color: @colorStriping;
}
