.toc-wrapper {
  position: absolute;
  top: 108px;
  bottom: 0px;
  left: 0px;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  width: 173px;
  border-right: 1px solid #aaa;
  padding: 0 6px;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
}
@media print {
  .toc-wrapper {
    display: none;
  }
}
.toc-wrapper #btnCloseLayersPane {
  font-size: 12px;
  margin-top: 12px;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}
.toc-wrapper li.grouplayer span.name {
  font-weight: bold;
  cursor: default;
}
.toc-wrapper li.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.toc-wrapper li a {
  text-decoration: none;
}
.toc-wrapper ul.toc-main {
  list-style: none;
  padding-left: 20px;
  text-indent: 0px;
  font-size: 11px;
}
.toc-wrapper ul.group-of-layers {
  list-style: none;
  padding-left: 20px;
  text-indent: 0px;
  font-size: 11px;
}
.toc-wrapper .toc-checkbox,
.toc-wrapper .grouptoggle {
  font-size: 16px;
  margin-left: -18px;
  color: #555;
  width: 16px;
  top: 3px;
  cursor: default;
}
.toc-wrapper .toc-checkbox.inactive,
.toc-wrapper .grouptoggle.inactive {
  color: #999;
}
.toc-wrapper .groupvisibility {
  /*height: 16px;*/
  /*padding-left: 20px;*/
  color: #555;
  width: 16px;
  padding-right: 2px;
  position: relative;
  /*top: calc(50% - 13px);*/
  vertical-align: middle;
  cursor: pointer;
}
.toc-wrapper .groupvisibility.inactive {
  color: #999;
}
.toc-wrapper .active-layer {
  background-color: #ECECEC;
  border: 1px solid #939292;
}
.toc-wrapper span.icon-info-circled {
  float: right;
  font-size: 14px;
  color: #555;
  margin-right: 3px;
  margin-top: 1px;
}
.toc-wrapper .quicklink {
  font-size: 12px;
}
.toc-wrapper .quicklink a {
  text-decoration: none;
}
.toc-wrapper .toc-header {
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #d3d3d3;
}
.toc-wrapper .layer-tabs {
  margin-top: 10px;
}
.toc-wrapper .layer-tabs > li > a {
  padding: 3px 5px;
}
.toc-wrapper li[role="treeitem"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.toc-wrapper li[role="treeitem"] a:focus {
  outline: 0;
}
/*
   Animation example, for spinners
*/
.animate-stop {
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  animation: nospin;
  transform: rotate(0deg);
}
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@keyframes nospin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
