.notification {
  /*.notification-trash { display:block; min-width:300px;}*/
}
.notification .notification-title {
  /*    font-family: lato,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 17px;
    color: #333333;
    font-weight: normal;
    display: flex;*/
  display: inline-block;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 250px;
}
.notification .like-section {
  min-width: 300px;
}
.notification .trashed {
  display: none;
}
.notification .like-failure {
  margin-right: 6px;
  color: #ED0000;
}
.notification .notification-like {
  font-size: large;
}
.notification .notification-card {
  padding: 2px;
  cursor: pointer;
  box-shadow: 0 1px 4px 0 #000000;
  border-radius: 3px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.notification .subject-top {
  background-color: #E7E9EB;
}
.notification .notification-content-text {
  padding: 2px;
}
.notification .notification-data {
  position: relative;
  width: calc(100% - 52px);
}
.notification .notification-data-text {
  z-index: 500;
}
.notification .dropdown-menu {
  max-height: 540px;
  overflow-y: auto;
}
.notification .dropdown-menu.unread .notification-content-text {
  width: 100%;
}
.notification .dropdown-submenu.unread .notification-card .subject-top {
  background-image: url('../../icons/Notifications/icon-unread-dot.png');
  background-color: #E7E9EB;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 7px;
}
.notification .dropdown-submenu {
  position: relative;
  min-width: 300px;
}
.notification ul.dropdown-submenu {
  box-sizing: border-box;
  flex-basis: 100%;
  color: #172b4d;
  /*border: 1px solid rgba(9, 30, 66, 0.08);*/
  border-radius: 3px;
  padding: 2px;
  word-break: break-word;
}
.notification ul.dropdown-submenu:hover {
  background-color: rgba(9, 30, 66, 0.04);
}
.notification .dropdown-menu.unread ul.no-bullets {
  margin-left: 20px;
}
.notification ul.no-bullets {
  list-style-type: none;
  /* Remove bullets */
  padding-inline-start: 15px;
}
.notification .notification-item {
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 20px;
  margin-left: 10px;
}
.notification #notificationDropDown ul a,
.notification a:visited,
.notification a:active {
  text-decoration: underline;
  color: #3333cc;
}
