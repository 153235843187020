﻿@import "Variables.less";
@import "Utilities.less";
@import '../bootstrap-3.4.1/less/variables.less';
@import '../bootstrap-3.4.1/less/mixins.less';


.overRideBootrapCaret;
//standard fonts - not all were set in bootstrap
body,
input,
button,
select,
textarea
{
    font-family: @font-family-sans-serif;
}

/*b, strong {
    font-family: @font-family-sans-serif-bold;
}*/

//override bootstrap printing links
@media print
{
    a[href]:after
    {
        content: none;
    }
}

.pointer {
    cursor:pointer;
}


LEGEND {
    font-weight: bold;
    border-bottom: none;
    font-size: 11px;
    margin-bottom: 5px;
    color: #000;
}


// support for jquery validation
.error {
    //font-size: 9px;
    color: @error-red;
    //padding-left: 10px;
}



.slim-scrollbar {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
        //border-radius: 10px;
        background-color: rgba(0,0,0,0.3);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/
        background-color: rgba(0,0,0,0.5);
    }
}


// fix modal dialog location due to fixed header bar:
.modal {
   // top:60px;
}


// Dropdown section headers
.dropdown-header {
    color: @gray-dark;
}


// WCAG Hacks - mechanically fix some things so we don't have to update these (no reason we cant update though)

// override the markup <font color='red'> used in lots of modules
font[color=red] {
    color: @error-red
}
// or with style of color:Red
span[style*='color:Red'], span[style*='color:red'] {
    color: @error-red !important;
}


// Override the bootstrap link format - include underline for WCAG
a[href] {
    text-decoration: underline;

    &.toggle-collapse, &.btn {
        text-decoration: none;
    }
}

.dropdown-menu a[href] {
    text-decoration:none;
}
