.sale_search_container {
  overflow-y: visible;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.expandCollapseIcon {
  text-decoration: none !important;
}
.expandCollapseIcon.icon::before {
  vertical-align: middle;
  margin: 0 4px 0 0;
}
.sale_search_menu .dropdown-submenu > .dropdown-menu {
  left: -100% !important;
}
@media (min-width: 1024px) {
  .sale_search_container {
    top: 55px;
  }
}
@media (max-width: 1023px) {
  .sale_search_container {
    top: 30px;
  }
}
.sales-search-tabs {
  display: none !important;
}
.sales-search-tabs > .container {
  display: none;
}
.clearfix {
  overflow: auto;
  *zoom: 1;
}
.sprite {
  vertical-align: middle;
  *margin-right: 0.3em;
}
.sprite.sort-indicator {
  margin-left: 10px;
}
body {
  background-color: white;
}
.body-background {
  background-color: #eee;
}
.sticky-header-nav {
  position: sticky;
  z-index: 100;
  top: 0;
}
.page-container {
  background-color: white;
  box-shadow: 0px 0px 30px #aaa;
  padding-top: 15px;
}
.page-right-pane {
  background-color: white;
  overflow-wrap: break-word;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  min-height: 500px;
  background-image: -webkit-linear-gradient(left, #eee, #fff 10px, #fff);
  background-image: -o-linear-gradient(left, #eee, #fff 10px, #fff);
  background-image: linear-gradient(to right, #eee, #fff 10px, #fff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffffffff', GradientType=1);
  background-repeat: no-repeat;
}
@media print {
  .page-right-pane {
    display: none;
  }
}
.page-center-pane {
  background-color: white;
  /*overflow-x: auto;
    overflow-y: visible;*/
}
.right-pane-block {
  margin-left: -14px;
  max-width: 240px;
}
.right-pane-block .right-pane-header {
  color: white;
  font-size: 11pt;
  font-weight: bold;
  background-color: #1a2451;
  border-radius: 0 4px 0 0;
  padding: 4px 0px 4px 8px;
  margin: 0;
  line-height: 1.42857143;
}
.right-pane-block .right-pane-content {
  margin-left: 14px;
}
.right-pane-block.info-pane {
  margin-bottom: 20px;
}
.right-pane-block .info-pane-photo,
.info-pane-preview-block .info-pane-photo {
  width: 100%;
}
.right-pane-block .info-pane-photo-caption,
.info-pane-preview-block .info-pane-photo-caption {
  padding: 14px;
  color: white;
  background-color: #222;
  margin-bottom: 10px;
}
.right-pane-block .info-pane-photo-caption a,
.info-pane-preview-block .info-pane-photo-caption a,
.right-pane-block .info-pane-photo-caption a:hover,
.info-pane-preview-block .info-pane-photo-caption a:hover,
.right-pane-block .info-pane-photo-caption a:visited,
.info-pane-preview-block .info-pane-photo-caption a:visited,
.right-pane-block .info-pane-photo-caption a:link,
.info-pane-preview-block .info-pane-photo-caption a:link {
  color: white;
}
.right-pane-block .info-pane-photo-caption .line1,
.info-pane-preview-block .info-pane-photo-caption .line1 {
  font-weight: bold;
}
.right-pane-block .info-pane-item,
.info-pane-preview-block .info-pane-item {
  margin-left: 14px;
  margin-bottom: 10px;
}
.right-pane-block .info-pane-item .photo img,
.info-pane-preview-block .info-pane-item .photo img {
  width: 100%;
}
.right-pane-block .info-pane-item .photo img[src=''],
.info-pane-preview-block .info-pane-item .photo img[src=''] {
  display: none;
}
.right-pane-block .info-pane-item .title,
.info-pane-preview-block .info-pane-item .title {
  font-weight: bold;
}
@media (min-width: 992px) {
  .page-right-pane .module-header {
    display: none;
  }
  .announcement-content {
    min-height: 120px;
  }
}
@media (max-width: 991px) {
  .page-right-pane {
    margin-top: 20px;
    min-height: 0;
  }
  .page-right-pane .right-pane-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-top: 10px;
    display: none;
  }
  .page-right-pane .right-pane-block .right-pane-header {
    display: none;
  }
  .page-right-pane .right-pane-block .right-pane-content {
    margin-left: 14px;
  }
  .page-right-pane .right-pane-block .info-pane-item {
    text-align: center;
  }
  .page-right-pane .right-pane-block .info-pane-photo {
    border-radius: 4px 4px 0 0;
  }
  .page-right-pane .right-pane-block .info-pane-photo-caption {
    text-align: center;
    border-radius: 0 0 4px 4px;
  }
  .page-right-pane .right-pane-block.info-pane {
    margin-bottom: 20px;
  }
  .page-right-pane .announcement-content > div.right-pane-header {
    border-radius: 4px;
  }
}
.module-error {
  border: 1px solid #db7093;
  background-color: #ffe4e1;
  padding: 8px;
  overflow-wrap: break-word;
}
.module-error .error-message {
  text-align: center;
}
.module-error .stack-dump span {
  overflow-wrap: break-word;
  font-family: Consolas, 'Lucida Console', 'DejaVu Sans Mono', monospace;
  font-size: 12px;
}
.main-toolbar {
  text-align: right;
  color: #0b2345;
}
@media print {
  .main-toolbar {
    display: none;
  }
}
.main-toolbar .dropdown-menu {
  text-align: left;
}
.main-toolbar div.dropdown {
  cursor: pointer;
}
.main-toolbar .glyphicon,
.main-toolbar .icon {
  width: 30px;
  font-size: 18px;
  color: #0b2345;
  padding: 6px;
}
.main-toolbar .glyphicon:hover,
.main-toolbar .icon:hover {
  background-color: #ddd;
  border-radius: 5px;
}
.main-toolbar button {
  border: none;
  background: none;
  padding: 0;
}
.module-content {
  margin-bottom: 20px;
  margin-left: 24px;
}
.layer-list-container .layer {
  line-height: 24px;
  height: 24px;
}
.layer-list-container .layer IMG,
.layer-list-container .layer input[type=image] {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}
.layer-list-container .layer .left {
  margin-left: 4px;
  float: left;
}
.layer-list-container .layer .right {
  margin-right: 4px;
  float: right;
}
.module-header,
.taskpane-header {
  width: 100%;
  position: relative;
  height: 30px;
  line-height: 24px;
  overflow: hidden;
  margin-bottom: 4px;
  margin-left: -14px;
}
@media print {
  .module-header,
  .taskpane-header {
    border: none;
    margin-left: 0;
  }
}
.module-header .left,
.taskpane-header .left {
  margin-left: 4px;
  float: left;
}
.module-header .right,
.taskpane-header .right {
  margin-right: 4px;
  float: right;
}
.module-header .debug,
.taskpane-header .debug {
  font-style: italic;
  color: #666;
}
@media only screen and (max-width: 470px) {
  .module-header .debug,
  .taskpane-header .debug {
    display: none;
  }
}
@media print {
  .module-header .debug,
  .taskpane-header .debug {
    display: none;
  }
}
.module-header .sprite,
.taskpane-header .sprite {
  margin-bottom: 10px;
  line-height: 24px;
}
@media print {
  .module-header .sprite,
  .taskpane-header .sprite {
    display: none;
  }
}
.module-header IMG,
.taskpane-header IMG,
.module-header input[type=image],
.taskpane-header input[type=image] {
  margin-top: 3px;
  width: 16px;
  height: 16px;
}
@media print {
  .module-header IMG,
  .taskpane-header IMG,
  .module-header input[type=image],
  .taskpane-header input[type=image] {
    display: none;
  }
}
.module-header input[type=checkbox],
.taskpane-header input[type=checkbox] {
  margin: 0;
}
@media print {
  .module-header input[type=checkbox],
  .taskpane-header input[type=checkbox] {
    display: none;
  }
}
.module-header .title,
.taskpane-header .title,
.module-header H2,
.taskpane-header H2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  display: inline;
  margin: 0;
  color: #0b2345;
  cursor: pointer;
}
.module-header .toggle-collapse,
.taskpane-header .toggle-collapse {
  margin: 0 4px 0 0;
  padding: 6px 4px 3px 4px;
  border: thin solid transparent;
  background-color: transparent;
  display: inline;
}
.module-header .toggle-collapse:focus,
.taskpane-header .toggle-collapse:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  text-decoration: none;
}
.module-header .toggle-collapse:hover,
.taskpane-header .toggle-collapse:hover {
  text-decoration: none;
}
.module-header .toggle-collapse-icon,
.taskpane-header .toggle-collapse-icon {
  margin-bottom: 8px;
  vertical-align: middle;
  color: #0b2345;
  cursor: pointer;
}
@media print {
  .module-header .toggle-collapse-icon,
  .taskpane-header .toggle-collapse-icon {
    display: none;
  }
}
.disclaimer-content {
  padding: 10px 4px 4px 4px;
}
.nodata-content {
  padding: 60px 4px 120px 4px;
  text-align: center;
}
.nodata-modulelist {
  padding: 10px 4px 4px 4px;
}
.announcement-content ul {
  list-style: none;
}
.announcement-content ul li {
  margin-top: 10px;
  min-height: 24px;
}
.announcement-content ul li .icon {
  float: left;
  color: #0b2345;
  font-size: 24px;
  margin-right: 4px;
}
.advertise-content {
  padding: 10px 4px 4px 4px;
  text-align: center;
}
.toolbar {
  width: 100%;
  position: relative;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  border-bottom: 1px solid black;
}
@media print {
  .toolbar {
    display: none;
  }
}
.toolbar .nav-pills {
  display: inline-block;
  float: left;
}
.toolbar .left {
  margin-left: 4px;
  float: left;
}
.toolbar .right {
  margin-right: 4px;
  float: right;
}
.toolbar IMG,
.toolbar input[type=image] {
  line-height: 36px;
  margin-left: 18px;
}
.toolbar .blocked-item {
  color: #ED0000;
  font-size: 11pt;
  font-weight: bold;
  padding-left: 7px;
}
.toolbar select {
  margin-bottom: 3px;
}
.toolbar #ddlWexfordAction {
  width: 150px;
}
.toolbar #wexfordActionDiv {
  margin-left: 18px;
}
/*#ResultsPaneDiv {
    height: 600px;
    overflow-x: hidden;
    overflow-x: auto;
}*/
.overview-content,
.printoptions-content {
  background-color: #eee;
}
.module-content label {
  display: inline;
}
.module-content input[type="checkbox"],
.module-content input[type="radio"] {
  margin-top: 0px;
  margin-bottom: 6px;
  margin-right: 3px;
}
@media print {
  .module-content {
    margin-bottom: 10px;
  }
}
.skip-to-content a {
  padding: 6px;
  position: absolute;
  top: -40px;
  left: 0px;
  color: white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-bottom-right-radius: 8px;
  background: #BF1722;
  -webkit-transition: top 1s ease-out;
  transition: top 1s ease-out;
  z-index: 100;
}
.skip-to-content a:focus {
  position: absolute;
  left: 0px;
  top: 0px;
  outline-color: transparent;
  -webkit-transition: top 0.1s ease-in;
  transition: top 0.1s ease-in;
}
.devpane-content {
  margin-top: 24px;
  border: 2px solid red;
  background-color: #ffc0c0;
  padding: 4px;
}
.devpane-content #debugDetails {
  margin-top: 4px;
}
@media print {
  .NoPrint {
    display: none;
  }
}
@media screen {
  .NoScreen {
    display: none;
  }
}
a[href="#"].hideblank {
  display: none;
}
.SearchPageInline {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 100%;
}
.SearchPageInline .input-group {
  width: 50%;
}
@keyframes loading-anim {
  from {
    left: 50%;
    width: 0;
    z-index: 100;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10;
  }
  to {
    left: 0;
    width: 100%;
  }
}
/*div.sprite
{
    &:hover
    {
        -moz-transition: all 0.27s linear;
        -o-transition: all 0.27s linear;
        -webkit-transition: all 0.27s linear;
        transition: all 0.27s linear;
        -moz-transform:  rotate(6.28rad) scale(20,20);
        -ms-transform:  rotate(6.28rad) scale(20,20);
        -o-transform:  rotate(6.28rad) scale(20,20);
        -webkit-transform:  rotate(6.28rad) scale(20,20);
        transform:  rotate(6.28rad) scale(20,20);
    }

    &
    {
        -moz-transition: all 0.14s linear;
        -o-transition: all 0.14s linear;
        -webkit-transition: all 0.14s linear;
        transition: all 0.14s linear;
    }
}*/
