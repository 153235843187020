@font-face {
  font-family: 'footable';
  src: url('../../fonts/footable/footable.eot');
  src: url('../../fonts/footable/footable.eot?#iefix') format('embedded-opentype'), url('../../fonts/footable/footable.woff') format('woff'), url('../../fonts/footable/footable.ttf') format('truetype'), url('../../fonts/footable/footable.svg#footable') format('svg');
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'footable';
    src: url('../../fonts/footable/footable.svg#footable') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}
.footable {
  width: 100%;
  /** SORTING **/
  /** PAGINATION **/
}
.footable.breakpoint > tbody > tr.footable-detail-show > td,
.footable.breakpoint > tbody > tr.footable-detail-show > th {
  border-bottom: none;
}
.footable.breakpoint > tbody > tr.footable-detail-show > td > .footable-toggle:before,
.footable.breakpoint > tbody > tr.footable-detail-show > th > .footable-toggle:before {
  content: "\e001";
}
.footable.breakpoint > tbody > tr:hover:not(.footable-row-detail) {
  cursor: pointer;
}
.footable.breakpoint > tbody > tr > td.footable-cell-detail,
.footable.breakpoint > tbody > tr > th.footable-cell-detail {
  background: #eee;
  border-top: none;
}
.footable.breakpoint > tbody > tr > td > .footable-toggle,
.footable.breakpoint > tbody > tr > th > .footable-toggle {
  display: inline-block;
  font-family: 'footable';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  padding-right: 5px;
  font-size: 14px;
  color: #888;
  position: absolute;
}
.footable.breakpoint > tbody > tr > td > .footable-toggle:before,
.footable.breakpoint > tbody > tr > th > .footable-toggle:before {
  content: "\e000";
}
.footable.breakpoint.toggle-circle > tbody > tr.footable-detail-show > td > .footable-toggle:before,
.footable.breakpoint.toggle-circle > tbody > tr.footable-detail-show > th > .footable-toggle:before {
  content: "\e005";
}
.footable.breakpoint.toggle-circle > tbody > tr > td > .footable-toggle:before,
.footable.breakpoint.toggle-circle > tbody > tr > th > .footable-toggle:before {
  content: "\e004";
}
.footable.breakpoint.toggle-circle-filled > tbody > tr.footable-detail-show > td > .footable-toggle:before,
.footable.breakpoint.toggle-circle-filled > tbody > tr.footable-detail-show > th > .footable-toggle:before {
  content: "\e003";
}
.footable.breakpoint.toggle-circle-filled > tbody > tr > td > .footable-toggle:before,
.footable.breakpoint.toggle-circle-filled > tbody > tr > th > .footable-toggle:before {
  content: "\e002";
}
.footable.breakpoint.toggle-square > tbody > tr.footable-detail-show > td > .footable-toggle:before,
.footable.breakpoint.toggle-square > tbody > tr.footable-detail-show > th > .footable-toggle:before {
  content: "\e007";
}
.footable.breakpoint.toggle-square > tbody > tr > td > .footable-toggle:before,
.footable.breakpoint.toggle-square > tbody > tr > th > .footable-toggle:before {
  content: "\e006";
}
.footable.breakpoint.toggle-square-filled > tbody > tr.footable-detail-show > td > .footable-toggle:before,
.footable.breakpoint.toggle-square-filled > tbody > tr.footable-detail-show > th > .footable-toggle:before {
  content: "\e009";
}
.footable.breakpoint.toggle-square-filled > tbody > tr > td > .footable-toggle:before,
.footable.breakpoint.toggle-square-filled > tbody > tr > th > .footable-toggle:before {
  content: "\e008";
}
.footable.breakpoint.toggle-arrow > tbody > tr.footable-detail-show > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow > tbody > tr.footable-detail-show > th > .footable-toggle:before {
  content: "\e00f";
}
.footable.breakpoint.toggle-arrow > tbody > tr > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow > tbody > tr > th > .footable-toggle:before {
  content: "\e011";
}
.footable.breakpoint.toggle-arrow-small > tbody > tr.footable-detail-show > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow-small > tbody > tr.footable-detail-show > th > .footable-toggle:before {
  content: "\e013";
}
.footable.breakpoint.toggle-arrow-small > tbody > tr > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow-small > tbody > tr > th > .footable-toggle:before {
  content: "\e015";
}
.footable.breakpoint.toggle-arrow-circle > tbody > tr.footable-detail-show > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow-circle > tbody > tr.footable-detail-show > th > .footable-toggle:before {
  content: "\e01b";
}
.footable.breakpoint.toggle-arrow-circle > tbody > tr > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow-circle > tbody > tr > th > .footable-toggle:before {
  content: "\e01d";
}
.footable.breakpoint.toggle-arrow-circle-filled > tbody > tr.footable-detail-show > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow-circle-filled > tbody > tr.footable-detail-show > th > .footable-toggle:before {
  content: "\e00b";
}
.footable.breakpoint.toggle-arrow-circle-filled > tbody > tr > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow-circle-filled > tbody > tr > th > .footable-toggle:before {
  content: "\e00d";
}
.footable.breakpoint.toggle-arrow-tiny > tbody > tr.footable-detail-show > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow-tiny > tbody > tr.footable-detail-show > th > .footable-toggle:before {
  content: "\e01f";
}
.footable.breakpoint.toggle-arrow-tiny > tbody > tr > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow-tiny > tbody > tr > th > .footable-toggle:before {
  content: "\e021";
}
.footable.breakpoint.toggle-arrow-alt > tbody > tr.footable-detail-show > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow-alt > tbody > tr.footable-detail-show > th > .footable-toggle:before {
  content: "\e017";
}
.footable.breakpoint.toggle-arrow-alt > tbody > tr > td > .footable-toggle:before,
.footable.breakpoint.toggle-arrow-alt > tbody > tr > th > .footable-toggle:before {
  content: "\e019";
}
.footable.breakpoint.toggle-medium > tbody > tr > td > .footable-toggle,
.footable.breakpoint.toggle-medium > tbody > tr > th > .footable-toggle {
  font-size: 18px;
}
.footable.breakpoint.toggle-large > tbody > tr > td > .footable-toggle,
.footable.breakpoint.toggle-large > tbody > tr > th > .footable-toggle {
  font-size: 24px;
}
.footable > thead > tr > th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.footable > thead > tr > th.footable-sortable:hover {
  cursor: pointer;
}
.footable > thead > tr > th.footable-sorted > span.footable-sort-indicator:before {
  content: "\e013";
}
.footable > thead > tr > th.footable-sorted-desc > span.footable-sort-indicator:before {
  content: "\e012";
}
.footable > thead > tr > th > span.footable-sort-indicator {
  display: inline-block;
  font-family: 'footable';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  padding-left: 5px;
}
.footable > thead > tr > th > span.footable-sort-indicator:before {
  content: "\e022";
}
.footable > tfoot .pagination {
  margin: 0;
}
.footable.no-paging .hide-if-no-paging {
  display: none;
}
.footable-row-detail-inner {
  display: table;
}
.footable-row-detail-row {
  display: table-row;
  line-height: 1.5em;
}
.footable-row-detail-group {
  display: block;
  line-height: 2em;
  font-size: 1.2em;
  font-weight: bold;
}
.footable-row-detail-name {
  display: table-cell;
  font-weight: bold;
  padding-right: 0.5em;
}
.footable-row-detail-value {
  display: table-cell;
}
.footable-odd {
  background-color: #F7F7F7;
}
