/*** Print Page ***/
.print-mode {
  background-color: white;
  -webkit-print-color-adjust: exact;
  /*.module-header {
        border: none;
        background: none;

        .sprite {
            display: none;
        }

        .debug {
            display: none;
        }
    }*/
}
.print-mode .PrintModuleTitle {
  color: black;
  font-size: 10pt;
  font-weight: bold;
}
.print-mode .print-header-block {
  height: 56px;
}
.print-mode .print-header-block img {
  float: left;
  height: 40px;
  margin-top: 6px;
}
.print-mode .print-header-block .header-title {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 24px;
  line-height: 56px;
  padding: 0 10px;
}
.print-mode .print-title-block {
  *zoom: 1;
  color: black;
  margin-bottom: 10px;
}
.print-mode .print-title-block:before,
.print-mode .print-title-block:after {
  display: table;
  content: "";
  line-height: 0;
}
.print-mode .print-title-block:after {
  clear: both;
}
.print-mode .print-title-block:before,
.print-mode .print-title-block:after {
  display: table;
  content: " ";
}
.print-mode .print-title-block:after {
  clear: both;
}
.print-mode .print-title-block .PrintHeaderTitle {
  font-size: 22pt;
}
.print-mode .print-title-block .PrintTitle {
  font-size: 16pt;
}
.print-mode .print-title-block .PrintSubtitle {
  font-size: 12pt;
}
.print-mode .print-map-detail-block {
  padding-top: 10px;
}
.print-mode .print-map-detail-block TD {
  padding-right: 4px;
}
.print-mode .print-footer-block {
  padding-top: 10px;
}
.print-mode .print-footer-block .footer-disclaimer-text {
  font-size: 7pt;
  padding-top: 4px;
}
.print-mode .print-footer-block .footer-last-update {
  padding: 8px 0;
  font-size: 7pt;
}
.print-mode .print-footer-block .footer-credits {
  *zoom: 1;
  font-size: 7pt;
  padding-left: 0;
  padding-right: 0;
}
.print-mode .print-footer-block .footer-credits img {
  width: 160px;
  height: 38px;
}
.print-mode .print-footer-block .footer-credits:before,
.print-mode .print-footer-block .footer-credits:after {
  display: table;
  content: "";
  line-height: 0;
}
.print-mode .print-footer-block .footer-credits:after {
  clear: both;
}
.print-mode .print-footer-block .footer-credits:before,
.print-mode .print-footer-block .footer-credits:after {
  display: table;
  content: " ";
}
.print-mode .print-footer-block .footer-credits:after {
  clear: both;
}
.print-mode .print-footer-block .footer-credits .icon {
  font-size: 37px;
  margin-top: -6px;
  margin-right: 6px;
}
.print-mode .print-footer-block .footer-credits div {
  float: left;
}
.print-mode .print-overview-crosshair {
  height: 15px;
  width: 15px;
}
.print-mode .print-overview-crosshair:before {
  content: url(/Images/overview_replacement.gif);
}
.print-mode .print-overview-rectangle {
  overflow: hidden;
  cursor: move;
  border: 2px dotted red;
}
.print-mode table.footable {
  display: table;
}
#printSettingsDropdown li a span.glyphicon-ok {
  display: none;
  width: 16px;
  font-size: 11px;
  margin-left: -16px;
  margin-right: 0px;
  padding: 0;
}
body.print-layout-1 #printSettingsDropdown li.print-layout-1 a span.glyphicon-ok {
  display: inline-block;
}
#printSettingsDropdown li a span.glyphicon-ok {
  display: none;
  width: 16px;
  font-size: 11px;
  margin-left: -16px;
  margin-right: 0px;
  padding: 0;
}
body.print-layout-2 #printSettingsDropdown li.print-layout-2 a span.glyphicon-ok {
  display: inline-block;
}
#printSettingsDropdown li a span.glyphicon-ok {
  display: none;
  width: 16px;
  font-size: 11px;
  margin-left: -16px;
  margin-right: 0px;
  padding: 0;
}
body.print-layout-3 #printSettingsDropdown li.print-layout-3 a span.glyphicon-ok {
  display: inline-block;
}
@media print {
  @page {
    margin: 0.5in;
    orphans: 3;
    widows: 3;
  }
  .avoid-page-break {
    page-break-inside: avoid;
  }
  .print-layout-1 {
    font-size: 8.8px;
    line-height: 11.2px;
    /*.sketch-thumbnail {
            img {
                height: @photoAndSketchHeight;
                width: auto;
            }
        }*/
  }
  .print-layout-1 #tabAffix {
    display: none;
  }
  .print-layout-1 .page-center-pane {
    width: 100%;
  }
  .print-layout-1 .page-center-pane .module-header {
    margin-bottom: 3px;
    height: 20px;
  }
  .print-layout-1 .page-center-pane .module-header .title,
  .print-layout-1 .page-center-pane .module-header H2 {
    font-size: 12px;
    line-height: 18px;
  }
  .print-layout-1 .page-center-pane .module-content {
    margin-bottom: 5px;
    margin-left: 16px;
    page-break-inside: auto;
  }
  .print-layout-1 .tabular-data td,
  .print-layout-1 .tabular-data th {
    padding: 1px 4px;
    font-size: 8.8px;
    line-height: 11.2px;
  }
  .print-layout-1 .tabular-data td .footable-toggle,
  .print-layout-1 .tabular-data th .footable-toggle {
    visibility: hidden;
  }
  .print-layout-1 .photo-thumbnail img,
  .print-layout-1 .gen-image-thumbnail img {
    height: 200px;
  }
  .print-layout-1 .primary-photo-rs-print {
    height: 200px;
    width: auto;
    border-radius: 5px;
  }
  .print-layout-1 .beacon-header-large {
    height: 32px;
  }
  .print-layout-1 .beacon-header-large .leftside img {
    height: 32px;
  }
  .print-layout-1 .beacon-header-large .leftside .header-title {
    font-size: 20px;
    line-height: 46px;
  }
  .print-layout-1 .footer-container {
    padding-top: 10px;
    font-size: 7pt;
    page-break-inside: avoid;
  }
  .print-layout-2 {
    font-size: 8.8px;
    line-height: 11.2px;
    /*.sketch-thumbnail {
            img {
                height: @photoAndSketchHeight;
                width: auto;
            }
        }*/
  }
  .print-layout-2 #tabAffix {
    display: none;
  }
  .print-layout-2 .page-center-pane {
    width: 100%;
  }
  .print-layout-2 .page-center-pane .module-header {
    margin-bottom: 3px;
    height: 20px;
  }
  .print-layout-2 .page-center-pane .module-header .title,
  .print-layout-2 .page-center-pane .module-header H2 {
    font-size: 12px;
    line-height: 18px;
  }
  .print-layout-2 .page-center-pane .module-content {
    margin-bottom: 5px;
    margin-left: 16px;
    page-break-inside: auto;
  }
  .print-layout-2 .tabular-data td,
  .print-layout-2 .tabular-data th {
    padding: 1px 4px;
    font-size: 8.8px;
    line-height: 11.2px;
  }
  .print-layout-2 .tabular-data td .footable-toggle,
  .print-layout-2 .tabular-data th .footable-toggle {
    visibility: hidden;
  }
  .print-layout-2 .photo-thumbnail img,
  .print-layout-2 .gen-image-thumbnail img {
    height: 120px;
  }
  .print-layout-2 .primary-photo-rs-print {
    height: 120px;
    width: auto;
    border-radius: 5px;
  }
  .print-layout-2 .beacon-header-large {
    height: 32px;
  }
  .print-layout-2 .beacon-header-large .leftside img {
    height: 32px;
  }
  .print-layout-2 .beacon-header-large .leftside .header-title {
    font-size: 20px;
    line-height: 46px;
  }
  .print-layout-2 .footer-container {
    padding-top: 10px;
    font-size: 7pt;
    page-break-inside: avoid;
  }
  .print-layout-2 .sketch-thumbnail img {
    height: 120px;
    width: auto;
  }
  .print-layout-3 {
    font-size: 8.8px;
    line-height: 11.2px;
    /*.sketch-thumbnail {
            img {
                height: @photoAndSketchHeight;
                width: auto;
            }
        }*/
  }
  .print-layout-3 #tabAffix {
    display: none;
  }
  .print-layout-3 .page-center-pane {
    width: 100%;
  }
  .print-layout-3 .page-center-pane .module-header {
    margin-bottom: 3px;
    height: 20px;
  }
  .print-layout-3 .page-center-pane .module-header .title,
  .print-layout-3 .page-center-pane .module-header H2 {
    font-size: 12px;
    line-height: 18px;
  }
  .print-layout-3 .page-center-pane .module-content {
    margin-bottom: 5px;
    margin-left: 16px;
    page-break-inside: auto;
  }
  .print-layout-3 .tabular-data td,
  .print-layout-3 .tabular-data th {
    padding: 1px 4px;
    font-size: 8.8px;
    line-height: 11.2px;
  }
  .print-layout-3 .tabular-data td .footable-toggle,
  .print-layout-3 .tabular-data th .footable-toggle {
    visibility: hidden;
  }
  .print-layout-3 .photo-thumbnail img,
  .print-layout-3 .gen-image-thumbnail img {
    height: 0;
  }
  .print-layout-3 .primary-photo-rs-print {
    height: 120px;
    width: auto;
    border-radius: 5px;
  }
  .print-layout-3 .beacon-header-large {
    height: 32px;
  }
  .print-layout-3 .beacon-header-large .leftside img {
    height: 32px;
  }
  .print-layout-3 .beacon-header-large .leftside .header-title {
    font-size: 20px;
    line-height: 46px;
  }
  .print-layout-3 .footer-container {
    padding-top: 10px;
    font-size: 7pt;
    page-break-inside: avoid;
  }
  .print-layout-3 .sketch-thumbnail {
    display: none;
  }
  .print-layout-3 SECTION[LAYOUT=IMAGE] {
    display: none;
  }
  .icon-sg-logoPrint {
    display: block;
  }
  .icon-sg-logoPrint img.icon-sg-logo {
    width: 100%;
  }
  .icon-sg-logoScreen {
    display: none;
  }
}
@media print {
  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    float: left;
  }
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-11 {
    width: 91.66666667%;
  }
  .col-xs-10 {
    width: 83.33333333%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-8 {
    width: 66.66666667%;
  }
  .col-xs-7 {
    width: 58.33333333%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-5 {
    width: 41.66666667%;
  }
  .col-xs-4 {
    width: 33.33333333%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-2 {
    width: 16.66666667%;
  }
  .col-xs-1 {
    width: 8.33333333%;
  }
  .col-xs-pull-12 {
    right: 100%;
  }
  .col-xs-pull-11 {
    right: 91.66666667%;
  }
  .col-xs-pull-10 {
    right: 83.33333333%;
  }
  .col-xs-pull-9 {
    right: 75%;
  }
  .col-xs-pull-8 {
    right: 66.66666667%;
  }
  .col-xs-pull-7 {
    right: 58.33333333%;
  }
  .col-xs-pull-6 {
    right: 50%;
  }
  .col-xs-pull-5 {
    right: 41.66666667%;
  }
  .col-xs-pull-4 {
    right: 33.33333333%;
  }
  .col-xs-pull-3 {
    right: 25%;
  }
  .col-xs-pull-2 {
    right: 16.66666667%;
  }
  .col-xs-pull-1 {
    right: 8.33333333%;
  }
  .col-xs-pull-0 {
    right: auto;
  }
  .col-xs-push-12 {
    left: 100%;
  }
  .col-xs-push-11 {
    left: 91.66666667%;
  }
  .col-xs-push-10 {
    left: 83.33333333%;
  }
  .col-xs-push-9 {
    left: 75%;
  }
  .col-xs-push-8 {
    left: 66.66666667%;
  }
  .col-xs-push-7 {
    left: 58.33333333%;
  }
  .col-xs-push-6 {
    left: 50%;
  }
  .col-xs-push-5 {
    left: 41.66666667%;
  }
  .col-xs-push-4 {
    left: 33.33333333%;
  }
  .col-xs-push-3 {
    left: 25%;
  }
  .col-xs-push-2 {
    left: 16.66666667%;
  }
  .col-xs-push-1 {
    left: 8.33333333%;
  }
  .col-xs-push-0 {
    left: auto;
  }
  .col-xs-offset-12 {
    margin-left: 100%;
  }
  .col-xs-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xs-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xs-offset-9 {
    margin-left: 75%;
  }
  .col-xs-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xs-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xs-offset-6 {
    margin-left: 50%;
  }
  .col-xs-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xs-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xs-offset-3 {
    margin-left: 25%;
  }
  .col-xs-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xs-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xs-offset-0 {
    margin-left: 0%;
  }
  .visible-small {
    display: none !important;
  }
  .visible-medium {
    display: none !important;
  }
  .hidden-desktop {
    display: none !important;
  }
  .visible-desktop {
    display: inherit !important;
  }
}
@media print and (min-width: 1px) {
  .container {
    width: 10px;
  }
}
@media print and (min-width: 2px) {
  .container {
    width: 20px;
  }
}
@media print and (min-width: 3px) {
  .container {
    width: 100%;
  }
}
@media print and (min-width: 1px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media print and (min-width: 2px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media print and (min-width: 3px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
