﻿/*https: //gist.github.com/ohryan/b9593841c024c033783d*/
@import 'Variables.less';
@screen-sm-min : @small-layout-width;
@screen-md-min : @medium-layout-width;
@screen-lg-min : @max-page-width;

.text-xs-left {
    text-align: left;
}

.text-xs-right {
    text-align: right;
}

.text-xs-center {
    text-align: center;
}

.text-xs-justify {
    text-align: justify;
}

@media (min-width: @screen-sm-min) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }

    .text-sm-justify {
        text-align: justify;
    }
}

@media (min-width: @screen-md-min) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }

    .text-md-justify {
        text-align: justify;
    }
}

@media (min-width: @screen-lg-min) {
    .text-lg-left {
        text-align: left;
    }

    .text-lg-right {
        text-align: right;
    }

    .text-lg-center {
        text-align: center;
    }

    .text-lg-justify {
        text-align: justify;
    }
}

