﻿@import "Variables.less";
@import "Utilities.less";

#legendJsMapPane
{
    display:none; 
    margin-top:10px;
}

.legend
{
    clear:both;
}

.legendSwatch
{
    margin-left: -20px;
    height:15px;
    width:15px;
    float:left;
}

.legendLabel
{
    
}
.legendLabel2
{
    margin-left:10px;
    padding-top: 4px;
    font-weight:bold;
    clear:both;
}

.legendRow
{
    margin-left:28px;
    margin-top:4px;
    margin-right:4px;
    font-weight:bold;
}

.legendRow2
{
    margin-left:28px;
    margin-top:4px;
    margin-right:4px;
    font-weight:normal;
}

.legendExtraButton
{
    margin-left:30px;
    font-size:8pt;
    font-style:italic;
    color: @link-color;
    cursor: pointer;
    border: 0;
    background: none;
}
