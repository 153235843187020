﻿// this will override standard .modal css from bootstrap
@import "../bootstrap-3.4.1/less/variables";

.dropzone-dialog {

    .dz-success {
        .dz-cancel-button {
            display: none;
        }

        .progress {
            display: none;
        }
        
        .status {
            display: inline;
        }
    }

    .status {
        display: none;
    }

    .name {
        font-size: larger;
        font-weight: bold
    }

    overflow-y: auto;
    height: 500px;
}
