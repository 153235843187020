﻿@import "Variables.less";
@import "Utilities.less";
@import '../bootstrap-3.4.1/less/mixins.less';

//    MapJS CSS



.toc-wrapper {
    position: absolute;
    top: @header-height-normal;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255,255,255,1);
    overflow-y: auto;
    overflow-x: hidden;
    width: @toc-pane-width;
    border-right: 1px solid #aaa;
    padding: 0 6px;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    .DontPrint();

    #btnCloseLayersPane {
        font-size: 12px;
        margin-top: 12px;
        cursor: pointer;
        border: none;
        background: none;
        padding: 0;
    }

    li.grouplayer span.name {
        font-weight: bold;
        cursor: default;
    }

    li.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    li a {
        text-decoration: none;
    }

    ul.toc-main {
        list-style: none;
        padding-left: 20px;
        text-indent: 0px;
        font-size: 11px;
    }

    ul.group-of-layers {
        list-style: none;
        padding-left: 20px;
        text-indent: 0px;
        font-size: 11px;
    }


    .toc-checkbox, .grouptoggle {
        font-size: 16px;
        margin-left: -18px;
        color: #555;
        width: 16px;
        top: 3px;
        cursor: default;

        &.inactive {
            color: #999;
        }
    }

    .groupvisibility {
        /*height: 16px;*/
        /*padding-left: 20px;*/
        color: #555;
        width: 16px;
        padding-right: 2px;
        position: relative;
        /*top: calc(50% - 13px);*/
        vertical-align: middle;
        cursor: pointer;

        &.inactive {
            color: #999;
        }
    }

    .active-layer {
        background-color: #ECECEC;
        border: 1px solid @background-dark;
    }
    // active layer icon
    span.icon-info-circled {
        float: right;
        font-size: 14px;
        color: #555;
        margin-right: 3px;
        margin-top: 1px;
    }
    // quicklink formatting:
    .quicklink {
        font-size: 12px;

        a {
            text-decoration: none;
        }
    }

    .toc-header {
        font-weight: bold;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgb(211, 211, 211);
    }

    .layer-tabs {
        margin-top: 10px;

        & > li > a {
            padding: 3px 5px;
        }
    }
    // WCAG
    li[role="treeitem"] {

        &:focus {
            .tab-focus();
        }

        a:focus {
            outline: 0; // hide the focus on the link text
        }
    }
}

/*
   Animation example, for spinners
*/
    .animate-stop
    {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        animation: nospin;
        transform: rotate(0deg);
    }

    .animate-spin {
        -moz-animation: spin 2s infinite linear;
        -o-animation: spin 2s infinite linear;
        -webkit-animation: spin 2s infinite linear;
        animation: spin 2s infinite linear;
        display: inline-block;
    }

    @keyframes nospin {
        0% {
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    @-moz-keyframes spin {
        0% {
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -moz-transform: rotate(359deg);
            -o-transform: rotate(359deg);
            -webkit-transform: rotate(359deg);
            -ms-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }

    @-webkit-keyframes spin {
        0% {
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -moz-transform: rotate(359deg);
            -o-transform: rotate(359deg);
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }

    @-o-keyframes spin {
        0% {
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -moz-transform: rotate(359deg);
            -o-transform: rotate(359deg);
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }

    @-ms-keyframes spin {
        0% {
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -moz-transform: rotate(359deg);
            -o-transform: rotate(359deg);
            -webkit-transform: rotate(359deg);
            -ms-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }

    @keyframes spin {
        0% {
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -moz-transform: rotate(359deg);
            -o-transform: rotate(359deg);
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }