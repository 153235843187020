﻿// this will override standard .modal css from bootstrap
@import "../bootstrap-3.4.1/less/variables";


/*.modal-iframe {
     position: fixed;
    top: 50px;
    bottom: 50px;
    left: 50px;
    right: 50px;
    margin-left: 0;
    width: auto;

    .modal-body{
        max-height:none;
        overflow: hidden;
    }
}*/

// enhancing the settings to include an xtra-large modal size:
@modal-xlg: 1200px;

@media (min-width: @screen-lg-min) {
    .modal-xlg {
        //width: auto;
        //margin: 10px;
        width: @modal-xlg;
    }
}
