﻿@import "Variables.less";

.nearmap {
    #arrows:before,
    #arrows:after,
    #arrows b:before,
    #arrows b:after {
        position: absolute;
        width: 1.5em;
        text-align: center;
    }


    #arrows:before,
    #arrows:after {
        left: 4.25em;
    }

    #arrows:before {
        content: "N";
        top: 0;
        z-index: 1;
    }

    #arrows:after {
        content: "S";
        bottom: 0;
    }

    #arrows b:before {
        left: 0;
        content: "W";
    }

    #arrows b:after {
        right: 0;
        content: "E";
    }

    #arrows b {
        position: absolute;
        top: 4.25em;
        width: 100%;
    }

    #arrows {
        position: relative;
        width: 10em;
        height: 10em;
        line-height: 1.5em;
        margin: 1em auto;
        border: 0.0625em solid #444;
        border-radius: 50%;
        background-color: white;
        box-shadow: inset 0.125em 0.25em 0.5em #0005, -0.25em -0.5em 0.5em #FFFC, 0 0 0.0625em 0.5em #777, 0 0 0.0625em 0.5625em #000, 0.125em 0.25em 0.5em 0.5em #0004;
    }

    #arrows *,
    #arrows *:before,
    #arrows *:after {
        box-sizing: border-box;
        background-clip: padding-box;
    }

    #arrows span {
        position: absolute;
        top: 0;
        left: 4.9375em;
        width: 0.125em;
        height: 100%;
    }

    #arrows span + span {
        transform: rotate(90deg);
    }

    #arrows i,
    #arrows span:before,
    #arrows span:after {
        position: absolute;
        top: 0.5em;
        left: 0;
        width: 100%;
        height: 9em;
        background-color: #000
    }

    #arrows span:before,
    #arrows span:after {
        content: "";
        border-style: solid;
        border-color: #000;
        border-width: 0.5em 0;
        transform: rotate(45deg);
    }


    #arrows i:last-of-type:before,
    #arrows i:last-of-type:after {
        content: "";
        position: absolute;
        border-radius: 50%;
    }

    #arrows i {
        z-index: 2;
        left: 4.85em;
        width: 0.3em;
        background-color: red;
        border: solid transparent;
        border-width: 0 0 3em;
        border-radius: 40%;
    }

    #arrows i:last-of-type:after {
        top: 4.25em;
        left: -0.125em;
        width: 0.5em;
        height: 0.5em;
        background: #ABC;
    }

    #clock i:last-of-type {
        background-color: #F00;
    }



    #arrows i:last-of-type:before {
        top: 4em;
        left: -0.375em;
        width: 1em;
        height: 1em;
        background: #000;
    }
    #orientation.center.on{
        width:0em;
    }
    #arrows i.center.on:last-of-type:before {
        top: 3em;
        left: -1.375em;
        width: 3em;
        height: 3em;
        background: #000;
    }

    #arrows div {
        position: absolute;
        border-radius: 50%;
        width: 10em;
        height: 10em;
        box-sizing: border-box;
        border-width: 5em;
    }

    .arrow-down.on {
        border-color: transparent transparent #D3D3D3 transparent;
        border-style: solid;
        /* opacity: .4; */
    }

    .arrow-up.on {
        border-color: #D3D3D3 transparent transparent transparent;
        border-style: solid;
        /* opacity: .2; */
    }


    .arrow-left.on {
        border-color: transparent #D3D3D3 transparent transparent;
        border-style: solid;
        /* opacity: .5; */
    }

    .arrow-right.on {
        border-color: transparent transparent transparent #D3D3D3;
        border-style: solid;
        /* opacity: .8; */
    }

    .center.on {
        border-color: #D3D3D3 #D3D3D3 #D3D3D3 #D3D3D3;
        border-style: solid;
        /* opacity: .8; */
    }
}
