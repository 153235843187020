.nearmap #arrows:before,
.nearmap #arrows:after,
.nearmap #arrows b:before,
.nearmap #arrows b:after {
  position: absolute;
  width: 1.5em;
  text-align: center;
}
.nearmap #arrows:before,
.nearmap #arrows:after {
  left: 4.25em;
}
.nearmap #arrows:before {
  content: "N";
  top: 0;
  z-index: 1;
}
.nearmap #arrows:after {
  content: "S";
  bottom: 0;
}
.nearmap #arrows b:before {
  left: 0;
  content: "W";
}
.nearmap #arrows b:after {
  right: 0;
  content: "E";
}
.nearmap #arrows b {
  position: absolute;
  top: 4.25em;
  width: 100%;
}
.nearmap #arrows {
  position: relative;
  width: 10em;
  height: 10em;
  line-height: 1.5em;
  margin: 1em auto;
  border: 0.0625em solid #444;
  border-radius: 50%;
  background-color: white;
  box-shadow: inset 0.125em 0.25em 0.5em #0005, -0.25em -0.5em 0.5em #FFFC, 0 0 0.0625em 0.5em #777, 0 0 0.0625em 0.5625em #000, 0.125em 0.25em 0.5em 0.5em #0004;
}
.nearmap #arrows *,
.nearmap #arrows *:before,
.nearmap #arrows *:after {
  box-sizing: border-box;
  background-clip: padding-box;
}
.nearmap #arrows span {
  position: absolute;
  top: 0;
  left: 4.9375em;
  width: 0.125em;
  height: 100%;
}
.nearmap #arrows span + span {
  transform: rotate(90deg);
}
.nearmap #arrows i,
.nearmap #arrows span:before,
.nearmap #arrows span:after {
  position: absolute;
  top: 0.5em;
  left: 0;
  width: 100%;
  height: 9em;
  background-color: #000;
}
.nearmap #arrows span:before,
.nearmap #arrows span:after {
  content: "";
  border-style: solid;
  border-color: #000;
  border-width: 0.5em 0;
  transform: rotate(45deg);
}
.nearmap #arrows i:last-of-type:before,
.nearmap #arrows i:last-of-type:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}
.nearmap #arrows i {
  z-index: 2;
  left: 4.85em;
  width: 0.3em;
  background-color: red;
  border: solid transparent;
  border-width: 0 0 3em;
  border-radius: 40%;
}
.nearmap #arrows i:last-of-type:after {
  top: 4.25em;
  left: -0.125em;
  width: 0.5em;
  height: 0.5em;
  background: #ABC;
}
.nearmap #clock i:last-of-type {
  background-color: #F00;
}
.nearmap #arrows i:last-of-type:before {
  top: 4em;
  left: -0.375em;
  width: 1em;
  height: 1em;
  background: #000;
}
.nearmap #orientation.center.on {
  width: 0em;
}
.nearmap #arrows i.center.on:last-of-type:before {
  top: 3em;
  left: -1.375em;
  width: 3em;
  height: 3em;
  background: #000;
}
.nearmap #arrows div {
  position: absolute;
  border-radius: 50%;
  width: 10em;
  height: 10em;
  box-sizing: border-box;
  border-width: 5em;
}
.nearmap .arrow-down.on {
  border-color: transparent transparent #D3D3D3 transparent;
  border-style: solid;
  /* opacity: .4; */
}
.nearmap .arrow-up.on {
  border-color: #D3D3D3 transparent transparent transparent;
  border-style: solid;
  /* opacity: .2; */
}
.nearmap .arrow-left.on {
  border-color: transparent #D3D3D3 transparent transparent;
  border-style: solid;
  /* opacity: .5; */
}
.nearmap .arrow-right.on {
  border-color: transparent transparent transparent #D3D3D3;
  border-style: solid;
  /* opacity: .8; */
}
.nearmap .center.on {
  border-color: #D3D3D3 #D3D3D3 #D3D3D3 #D3D3D3;
  border-style: solid;
  /* opacity: .8; */
}
