﻿
// macros for overview map stuff
.xfrm(@angle) {
    -moz-transform: rotate(@angle);
    -ms-transform: rotate(@angle);
    -o-transform: rotate(@angle);
    -webkit-transform: rotate(@angle);
    transform: rotate(@angle);
} 

.anim(@name, @speed, @mode) {
    -moz-animation: @name @speed @mode;
    -o-animation: @name @speed @mode;
    -webkit-animation: @name @speed @mode;
    animation: @name @speed @mode;
}

.create-transform(@name, @angle1,@angle2) {

    @-webkit-keyframes @name {
        0% {
            -moz-transform: rotate(@angle1);
            -o-transform: rotate(@angle1);
            -webkit-transform: rotate(@angle1);
            -ms-transform: rotate(@angle1);
            transform: rotate(@angle1);
        }

        100% {
            -moz-transform: rotate(@angle2);
            -o-transform: rotate(@angle2);
            -webkit-transform: rotate(@angle2);
            -ms-transform: rotate(@angle2);
            transform: rotate(@angle2);
        }
    }

    @-moz-keyframes @name {
        0% {
            -moz-transform: rotate(@angle1);
            -o-transform: rotate(@angle1);
            -webkit-transform: rotate(@angle1);
            -ms-transform: rotate(@angle1);
            transform: rotate(@angle1);
        }

        100% {
            -moz-transform: rotate(@angle2);
            -o-transform: rotate(@angle2);
            -webkit-transform: rotate(@angle2);
            -ms-transform: rotate(@angle2);
            transform: rotate(@angle2);
        }
    }

    @keyframes @name {
        0% {
            -moz-transform: rotate(@angle1);
            -o-transform: rotate(@angle1);
            -webkit-transform: rotate(@angle1);
            -ms-transform: rotate(@angle1);
            transform: rotate(@angle1);
        }

        100% {
            -moz-transform: rotate(@angle2);
            -o-transform: rotate(@angle2);
            -webkit-transform: rotate(@angle2);
            -ms-transform: rotate(@angle2);
            transform: rotate(@angle2);
        }
    }

}