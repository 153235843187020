﻿@import "Variables.less";
@import "Utilities.less";
@import '../bootstrap-3.4.1/less/variables.less';
@import '../bootstrap-3.4.1/less/mixins.less';




.sale_search_container {
    overflow-y: visible;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}
.expandCollapseIcon {
    text-decoration: none !important;

    
}
.expandCollapseIcon.icon::before {
    vertical-align: middle;
    margin: 0 4px 0 0;
}

.sale_search_menu .dropdown-submenu > .dropdown-menu {
    left: -100% !important;
}

@media (min-width: 1024px) {
    .sale_search_container {
        top: 55px;
    }
}

@media (max-width: 1023px) {
    .sale_search_container {
        top: 30px;
    }
}

.sales-search-tabs {
    display: none !important;
}

.sales-search-tabs > .container {
    display: none;
}

.clearfix { // see: http://learnlayout.com/clearfix.html
    overflow: auto;
    *zoom: 1; // for ie6 support
}

// make sprite images work like an inline icon
.sprite {
    vertical-align: middle;
    //display: inline-block;
    *margin-right: .3em; //ie7 right margin issue
    &.sort-indicator {
        margin-left: 10px;
    }
}


body {
    background-color: white;
}


.body-background {
    background-color: @background;
}

.sticky-header-nav {
    position: sticky;
    z-index: 100;
    top: 0;
}

.page-container {
    background-color: white;
    // add subtle gradient outside of main page content
    box-shadow: 0px 0px 30px #aaa;
    padding-top: 15px;
}


.page-right-pane {
    .DontPrint();
    background-color: white;
    overflow-wrap: break-word;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    min-height: 500px;
    // provide small gradient between main content and info-pane
    // keep this in sync with the gradient in .page-center-pane
    #gradient > .horizontal-three-colors(@background, #fff, 10px, #fff);
}

.page-center-pane {
    background-color: white;
    /*overflow-x: auto;
    overflow-y: visible;*/
    // overflow-x:hidden; ** this was removed because when you use overflow-x, it implies overflow-y and causes the height to be clipped
    // provide small gradient between main content and info-pane
    // keep this in sync with the gradient in .page-right-pane
    //box-shadow: 0px 0px 30px #ddd;
}

.right-pane-block {
    // width: 150px;
    margin-left: -14px;
    max-width: 240px;
    //.clearfix();
    .right-pane-header {
        color: white;
        font-size: 11pt;
        font-weight: bold;
        background-color: #1a2451;
        border-radius: 0 4px 0 0;
        padding: 4px 0px 4px 8px;
        margin: 0;
        line-height: @line-height-base;
    }

    .right-pane-content {
        margin-left: 14px;
    }

    &.info-pane {
        margin-bottom: 20px;
    }
}

.info-pane-preview-block {
}

.right-pane-block, .info-pane-preview-block {
    .info-pane-photo {
        //margin-left: -14px;
        // width: 179px;
        width: 100%;
    }

    .info-pane-photo-caption {
        //margin-left: -14px;
        padding: 14px;
        //width: 179px;
        //width:100%;
        color: white;
        background-color: #222;
        margin-bottom: 10px;

        a, a:hover, a:visited, a:link {
            color: white;
        }

        .line1 {
            font-weight: bold;
        }
    }

    .info-pane-item {
        margin-left: 14px;
        margin-bottom: 10px;

        .photo {
            img {
                width: 100%;

                &[src=''] {
                    display: none;
                }
            }
        }

        .title {
            font-weight: bold;
        }

        .name {
        }

        .phone {
        }
    }
}


/// RWD for right pane (info, announcements)
@media (min-width: (@right-pane-cutoff-width + 2 )) {
    .page-right-pane {

        .module-header {
            display: none;
        }
    }

    .announcement-content {
        min-height: 120px;
    }
}

@media (max-width: (@right-pane-cutoff-width + 1)) {

    .page-right-pane {
        margin-top: 20px;
        min-height: 0;

        .right-pane-block {
            .center-block();
            padding-top: 10px;
            display: none; // initially hidden for mobile users
            .right-pane-header {
                display: none;
            }

            .right-pane-content {
                margin-left: 14px;
            }

            .info-pane-item {
                text-align: center;
            }

            .info-pane-photo {
                border-radius: 4px 4px 0 0;
            }

            .info-pane-photo-caption {
                text-align: center;
                border-radius: 0 0 4px 4px;
            }

            &.info-pane {
                margin-bottom: 20px;
            }
        }

        .announcement-content > div.right-pane-header {
            border-radius: 4px;
        }
    }
}



.module-error {
    border: 1px solid #db7093;
    background-color: #ffe4e1;
    padding: 8px;
    overflow-wrap: break-word;

    .error-message {
        text-align: center;
    }

    .stack-dump span {
        overflow-wrap: break-word;
        font-family: Consolas, 'Lucida Console', 'DejaVu Sans Mono', monospace;
        font-size: 12px;
    }
}

.main-toolbar {
    .DontPrint();
    text-align: right;
    color: @schneider-blue;

    .dropdown-menu {
        text-align: left;
    }

    div.dropdown {
        cursor: pointer;
    }

    .glyphicon, .icon {
        width: 30px;
        font-size: 18px;
        color: @schneider-blue;
        padding: 6px;

        &:hover {
            background-color: #ddd;
            border-radius: 5px;
        }
    }

    button {
        // clear the visual aspect of a button
        border: none;
        background: none;
        padding: 0;
    }
}


.module-content {
    margin-bottom: 20px;
    margin-left: 24px;
}

.taskpane-content {
    //padding: 4px 8px 4px 20px;
}

.layer-list-container {
    .layer {
        line-height: 24px;
        height: 24px;

        IMG, input[type=image] {
            width: 16px;
            height: 16px;
            margin-top: 3px;
        }

        .left {
            margin-left: 4px;
            float: left;
        }

        .right {
            margin-right: 4px;
            float: right;
        }
    }
}





.module-header, .taskpane-header {
    width: 100%;
    position: relative;
    height: 30px;
    line-height: 24px; //centers the text vertically
    //border-bottom: 1px solid black;
    overflow: hidden;
    margin-bottom: 4px;
    margin-left: -14px;

    @media print {
        border: none;
        margin-left: 0;
    }

    .left {
        margin-left: 4px;
        float: left;
    }

    .right {
        margin-right: 4px;
        float: right;
    }

    .debug {
        font-style: italic;
        color: #666;

        @media only screen and (max-width: @small-layout-width) {
            display: none;
        }

        .DontPrint();
    }

    .hint {
    }

    .count {
    }

    .sprite {
        //margin-left: 4px;
        margin-bottom: 10px;
        line-height: 24px;
        .DontPrint();
    }

    IMG, input[type=image] {
        margin-top: 3px;
        width: 16px;
        height: 16px;
        .DontPrint();
    }

    input[type=checkbox] {
        //top: 2px;
        //position: relative;
        margin: 0;
        .DontPrint();
    }

    .title,
    H2 {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        display: inline;
        margin: 0;
        color: @schneider-blue;
        cursor: pointer;
    }

    .toggle-collapse {
        margin: 0 4px 0 0;
        padding: 6px 4px 3px 4px;
        border: thin solid transparent;
        background-color: transparent;
        display: inline;

        &:focus {
            // WCAG - don't remove the focus styling for the button
            .tab-focus();
            text-decoration: none;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .toggle-collapse-icon {
        .DontPrint();
        margin-bottom: 8px;
        vertical-align: middle;
        color: @schneider-blue;
        cursor: pointer;
    }
}

.disclaimer-content {
    padding: 10px 4px 4px 4px;
}

.nodata-content {
    padding: 60px 4px 120px 4px;
    text-align: center;
}

.nodata-modulelist {
    padding: 10px 4px 4px 4px;
}

.announcement-content {
    ul {
        list-style: none;

        li {
            margin-top: 10px;
            min-height: 24px; // provide min height for icons
            .icon {
                float: left;
                color: @schneider-blue;
                font-size: 24px;
                margin-right: 4px;
            }
        }
    }
}

.advertise-content {
    padding: 10px 4px 4px 4px;
    text-align: center;
}

.toolbar {
    .DontPrint();
    width: 100%;
    position: relative;
    height: 36px;
    line-height: 36px; //centers the text vertically
    overflow: hidden;
    border-bottom: 1px solid black;

    .nav-pills {
        display: inline-block;
        float: left;
    }

    .left {
        margin-left: 4px;
        float: left;
    }

    .right {
        margin-right: 4px;
        float: right;
    }

    IMG, input[type=image] {
        //margin-top: 3px;
        // width: 16px;
        // height: 16px;
        line-height: 36px;
        margin-left: 18px;
        ;
    }

    .blocked-item {
        color: @error-red;
        font-size: 11pt;
        font-weight: bold;
        padding-left: 7px;
    }

    select {
        margin-bottom: 3px;
    }

    #ddlWexfordAction {
        width: 150px;
    }

    #wexfordActionDiv {
        margin-left: 18px;
    }
}

/*#ResultsPaneDiv {
    height: 600px;
    overflow-x: hidden;
    overflow-x: auto;
}*/

.overview-content,
.printoptions-content {
    background-color: @background;
}
// Override stock bootstrap settings for html in module areas:
.module-content {

    label {
        display: inline;
    }

    input[type="checkbox"],
    input[type="radio"] {
        margin-top: 0px;
        margin-bottom: 6px;
        margin-right: 3px;
    }

    @media print {
        margin-bottom: 10px;
    }
}

// WCAG Skip to content links
.skip-to-content a {
    padding: 6px;
    position: absolute;
    top: -40px;
    left: 0px;
    color: white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-bottom-right-radius: 8px;
    background: #BF1722;
    -webkit-transition: top 1s ease-out;
    transition: top 1s ease-out;
    z-index: 100;

    &:focus {
        position: absolute;
        left: 0px;
        top: 0px;
        outline-color: transparent;
        -webkit-transition: top .1s ease-in;
        transition: top .1s ease-in;
    }
}

.devpane-content {
    margin-top: 24px;
    border: 2px solid red;
    background-color: #ffc0c0;
    padding: 4px;

    #debugDetails {
        margin-top: 4px;
    }
}

.NoPrint {
    .DontPrint();
}

.NoScreen {
    @media screen {
        display: none;
    }
}

a[href="#"].hideblank {
    display: none;
}

.SearchPageInline {
    position: relative;
    display: table;
    border-collapse: separate;
    width: 100%;

    .input-group{width:50%;}
}
@keyframes loading-anim {
    from {
        left: 50%;
        width: 0;
        z-index: 100;
    }

    33.3333% {
        left: 0;
        width: 100%;
        z-index: 10;
    }

    to {
        left: 0;
        width: 100%;
    }
}

//wacky
/*div.sprite
{
    &:hover
    {
        -moz-transition: all 0.27s linear;
        -o-transition: all 0.27s linear;
        -webkit-transition: all 0.27s linear;
        transition: all 0.27s linear;
        -moz-transform:  rotate(6.28rad) scale(20,20);
        -ms-transform:  rotate(6.28rad) scale(20,20);
        -o-transform:  rotate(6.28rad) scale(20,20);
        -webkit-transform:  rotate(6.28rad) scale(20,20);
        transform:  rotate(6.28rad) scale(20,20);
    }

    &
    {
        -moz-transition: all 0.14s linear;
        -o-transition: all 0.14s linear;
        -webkit-transition: all 0.14s linear;
        transition: all 0.14s linear;
    }
}*/
