﻿@import "Variables.less";
@import "Utilities.less";
@import "utils/animation-utils.less";


.mapToolbar {
    position: absolute;
    .white-background-50();
    cursor: default;
    .DontPrint();


    div {
        float: left;
        display: block;
        width: 20px;
        height: 20px;
        margin: 2px;
        padding: 2px;
        border: 1px solid #440E11;
        background-color: #999;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        border-radius: 5px;

        &:hover {
            background-color: #b0b0b0;
        }

        .sprite {
            margin: 2px;
        }

        &.ItemActive {
            background-color: #ccc;
        }

        &.ItemInactive {
        }
    }
}

.esri-ui-top-left {
    width: 100%;
}

.mapToolBar {
    width: 350px;
}

.tablink {
    background-color: #555;
    color: white;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px;
    font-size: 10px;
    width: 25%;
}

/* Change background color of buttons on hover */
.tablink:hover {
    background-color: #777;
}

/* Set default styles for tab content */
.tabcontent {
    color: white;
    display: none;
    background-color: white;
    font-size: 10px;
    /*padding: 50px;*/
    /*text-align: center;*/
}




.mapToolbarDangle {
    position: absolute;
    .white-background-50();
    cursor: default;
}


.olControlMousePosition {
    .white-background-50();
}



.spatialselectionPane {
    position: absolute;
    .white-background-50();
    padding: 2px;
    /*INPUT[type=text] {
        width: 32px;
        font-size: 7pt;
    }*/
    INPUT[type=radio] {
        vertical-align: text-bottom;
        margin-left: 4px;
    }

    INPUT[type=checkbox] {
        vertical-align: text-bottom;
    }
    /*SELECT {
       height: auto;
    }*/
    .form-inline {
        margin-left: 4px;
    }

    .spatialselectionPaneFrame {
        padding: 4px;
        background-color: #eee;
        line-height: 18px;
    }

    hr {
        border-top: 1px solid #ccc;
        margin: 8px 0;
    }
}


.client-added-layers {

    ul {
        list-style-type: none;
        padding-left: 5px;
        width: 100%
    }
    /*    li {
        display: inline-flex;
    }*/

    input[type=checkbox] {
        vertical-align: text-bottom;
        font-size: 16px;
        /*margin-left: -18px;*/
        padding-left: 5px;
        color: #555;
        width: 16px;
        top: 3px;
        cursor: default;
    }

    input[type=file] {
        display: none;
    }

    .map-tool-image {
        width: 13px;
        vertical-align: text-bottom;
    }
}


.measurePane {
    position: absolute;
    .white-background-50();
    padding: 2px;

    INPUT[type=text] {
        width: 32px;
        font-size: 7pt;
    }

    .measurePaneFrame {
        padding: 4px;
        background-color: #eee;
        line-height: 18px;
    }
}

/*.scalebar-dropdown {
    min-width: 0px;
}*/

#ZoomToXYPanel {
    line-height: 24px;

    input {
        width: 80px;
        line-height: normal;
    }
}

#measureOverlay {
    input[type='text'] {
        border-style: none;
        background-color: transparent;
        width: 72px;
        margin: 0 6px;
        text-align: right;
        font-size: 8pt;
    }

    select {
        width: 63px;
    }

    #btnMeasureDone {
        margin-left: 10px;
    }
}

.zoomtoxypPane {
    position: absolute;
    .white-background-50();
    padding: 2px;

    .zoomtoxypPaneFrame {
        padding: 4px;
        background-color: #eee;
        line-height: 18px;
    }
}




/* markup controls */
.mapmarkupFrame {
    .white-background-50();
    padding: 2px;
}

#MapMarkupPanel {
    background-color: #eee;
    line-height: 18px;
    height: 100px;

    .mapMarkup { // tools
        float: left;
        /*display: block;*/
        width: 20px;
        height: 20px;
        margin: 2px;
        padding: 2px;
        border: 1px solid #440E11;
        background-color: #999;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        border-radius: 5px;

        &:hover {
            background-color: #b0b0b0;
        }

        .sprite {
            margin: 2px;
        }
    }


    .mmActive {
        background-color: #ccc;
    }

    .mmColorPalette {
        padding: 1px;
        background-color: #ccc;
        width: 144px;
    }

    .mmColorSwatch {
        float: left;
        padding: 1px;
        margin: 1px;
        border: 1px solid black;
        width: 12px;
        height: 12px;
    }

    .mmColorButton {
        display: inline-block;
        border: 1px solid black;
        width: 16px;
        height: 16px;
        background-color: #f00;
        margin-bottom: -4px;
    }

    .mmMarkerPalette {
        padding: 1px;
        background-color: #ccc;
        width: 110px;
    }

    .mmMarkerSwatch {
        float: left;
        padding: 1px;
        margin: 1px;
        border: 1px solid black;
        width: 16px;
        height: 16px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .mmMarkerButton {
        display: inline-block;
        border: 1px solid black;
        width: 16px;
        height: 16px;
        background-position: center center;
        background-repeat: no-repeat;
        margin-bottom: -4px;
    }

    #mmSize {
        height: 20px;
        vertical-align: text-bottom;
        margin-bottom: -4px;
    }

    .mmInputs {
        position: absolute;
        top: 36px;
        left: 4px;
        padding: 4px;
        line-height: 24px;
    }
}

/* cogo tools */
.mapcogoFrame {
    .white-background-50();
    padding: 2px;
}

#CogoPanel {
    background-color: #eee;
    line-height: 18px;
    min-height: 32px;
    //height: 100px;
    .cogoTool { // tools
        float: left;
        /*display: block;*/
        width: 20px;
        height: 20px;
        margin: 2px;
        padding: 2px;
        border: 1px solid #440E11;
        background-color: #999;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        border-radius: 5px;

        &:hover {
            background-color: #b0b0b0;
        }

        .sprite {
            margin: 2px;
        }

        &.disabled-tool {
            pointer-events: none;
            opacity: 0.4;
        }
    }


    .mmActive {
        background-color: #ccc;
    }

    .cogoInputs {
        padding: 4px;
    }

    .cogo-tool-title {
        font-size: larger;
        font-weight: bold;
    }

    .cogo-tool-instructions {
    }
}


.sprite.MaptipClose {
    cursor: pointer;
    top: 5px;
    right: 33px;
    z-index: 2;
}

.sprite.MaptipPinUp,
.sprite.MaptipPinDown {
    cursor: pointer;
    right: 52px;
    top: 5px;
    z-index: 2;
}


.olMapTipFrameContent {
    padding: 5px 5px 0px 5px;
    overflow: hidden;


    .title {
        padding-bottom: 5px;
        font-weight: bold;
        font-size: 11px;
    }

    .content {
        font-size: 7pt;
    }

    .loader {
        background-image: url(/Images/MapTips/loader.gif);
        background-repeat: no-repeat;
        background-position: center center;
        height: 12px;
        width: 100px;
        padding: 15px 5px 0px 5px;
    }
}

.olCursorNotAllowed {
    cursor: not-allowed;
}

.mobile-collapsed {
    display: none;

    .mapTip-mobile {
    }
}

.mapTip-mobile {
    position: absolute;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: lightgray;
    width: 100%;


    .header {
        height: 20px;
        background-color: "whitesmoke";

        .title {
            padding-bottom: 5px;
            font-weight: bold;
            font-size: 11px;
        }
    }

    .content {
        font-size: 9px;
        padding: 2px;
    }
}

.mapTip {
    position: absolute;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: lightgray;
    width: 240px;
    height: 129px;
    box-shadow: rgb(51, 51, 51) 0px 0px 10px;
    overflow: hidden;

    .header {
        height: 20px;
        background-color: "whitesmoke";

        .title {
            padding-bottom: 5px;
            font-weight: bold;
            font-size: 11px;
        }
    }

    .content {
        font-size: 9px;
        padding: 2px;
    }
}




/* used by some tools to prevent mouse drags off the map from selecting other content */
.disableTextSelection {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* avoid pink tiles */
.olImageLoadError {
    /*background-color: transparent !important;*/
    visibility: hidden;
}


/* general overlay */
#generalOverlay {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 50px;
    bottom: 50px;
    border: 1px solid black;
    background-color: White;
}

#generalOverlayClose {
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: 20000;
}



/* ajax message display - UR corner of map */
#activityIndicator {
    position: absolute;
    z-index: 9000;
    right: 40px;
    top: 0px;
    background-color: #900000;
    color: #ffffff;
    font-weight: bold;
    font-size: 8pt;
    padding: 2px;
}



/* OL overview map */


.bControlOverviewMapElement {
    padding: 0px;
    background-color: #fff;
    -moz-border-radius: 1em 0 0 0;
    border-radius: 1em 0 0 0;


    .bControlOverviewMapExtentRectangle {
        overflow: hidden;
        //background-image: url("/Images/blank.gif");
        cursor: move;
        border: 2px dotted red;
    }

    .sprite.OverviewCrosshair {
        cursor: move;
    }
}


.coordiantes {
    .white-background-50();
    padding: 1px 3px 1px 3px;
    position: absolute;
    max-width: 175px;


    .text {
        font-size: 8pt;
        text-align: center;
        float: right;
        padding-right: 3px;
    }

    .toggle {
        width: 9px;
        height: 9px;
        margin: 2px 0px 2px 0px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAI0lEQVQY02NggID/eDBcAT7wH5ui/xQrwuUeGlmH13cEwwkAw80k3JlgCzsAAAAASUVORK5CYII=') /*/Images/plus_9px.png*/;
        *background-image: url("/Images/plus_9px.png"); /* For IE 6 and 7 */
        background-repeat: no-repeat;
        float: right;
    }
}


.bControlDisplayXY {
    bottom: 0px;
    right: 0px;
    display: block;
    position: absolute;
    padding: 1px 3px 1px 3px;
    .white-background-50();
    width: 24px;


    .bControlDisplayXYText {
        font-size: 8pt;
        text-align: center;
        float: right;
        padding-right: 3px;
    }

    .bControlDisplayXYToggle {
        width: 9px;
        height: 9px;
        margin: 2px 0px 2px 0px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAI0lEQVQY02NggID/eDBcAT7wH5ui/xQrwuUeGlmH13cEwwkAw80k3JlgCzsAAAAASUVORK5CYII=') /*/Images/plus_9px.png*/;
        *background-image: url("/Images/plus_9px.png"); /* For IE 6 and 7 */
        background-repeat: no-repeat;
        float: right;
    }
}

.bScalebar {
    position: absolute;
    left: 8px;
    bottom: 8px;
    .white-background-50();
    padding: 1px;
    z-index: 1200;
    .DontPrint();

    &:hover {
        .white-background-75();
    }

    .bScalebarLine {
        width: 94px;
        font-size: 9px;
        font-weight: bold;
        text-align: center;
        border: 2px solid black;
        border-top: none;

        .up-arrow {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 5px;
            border-top: 0;
            border-bottom: 5px solid;
            vertical-align: middle;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
        }
    }

    #scaleDropupMenu {
        width: 96px;
        min-width: 96px;
    }
}

.bEsriLogo {
    position: absolute;
    right: 0px;
    bottom: 12px;
    padding: 1px;
}

/* this is for the touch zoom panel */

div.bMobileZoomPanel {
    top: 14px;
    left: 14px;
    z-index: 1200;
    position: absolute;

    div {
        position: absolute;
        height: 32px;
        width: 32px;
        background-color: white;
        border: 1px solid #333;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #333;

        &:hover {
            background-color: darken( white, 10%);
        }
    }

    #btnZoomIn {
        top: 0px;
        border-radius: 5px 5px 0 0;
    }

    #btnZoomExtent {
        top: 31px;
        content: "H";
    }

    #btnZoomOut {
        top: 62px;
        border-radius: 0 0 5px 5px;
    }

    #btnGpsMode {
        display: none; // not ready yet
        top: 100px;
        border-radius: 5px;

        &.active {
            color: #00bee9; //3A3AFF;
        }
    }
}

#btnGpsModeTip {
    position: absolute;
    top: 120px;
    left: 46px;
}

// ----------------- new --------------------------



body.map-view {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#olMap {
    margin: 0;
    padding: 0;
    top: @header-height-normal;
    bottom: 0;
    right: 0; //@results-pane-width;
    left: @toc-pane-width;
    background-color: white;
    position: fixed;
    .DontPrint();
}



#agsMap {
    margin: 0;
    padding: 0;
    top: @header-height-normal;
    bottom: 0;
    right: 0; //@results-pane-width;
    left: @toc-pane-width;
    background-color: white;
    position: fixed;
    .DontPrint();
}

#agsToolBar {
    background: #fff;
    padding: 5px;
    margin-left: 50px;
}

#agsToolBar2 {
    background: #fff;
    padding: 5px;
    /*left: 60px !important;*/
}

.agsTool2 {
    float: left;
    /*font-size: 16px;
    background-color: transparent;
    border: 1px solid #d3d3d3;
    color: #6e6e6e;
    height: 32px;
    width: 32px;
    text-align: center;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);*/
}

.agsSubToolBar {
    background: #fff;
    padding: 5px;
    /*left: 60px;*/
}

#agsIdentifyOptionPanel {
    /*position: absolute;*/
    /*z-index: 1022;*/
    .white-background-50();
    padding: 2px;
    /*left: 60px;*/
    /*top: 42px;*/
    width: 150px;

    div {
        background-color: #eee;
        padding: 3px 6px 0px 6px;
    }
}

.optionDialogBottom {
    font-size: 10px;
    width: 75%;
    background-color: rgba(255,255,255, 0.9);
    padding: 10px
}

.optionDialog {
    /*display:none;*/
    font-size: 10px;
    padding: 10px;
    background-color: rgba(255,255,255, 0.9);
    max-height: 500px;
}

.widget-dialog-floating {
    font-family: "Segoe UI", Verdana, Tahoma, Helvetica, sans-serif;
    cursor: default;
    font-size: 8pt;
    padding-top: 0px;
    border-bottom: 1px solid #8b9097;
    -webkit-box-shadow: #8b9097 0px 1px 3px;
    -moz-box-shadow: #8b9097 0px 1px 3px;
    box-shadow: #8b9097 0px 1px 3px;
    overflow: hidden;
    z-index: 25;
    background-color: rgba(250,247,243, 0.9);

    .title-bar {
        margin: 4pt;
        white-space: nowrap;
        cursor: move;

        .title-actions {
            padding-left: 4pt;
            padding-right: 4pt;
            float: right;
            cursor: pointer;
        }
    }

    .content {
        padding: 1px;
        margin-top: 5px;
        background: #FFF url('rib-back.png') repeat-x bottom;
        background: -webkit-gradient(linear, 0 0, 0 bottom, from(#ffffff), to(#eaedf1));
        background: -moz-linear-gradient(#ffffff, #eaedf1);
        background-color: rgba(0,0,0,0);
        overflow: visible;
        max-height: 400px;
        behavior: url(/PIE.htc);
        z-index: 30;
        -pie-watch-ancestors: 2;
        padding: 10pt;
    }

    .attribute-input-group {
        overflow: auto;
        max-height: 500px;
    }

    .button:hover {
        border: 1px solid #F1C43F;
        background: #fdeeb3;
        cursor: pointer;
    }

    .button {
        width: 20px;
        border: 1px solid rgba(0, 0, 0, 0);
        cursor: pointer;
    }

    .active {
        border: 1px solid #F1C43F;
        background: #F1C43F;
        cursor: pointer;
    }

    .tool-button {
        padding: 4px 6px;
        border: 1px solid transparent;
        -webkit-border-radius: 3px 3px 3px 3px;
        -moz-border-radius: 3px 3px 3px 3px;
        border-radius: 3px 3px 3px 3px;
        text-align: center;
        min-width: 32px;
        float: left;
    }

    .disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    .tool-button:hover {
        border: 1px solid #F1C43F;
        background: #fdeeb3;
        cursor: pointer;
    }

    .map-tool-image {
        width: 13px;
    }
}

vaadin-grid-cell-content {
    font-size: x-small !important;
    --lumo-font-size-s: x-small;
}


.widget-dialog-dropbeneath {
    font-family: "Segoe UI", Verdana, Tahoma, Helvetica, sans-serif;
    cursor: default;
    /*font-size: 8pt;*/
    padding-top: 0px;
    background-color: rgba(250,247,243, 0.9); // rgba(234,237,241, 0.9); // #eaedf1;
    border-bottom: 1px solid #8b9097;
    -webkit-box-shadow: #8b9097 0px 1px 3px;
    -moz-box-shadow: #8b9097 0px 1px 3px;
    box-shadow: #8b9097 0px 1px 3px;
    overflow: hidden;
    z-index: 25;

    .button:hover {
        border: 1px solid #F1C43F;
        background: #fdeeb3;
        cursor: pointer;
    }

    .button {
        width: 20px;
        border: 1px solid rgba(0, 0, 0, 0);
        cursor: pointer;
    }

    .button-title {
        color: #444;
        font-size: 7pt;
        padding-left: 5pt;
    }

    .active {
        border: 1px solid #F1C43F;
        background: #F1C43F;
        cursor: pointer;
    }

    .tool-button {
        padding: 4px 6px;
        border: 1px solid transparent;
        -webkit-border-radius: 3px 3px 3px 3px;
        -moz-border-radius: 3px 3px 3px 3px;
        border-radius: 3px 3px 3px 3px;
        text-align: center;
        min-width: 32px;
        float: left;
    }

    .disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    .tool-button:hover {
        border: 1px solid #F1C43F;
        background: #fdeeb3;
        cursor: pointer;
    }

    .map-tool-image {
        width: 13px;
    }
}

.cogo-operation-list {
    max-height: 200px;
    overflow-y: auto;
    border-color: darkgray;
    border-style: solid;
    border-width: 1px;
    padding: 0;
    margin-top: 5px;
    /*list-style-type: none;*/
    li {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        padding: 2.5px;
        border: 1px solid rgba(0, 0, 0, 0);

        .button {
            width: 15px;
            margin-left: 5px;
        }

        span {
            margin-left: 15px;
        }
    }

    li:hover {
        border: 1px solid #F1C43F;
        background: #fdeeb3;
    }
}


.cogo-input-group {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-top: 5px;

    label {
        padding-right: 10px;
        min-width: 100px;
    }

    input {
        width: 100px;
    }

    select {
        width: 100px;
    }
}

.widget-input-group {
    display: flex;
    flex-direction: row;
    /*justify-content: right;*/
    align-items: center;
    padding-top: 5px;

    label {
        padding-right: 10px;
        min-width: 135px;
    }

    input, select {
        width: 145px;
    }

    img {
        width: 20px;
        cursor: pointer;
    }

    .label-center {
        margin-left: auto;
        margin-right: auto;
    }

    .button-right {
        margin-left: auto;
    }

    .button-title {
        clear: left;
        display: block;
    }
}

.map-measure-details-group {
    display: flex;
    flex-direction: row;
    /*justify-content: right;*/
    align-items: center;
    padding-top: 5px;

    label {
        padding-right: 10px;
        width: 70px;
    }

    input, select {
        width: 120px;
    }
}

.opacity-slider {
    -webkit-appearance: none;
    width: 100px;
    height: 5px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.hidden {
    display: none;
}

/*.esri-icon {
    font-size: 300px;
}*/

.action-button {
    font-size: 14px;
    background-color: transparent;
    border: 0px; //1px solid #d3d3d3;
    color: #6e6e6e;
    height: 22px;
    width: 22px;
    text-align: center;
    /*box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);*/
}


.action-button-left-menu {
    font-size: 16px;
    border: 0px; //1px solid #d3d3d3;
    color: #6e6e6e;
    height: 26px;
    width: 26px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .ribbon-static {
        display: none !important;
    }
}

.page {
    border: 1px solid #a5acb5;
    margin: 20px;
    padding: 15px;
    -webkit-box-shadow: #000000 0px 0px 10px;
    -moz-box-shadow: #000000 0px 0px 10px;
    box-shadow: #000000 0px 0px 10px;
    background: #FFF;
}



#layer-option-tabs {
    font-family: "Segoe UI", Verdana, Tahoma, Helvetica, sans-serif;
    cursor: default;
    font-size: 8pt;
    padding-top: 0px;
    background-color: rgba(250,247,243, 0.9); // rgba(234,237,241, 0.9); // #eaedf1;
    border-bottom: 1px solid #8b9097;
    /*    -webkit-box-shadow: #8b9097 0px 1px 3px;
    -moz-box-shadow: #8b9097 0px 1px 3px;*/
    /*box-shadow: #8b9097 0px 1px 3px;*/
    /*overflow: scroll;*/
    z-index: 25;
}

#layer-option-tabs.backstage {
    height: auto;
    bottom: 0;
}


#layer-option-tabs #layer-option-tab-header-strip {
    margin: 4px 0 0 1px;
    white-space: nowrap;
}

#layer-option-tabs .layer-option-tab-header {
    display: inline;
    margin: 0 5px;
    padding: 5px 5px;
    font-size: 7pt;
    color: #444; /*border: 1px solid #eaedf1;*/
    /*    border-bottom: 1px solid #b6babf;*/
    -webkit-border-radius: 4px 4px 0px 0px;
    -moz-border-radius: 4px 4px 0px 0px;
    border-radius: 4px 4px 0px 0px;
    position: relative;
    z-index: 40;
}

#layer-option-tabs .layer-option-tab-header:hover {
    background: #f1f3f4;
    border: 1px solid #b6babf;
    margin: -1px 4px;
    cursor: pointer;
}

#layer-option-tabs .layer-option-tab-header.sel, #layer-option-tabs .layer-option-tab-header.sel:hover {
    background: #fff;
    margin: -1px 4px;
    border: 1px solid #b6babf;
    border-bottom: 1px solid #fff;
    cursor: default;
    z-index: 45;
}


#layer-option-tabs .layer-option-tab {
    /*height: 80px;*/
    padding: 1px;
    margin-top: 5px;
    border-top: 1px solid #b6babf;
    background: #FFF url('rib-back.png') repeat-x bottom;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#ffffff), to(#eaedf1));
    background: -moz-linear-gradient(#ffffff, #eaedf1); /*-pie-background: linear-gradient(#ffffff, #eaedf1);*/
    overflow-y: auto;
    min-width: 250px;
    /*min-width: 100%;*/
    behavior: url(/PIE.htc);
    z-index: 30;
    -pie-watch-ancestors: 2;
}





#ribbon {
    font-family: "Segoe UI", Verdana, Tahoma, Helvetica, sans-serif;
    cursor: default;
    /*font-size: 8pt;*/
    padding-top: 0px;
    background-color: rgba(250,247,243, 0.9); // rgba(234,237,241, 0.9); // #eaedf1;
    border-bottom: 1px solid #8b9097;
    -webkit-box-shadow: #8b9097 0px 1px 3px;
    -moz-box-shadow: #8b9097 0px 1px 3px;
    box-shadow: #8b9097 0px 1px 3px;
    overflow: hidden;
    z-index: 25;
}

#ribbon.backstage {
    height: auto;
    bottom: 0;
}

#ribbon .ribbon-window-title {
}


#ribbon.backstage .ribbon-tab-header {
    border-bottom: 1px solid #1f48a1;
}

#ribbon .ribbon-tab-header:hover {
    background: #f1f3f4;
    border: 1px solid #b6babf;
    margin: -1px 4px;
    cursor: pointer;
}

#ribbon.backstage .ribbon-tab-header {
    border-bottom: 1px solid #1f48a1;
}


#ribbon .ribbon-tab-header-control {
    display: inline;
    margin: 0 5px;
    /*padding: 5px 10px;*/
    /*font-size: 8pt;*/
    color: #444;
    /*border-bottom: 1px solid #b6babf;*/
    -webkit-border-radius: 4px 4px 0px 0px;
    -moz-border-radius: 4px 4px 0px 0px;
    border-radius: 4px 4px 0px 0px;
    /*position: relative;*/
    z-index: 40;
    float: right;
    margin: 0 5px;
}

#ribbon .ribbon-tab-header-control:hover {
    background: #f1f3f4;
    border: 1px solid #b6babf;
    margin: -1px 4px;
    cursor: pointer;
}

#ribbon .ribbon-tab {
    /*height: 80px;*/
    padding: 1px;
    /*margin-top: 5px;*/
    /*border-top: 1px solid #b6babf;*/
    background: #FFF url('rib-back.png') repeat-x bottom;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#ffffff), to(#eaedf1));
    background: -moz-linear-gradient(#ffffff, #eaedf1); /*-pie-background: linear-gradient(#ffffff, #eaedf1);*/
    overflow: hidden;
    /*min-width: 250px;*/
    /*min-width: 100%;*/
    behavior: url(/PIE.htc);
    z-index: 30;
    -pie-watch-ancestors: 2;
}

/*#ribbon .ribbon-tab.file {
    padding: 0;
    margin-top: 5px;
    border-top: 1px solid #1f48a1;
    background-color: #4488e5;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,startColorstr=#4488e5, endColorstr=#ffffff);
    background-image: -moz-linear-gradient(left, #4488e5 15%, #ffffff 100%);
    background-image: -webkit-linear-gradient(left, #4488e5 15%, #ffffff 100%);
    background-image: -ms-linear-gradient(left, #4488e5 15%, #ffffff 100%);
    background-image: linear-gradient(left, #4488e5 15%, #ffffff 100%);
    background-image: -o-linear-gradient(left, #4488e5 15%, #ffffff 100%);
    background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(15%,#4488e5), color-stop(100%,#ffffff));
    overflow: hidden;
    min-width: 250px;*/
/*min-width: 100%;*/
/*z-index: 30;
}*/

#ribbon .ribbon-backstage {
    display: none;
}


#ribbon.backstage .ribbon-backstage {
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background-color: #FFF;
}

#ribbon .section-title {
    color: #5B626C;
    text-align: center;
    clear: left;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

#ribbon .ribbon-section {
    float: left;
    position: relative;
    /*height: 82px;*/
}

#ribbon .ribbon-section-sep {
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #fff;
    margin: 0 5px;
    height: 45px;
    top: 0;
    bottom: 0;
    position: relative;
    width: 0;
    float: left;
}

#ribbon .ribbon-button-small-group {
    float: right;
}




#ribbon .ribbon-button {
    padding: 4px 6px;
    border: 1px solid transparent;
    -webkit-border-radius: 3px 3px 3px 3px;
    -moz-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}

#ribbon .ribbon-button-large {
    text-align: center;
    /*height: 58px;*/
    min-width: 32px;
    float: left;
}

/*#ribbon .ribbon-button-small {*/
/*height: 14px;
    padding: 3px 4px;*/
/*text-align: left;
}

#ribbon .ribbon-button .button-help {
    display: none;
}*/

#ribbon .ribbon-button .button-title {
    color: #444;
    font-size: 7pt;
}

#ribbon .ribbon-button-large .button-title {
    clear: left;
    display: block;
}

#ribbon .ribbon-button:hover {
    border: 1px solid #F1C43F;
    background: #fdeeb3;
    cursor: pointer;
}

#ribbon .active {
    border: 1px solid #F1C43F;
    background: #F1C43F;
    cursor: pointer;
}

#ribbon .disabled {
    background: rgb(232,230,224);
    opacity: 0.5;
}

.ribbon-tooltip {
    font-family: "Segoe UI", Verdana, Tahoma, Helvetica, sans-serif;
    font-size: 10pt;
    position: fixed;
    z-index: 5000;
    border: 1px solid #8b9097;
    background-color: #e5e5f0;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#ffffff), to(#e5e5f0));
    background: -moz-linear-gradient(#ffffff, #e5e5f0);
    -pie-background: linear-gradient(#ffffff, #e5e5f0);
    padding: 5px;
    -webkit-box-shadow: #c6c9cd 1px 1px 3px;
    -moz-box-shadow: #c6c9cd 1px 1px 3px;
    box-shadow: #c6c9cd 1px 1px 3px;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    width: 200px;
    top: 150px !important;
}

#resultsGrid {
    display: none;
    position: absolute;
    left: 0; //@toc-pane-width;
    right: 0; //@results-pane-width;
    bottom: 0;
    background-color: rgb(255,255,255); //for IE8
    background-color: rgba(255,255,255,0.9);
    height: @results-grid-height;
    overflow: auto;
    border-top: 1px solid #aaa;
    padding: 6px;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    z-index: 1;
    .DontPrint();
    /*    #resultsGridContent {
        position: absolute;
        display:flex;
    }*/
}
#toolTip {
    display: none;
    position: absolute;
    left: 0; //@toc-pane-width;
    right: 0; //@results-pane-width;
    bottom: @results-grid-height;
    background-color: rgb(255,255,255); //for IE8
    background-color: rgba(255,255,255,0.9);
    height: @results-tooltip-mobile-height;
    overflow: auto;
    border-top: 1px solid #aaa;
    padding-right: 5px;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    z-index: 1;
    .DontPrint();
    /*    #resultsGridContent {
        position: absolute;
        display:flex;
    }*/
}

#mapSearch {
    display: none;
    position: absolute;
    top: @header-height-normal;
    bottom: 0;
    right: 0;
    width: @map-search-width;
    background-color: rgb(255,255,255); //for IE8
    background-color: rgba(255,255,255,0.9);
    overflow-x: auto;
    border-left: 1px solid #aaa;
    padding: 4px;
    z-index: 1;
}


#mapJsDetailDiv {
    display: none;
    position: absolute;
    left: @toc-pane-width;
    right: @results-pane-width;
    bottom: 0;
    background-color: rgb(255,255,255); //for IE8
    background-color: rgba(255,255,255,0.9);
    max-height: @details-max-height;
    overflow: auto;
    border-top: 1px solid #aaa;
    padding: 6px;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    z-index: 1;
    .DontPrint();


    #btnCloseDetailsPane {
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 12px;
        color: #aaa;
        cursor: pointer;
    }
}


#ResultsPaneDiv {
    position: absolute;
    top: @header-height-normal;
    bottom: 0;
    right: 0px;
    width: @results-pane-width;
    background-color: rgb(255,255,255); //for IE8
    background-color: rgba(255,255,255,0.9);
    overflow-x: auto;
    border-left: 1px solid #aaa;
    padding: 4px;
    z-index: 1;
    .DontPrint();
}


// the IE9 transform filter is in mapjs.ascx - uses the  9 ie hack
.vert-text-east-base() {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    //filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -moz-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
}

.vert-text-west-base() {
    //filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -moz-transform-origin: 100% 0%;
    -ms-transform-origin: 100% 0%;
    -o-transform-origin: 100% 0%;
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
}

.map-pane-default-tab() {
    position: absolute;
    border: 1px solid #999;
    border-top: none;
    background-color: #ccc;
    color: #333;
    padding: 2px 6px 6px 6px;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    display: none;
}

#map-pane-tab-results {
    .vert-text-west-base();
    .map-pane-default-tab();
    right: 0;
    top: 333px;
    .DontPrint();

    .no-results-available {
        display: none;
    }
    /* This is an IE9 hack to add text rotation to the side tabs */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1) e("\9");
}

.map-wrap.hide-map-pane-results {


    #olMap {
        right: 0;
    }

    #ResultsPaneDiv {
        display: none;
    }

    #map-pane-tab-results {
        display: block;

        &.no-results-available {
            display: none;
        }
    }

    #mapJsDetailDiv {
        right: 0;
    }
}

.map-wrap.show-map-grid-results {

    #agsMap {
        bottom: (@results-grid-height);
    }

    .toc-wrapper {
        bottom: (@results-grid-height);
    }

    #mapSearch {
        bottom: (@results-grid-height);
    }

    #toolTip {
        display: none;
    }

    #resultsGrid {
        display: flex;
    }

    #togglePane.maximized-pane {
        transform: rotate(270deg);
    }
}
.map-wrap.show-map-grid-results.show-tooltip {

    #agsMap {
        bottom: (@results-grid-height + @results-tooltip-mobile-height);
    }

    .toc-wrapper {
        bottom: (@results-grid-height + @results-tooltip-mobile-height);
    }

    #mapSearch {
        bottom: (@results-grid-height + @results-tooltip-mobile-height);
    }

    #toolTip {
        display: flex;
    }

    #resultsGrid {
        display: flex;
    }

    #togglePane.maximized-pane {
        transform: rotate(270deg);
    }
}

.map-wrap.show-map-grid-results.show-map-grid-results-collapsed {

    #agsMap {
        bottom: 50px;
    }

    .toc-wrapper {
        bottom: 50px;
    }

    #mapSearch {
        bottom: 50px;
    }

    #toolTip {
        bottom: @results-grid-collapsed-height;
        overflow: hidden;
    }

    #resultsGrid {
        height: @results-grid-collapsed-height;
        overflow: hidden;
    }


    #togglePane.minimized-pane {
        transform: rotate(90deg);
    }
}








.map-wrap.show-map-search {

    #agsMap {
        right: @map-search-width;
    }
}

.map-wrap.hide-map-pane-layers {


    #olMap {
        left: 0;
    }

    #agsMap {
        left: 0;
    }

    .toc-wrapper {
        display: none;
    }

    #map-pane-tab-layers {
        display: block;
    }

    #mapJsDetailDiv {
        left: 0;
    }
}


@media (max-width: @medium-layout-width) {

    #olMap {
        top: @header-height-small;
        //left:0;
    }

    #agsMap {
        top: @header-height-small;
        //left:0;
    }

    .toc-wrapper {
        top: @header-height-small;
        //display:none;
    }

    #ResultsPaneDiv {
        top: @header-height-small;
        //display:none;
    }

    #mapJsDetailDiv {
        max-height: 100px;
    }
}



#ovmap_img {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2000;
    font-size: 20px;
    line-height: 20px;
    width: 20px;
    opacity: 0.8;
    text-shadow: 0 0 4px white;
    margin: 2px;

    &.ov-closed {
        .xfrm(135deg);
        .anim(ov-closed, 0.3s,ease-in-out);
    }

    &.ov-opened {
        .xfrm(-45deg);
        .anim(ov-opened, 0.3s,ease-in-out);
    }
}

.create-transform(ov-closed, -45deg, 135deg);
.create-transform(ov-opened, 135deg, 315deg);

#overviewJsMapPane {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1990;
    width: 160px;
    height: 160px;
    border-left: 1px solid #777;
    border-bottom: 1px solid #777;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    padding: 6px;
    background-color: white;
    box-shadow: rgba(0,0,0,0.5) 0px 0px 10px;
}


#identifyOptionPanel {
    position: absolute;
    z-index: 1022;
    .white-background-50();
    padding: 2px;
    left: 60px;
    top: 42px;

    div {
        background-color: #eee;
        padding: 3px 6px 0px 6px;
    }
}

#identifyOptionPanelSpacer {
    position: absolute;
    z-index: 1022;
    .white-background-50();
    width: 30px;
    height: 4px;
    left: 60px;
    top: 38px;
}


.map-print-warning {
    font-weight: bold;
    font-size: 24px;
    color: @error-red;

    @media screen {
        display: none;
    }

    @media print {
        display: block;
    }
}

.map-tool-image {
    width: 13px;
}


.form-control-sm {
    /*    height: calc(1em + 1rem + 1px) !important;
    padding: .250rem .50rem !important;*/
    font-size: 1rem !important;
    /*    line-height: 1.5;
    border-radius: .2rem;*/

    width: 85px
}

.vertical-center {
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
