﻿// common macro-like functions here:
@import "Variables.less";

.fn-link-color(@color)
{
    a
    {
        color: @color;

        &:hover, &:link, &:active, &:visited
        {
            color: @color;
            text-decoration: none;
        }

        &:hover
        {
            text-decoration: underline;
        }
    }
}


// Border Radius
.border-radius(@radius)
{
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}


.overRideBootrapCaret() {
    .caret {
        align-items: center;
        border: 0px solid transparent;
        display: inline-flex !important;
        padding: 0em;
        width: auto;
    }

    .caret::after {
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        content: "";
        border-bottom: 2px solid #333;
        border-right: 2px solid #333;
        height: 0.5em;
        margin-left: 0.75em;
        width: 0.5em;
        transform: rotate(45deg);
        margin-bottom: 3px;
    }

    .beacon-navbar-nav {
        .caret::after {
            border-bottom-color: @navbar-inverse-link-color;
            border-right-color: @navbar-inverse-link-color;
        }
    }
}
// this was stolen from bootstrap
.clearfix()
{
    *zoom: 1;

    &:before,
    &:after
    {
        display: table;
        content: "";
        // Fixes Opera/contenteditable bug:
        // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
        line-height: 0;
    }

    &:after
    {
        clear: both;
    }
}


// transparancy support -- with ie 6/7/8 failback (the 9 hack)

.white-background-25()
{
    background-color: rgba(255,255,255,0.25);
    background-image: url(/Images/pixel_white25.png) e("\9");
    background-repeat: repeat e("\9");
}

.white-background-50()
{
    background-color: rgba(255,255,255,0.5);
    background-image: url(/Images/pixel_white50.png) e("\9");
    background-repeat: repeat e("\9");
}

.white-background-75()
{
    background-color: rgba(255,255,255,0.75);
    background-image: url(/Images/pixel_white75.png) e("\9");
    background-repeat: repeat e("\9");
}
 

// print support
.DontPrint() 
{
    @media print { display:none; }
}