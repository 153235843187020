.caret {
  align-items: center;
  border: 0px solid transparent;
  display: inline-flex !important;
  padding: 0em;
  width: auto;
}
.caret::after {
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  content: "";
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
  height: 0.5em;
  margin-left: 0.75em;
  width: 0.5em;
  transform: rotate(45deg);
  margin-bottom: 3px;
}
.beacon-navbar-nav .caret::after {
  border-bottom-color: #ddd;
  border-right-color: #ddd;
}
body,
input,
button,
select,
textarea {
  font-family: lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
/*b, strong {
    font-family: @font-family-sans-serif-bold;
}*/
@media print {
  a[href]:after {
    content: none;
  }
}
.pointer {
  cursor: pointer;
}
LEGEND {
  font-weight: bold;
  border-bottom: none;
  font-size: 11px;
  margin-bottom: 5px;
  color: #000;
}
.error {
  color: #ED0000;
}
.slim-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.slim-scrollbar::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  background-color: rgba(0, 0, 0, 0.3);
}
.slim-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/
  background-color: rgba(0, 0, 0, 0.5);
}
.dropdown-header {
  color: #333333;
}
font[color=red] {
  color: #ED0000;
}
span[style*='color:Red'],
span[style*='color:red'] {
  color: #ED0000 !important;
}
a[href] {
  text-decoration: underline;
}
a[href].toggle-collapse,
a[href].btn {
  text-decoration: none;
}
.dropdown-menu a[href] {
  text-decoration: none;
}
