.clearfix,
.beacon-navbar {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after,
.beacon-navbar:before,
.beacon-navbar:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after,
.beacon-navbar:after {
  clear: both;
}
.clearfix:before,
.clearfix:after,
.beacon-navbar:before,
.beacon-navbar:after {
  display: table;
  content: " ";
}
.clearfix:after,
.beacon-navbar:after {
  clear: both;
}
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
#tabAffix .affix {
  top: 0;
  right: 0;
  left: 0;
  z-index: 2000;
}
@media (max-width: 700px) {
  #tabAffix {
    height: auto !important;
  }
  #tabAffix .affix {
    position: relative;
  }
}
#tabRow {
  height: 50px;
}
#tabRow.map-page-row {
  padding: 0 16px;
}
.beacon-navbar {
  position: relative;
  height: 52px;
  border: 1px solid transparent;
  border-radius: 0;
  margin-bottom: 0;
}
@media print {
  .beacon-navbar {
    display: none;
  }
}
.beacon-navbar-nav {
  font-weight: bold;
  float: left;
  margin: 0;
}
.beacon-navbar-nav > li,
.beacon-navbar-nav > div {
  float: left;
}
.beacon-navbar-nav > li > a,
.beacon-navbar-nav > div > a {
  padding: 13.5px 10px;
  margin: 4px 2px;
  border-radius: 4px;
  line-height: 15px;
}
.beacon-navbar-left {
  float: left !important;
}
.beacon-navbar-right {
  float: right !important;
}
.beacon-navbar-nav > li > .dropdown-menu,
.beacon-navbar-nav > div > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.beacon-navbar-nav > li > .dropdown-menu .active-layer-indicator,
.beacon-navbar-nav > div > .dropdown-menu .active-layer-indicator {
  margin-left: -14px;
  width: 11px;
}
.beacon-navbar-fixed-bottom .beacon-navbar-nav > li > .dropdown-menu {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.beacon-navbar-inverse {
  background-color: #7c1d21;
  border-color: #531316;
  background-image: -webkit-linear-gradient(top, #7c1d21 0%, #531316 100%);
  background-image: -o-linear-gradient(top, #7c1d21 0%, #531316 100%);
  background-image: linear-gradient(to bottom, #7c1d21 0%, #531316 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7c1d21', endColorstr='#ff531316', GradientType=0);
  background-repeat: repeat-x;
}
.beacon-navbar-inverse .beacon-navbar-nav > li > a,
.beacon-navbar-inverse .beacon-navbar-nav > div > a {
  color: #ddd;
  text-decoration: none;
}
.beacon-navbar-inverse .beacon-navbar-nav > li > a:hover,
.beacon-navbar-inverse .beacon-navbar-nav > div > a:hover,
.beacon-navbar-inverse .beacon-navbar-nav > li > a:focus,
.beacon-navbar-inverse .beacon-navbar-nav > div > a:focus {
  color: #fff;
  background-color: #a5272c;
}
.beacon-navbar-inverse .beacon-navbar-nav > li > a:focus,
.beacon-navbar-inverse .beacon-navbar-nav > div > a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.beacon-navbar-inverse .beacon-navbar-nav > .active > a,
.beacon-navbar-inverse .beacon-navbar-nav > .active > a:hover,
.beacon-navbar-inverse .beacon-navbar-nav > .active > a:focus {
  color: #000;
  background-color: #fff;
}
.beacon-navbar-inverse .beacon-navbar-nav > .disabled > a,
.beacon-navbar-inverse .beacon-navbar-nav > .disabled > a:hover,
.beacon-navbar-inverse .beacon-navbar-nav > .disabled > a:focus {
  color: #888;
  background-color: transparent;
}
.beacon-navbar-inverse .beacon-navbar-nav > .open > a,
.beacon-navbar-inverse .beacon-navbar-nav > .open > a:hover,
.beacon-navbar-inverse .beacon-navbar-nav > .open > a:focus {
  background-color: #fff;
  color: #000;
}
#liVersionSwitcher a {
  height: 42px;
}
#liVersionSwitcher img {
  width: 60px;
}
#tabRow .tab-overflow,
#tabRow .tab-underflow {
  display: none;
}
