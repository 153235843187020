﻿@import "Variables.less";
@import "Utilities.less";

// adpated from bootstrap's responsive-utilities.less

// .zzzdisabled {
//     // IE10 Metro responsive
//     // Required for Windows 8 Metro split-screen snapping with IE10
//     // Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
//     @-ms-viewport {
//         width: device-width;
//     }
//     // Hide from screenreaders and browsers
//     // Credit: HTML5 Boilerplate
//     .hidden {
//         display: none;
//         visibility: hidden;
//     }
// }



// Visibility utilities

// For desktops
.visible-small {
    display: none !important;
}

.visible-medium {
    display: none !important;
}

.hidden-small {
}

.hidden-medium {
}

.hidden-desktop {
    display: none !important;
}

.visible-desktop {
    display: inherit !important;
}

// tablets & small desktops only
@media only screen and (min-width: @small-layout-width-plus-one) and (max-width: @medium-layout-width) {
    // Hide everything else
    .hidden-desktop {
        display: inherit !important;
    }

    .visible-desktop {
        display: none !important;
    }
    // Show
    .visible-medium {
        display: inherit !important;
    }
    // Hide
    .hidden-medium {
        display: none !important;
    }
}

// smalls only
@media only screen and (max-width: @small-layout-width) {
    // Hide everything else
    .hidden-desktop {
        display: inherit !important;
    }

    .visible-desktop {
        display: none !important;
    }
    // Show
    .visible-small {
        display: inherit !important;
    }
    // Use inherit to restore previous behavior
    // Hide
    .hidden-small {
        display: none !important;
    }
}


// .zzzdisabled {
//     // bootstrap Modal panel - from responsive-767px-min.less
//     @media only screen and (max-width: @medium-layout-width) {
//         // Modals
//         .modal {
//             position: fixed;
//             top: 20px;
//             left: 20px;
//             right: 20px;
//             width: auto;
//             margin: 0;
// 
//             &.fade {
//                 top: -100px;
//             }
// 
//                 &.fade.in {
//                     top: 20px;
//                 }
//         }
//     }
// 
//     @media only screen and (max-width: @small-layout-width) {
// 
//         // Modals
//         .modal {
//             top: 10px;
//             left: 10px;
//             right: 10px;
//         }
// 
//         .modal-header .close {
//             padding: 10px;
//             margin: -10px;
//         }
//     }
// }

// magic css to assist in JS detectability
//  access this with:
//  var size = window.getComputedStyle(document.body,':after').getPropertyValue('content'); -- maybe check for extra quotes?

// body:after {
//     content: 'desktop';
//     display: none;
// 
//     @media only screen and (max-width: @small-layout-width) {
//         content: 'small';
//     }
// 
//     @media only screen and (min-width: @small-layout-width-plus-one) and (max-width: @medium-layout-// width) {
//         content: 'medium';
//     }
// }








// .zzzdisabled {
//     // Print utilities
//     .visible-print {
//         display: none !important;
//     }
// 
//     .hidden-print {
//     }
// 
// 
//     @media print {
//         .visible-print {
//             display: inherit !important;
//         }
// 
//         .hidden-print {
//             display: none !important;
//         }
//     }
// }

//debug mode helpers

.small-cuttoff-guideline {
    border-right: 1px solid red;
    width: 1px;
    height: 100%;
    position: fixed;
    top: 0;
    left: @small-layout-width;
}

.medium-cuttoff-guideline {
    border-right: 1px solid blue;
    width: 1px;
    height: 100%;
    position: fixed;
    top: 0;
    left: @medium-layout-width;
}

.device-guideline {
    border-right: 1px solid #ddd;
    width: 2px;
    height: 100%;
    position: fixed;
    top: 0;
    //left: @medium-layout-width;
}

@media (min-width: @medium-layout-width) {
    .right-align-subject-column(@column) when (@column >= 0) {
        .right-align-subject-column((@column - 1));
        @x: @column + 1;

        .right-align-subject-column-@{column} {
            text-align: right;
        }
    }
    .right-align-subject-column(5);
}

/* For the GEN1 Web Link */
.equal-width-stack {
    display: inline-block; /* use inline-block so that it doesn't expand to entire container */

    > .equal-width-item {
        display: block;
        margin: 0.5rem;
    }
    > .equal-width-container {
        display: flex;
        align-items: center;
    }
    > .equal-width-item, .equal-width-container {
        > .equal-width-button {
            width: 100%;
        }
    }

    &.equal-width-row > .equal-width-container {
        /* when horizontal display is desired, use inline-flex so that they will stack inline */
        display: inline-flex;
    }
    &.equal-width-row > .equal-width-item {
        display: inline-block;
    }
}

/* For UPM1 Comp Search */
@media (max-width: @medium-layout-width) {
    /* For tables that aren't really tables. Just chop them up so they fit on the screen.*/
    .responsive-table-decorative {
        select {
            max-width: 80vw;
            text-overflow: ellipsis;
        }
        /* Break the table apart */
        thead,
        tbody,
        th,
        tr,
        td {
            display: block;
        }
    }

    .responsive-table {
        /* deal with overflowing select elements */
        select {
            max-width: 80vw;
            text-overflow: ellipsis;
        }

        /* Break the table apart */
        thead,
        tbody,
        th,
        tr,
        td {
            display: block;
            background-color: white;
        }

        /* Make the head row invisible for visual users */
        /* (Non-visual users probably don't need visual responsive design.) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        /* Border around each criterion */
        tr {
            border: 0.7px solid #e3e3e3;
        }

        /* borders not needed within criteria */
        tbody > tr > td,
        tbody > tr > th {
            border: none;
        }
    }

    /* styling for "Use" columns in comparison/advanced search modules */
    .column-checkbox(@column) when (@column >= 0) {
        .column-checkbox((@column - 1));
        @x: @column + 1;

        .column-@{column}-checkbox {
            tr > :nth-child(@{x}) {
                /* put on same row as label column */
                display: inline-block;
                /* float to the right */
                float: right;
                /* correct spacing */
                padding: 0px;
                margin-top: 7px; /* This needs to be less than 8, otherwise they behave _strangely_...*/
                margin-right: 7px;
                margin-left: 7px;
                /* no margin-bottom, otherwise they will exhibit strange stacking behavior */

                &::after {
                    content: "Use";
                }

                //remove the 'use' label if there isn't actually a checkbox in that row
                //(sometimes there are empty rows for dubious reasons)
                &.no-checkbox::after {
                    content: none;
                }
            }
        }
    }
    .column-checkbox(5);

    /* styling for the "Category" columns in advanced/comparison search modules */
    .column-field(@column) when (@column >= 0) {
        .column-field((@column - 1));
        @x: @column + 1;

        .column-@{column}-field {
            tr > :nth-child(@{x}) {
                /* put on same row as checkbox column, if it exists */
                display: inline-block;
            }
        }
    }
    .column-field(5);

    /* styling for the input elements in advanced/comparison search modules */
    .column-inputs(@column) when (@column >= 0) {
        .column-inputs((@column - 1));
        @x: @column + 1;

        .column-@{column}-inputs {
            tr {
                > :nth-child(@{x}) {
                    /* use color to indicate that this is the content, and not the subject, of a row */
                    background-color: #f5f5f5;
                }
                &.hidden-criterion > :nth-child(@{x}) {
                    /* hide this content if it is supposed to be hidden */
                    display: none;
                }
            }
        }
    }
    .column-inputs(5);

    /* styling for the "Criteria for subject" content in comparison search modules */
    .column-subject(@column) when (@column >= 0) {
        .column-subject((@column - 1));
        @x: @column + 1;

        .column-@{column}-subject {
            tr {
                > :nth-child(@{x}) {
                    /* use color to indicate that this is the content, and not the subject, of a row */
                    background-color: #f5f5f5;
                    &::before {
                        content: "Criteria for subject: ";
                    }
                }
                &.hidden-criterion > :nth-child(@{x}) {
                    /* hide this content if it is supposed to be hidden */
                    display: none;
                }
            }
        }
    }
    .column-subject(5)
}