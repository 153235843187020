﻿
.default-section() {
    border: 1px solid #ccc;
    margin: 6px;
    color: #888;
    min-height: 20px;
    position: relative;
    padding-top: 16px;
}

#fbPrintSetDefault {
    color: green;
    margin-left: 10px;
    font-size: 20px;
}

.print-page-preview {
    border: 2px solid #ccc;
    width: 184px;
    height: 251px;
    background-color: #eee;
    position: relative;

    .page-section-label {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 1px 3px;
        font-size: 7px;
        font-weight: bold;
        color: #555;
        background-color: #ccc;
    }

    .header {
        .default-section();


        .author {
            font-size: 7px;
        }

        .title {
            font-size: 9px;
        }


        .subtitle {
            font-size: 7px;
        }
    }

    .report {
        .default-section();
        text-align: center;
        height: 150px;

        span {
            font-size: 100px;
        }
    }

    .footer {
        .default-section();
        position: absolute;
        bottom: 0;
        left: 2px;
        right: 2px;
    }
}
