﻿//@version: "1639804";  // change the version number every time the underlying font files are updated - or users will cry

// --- MAIN DOCUMENT FONTS ---
// *** switched back to google fonts for Lato ***
// .import-font('/fonts/lato/lato-reg-webfont', 'lato', 'latoregular', '', normal, normal);
// .import-font('/fonts/lato/lato-regita-webfont', 'lato', 'latoitalic', '', normal, italic);
// //.import-font('/fonts/lato/lato-lig-webfont', 'lato', 'latolight', '', normal, normal);
// //.import-font('/fonts/lato/lato-hai-webfont', 'lato', 'latohairline', '', normal, normal);
// //.import-font('/fonts/lato/lato-bol-webfont', 'lato', 'latobold', '', normal, normal);
// .import-font('/fonts/lato/lato-bla-webfont', 'lato', 'latoblack', '', bold, normal);
// .import-font('/fonts/lato/lato-blaita-webfont', 'lato', 'latoblack_italic', '', bold, italic);

.import-font('../../fonts/fontello/fontello-icons', 'fontello-icons', 'fontello-icons', '', normal, normal);
//.import-font-svghack('/fonts/fontello/fontello-icons', 'fontello-icons', '?@{version}');


.icon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'fontello-icons';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


// --- MIXINS ---

.import-font(@font-path-base: ''; @font-name2: ''; @font-name: ''; @version: ''; @weight; @style) {

    @font-face {
        font-family: '@{font-name2}';
        src: url('@{font-path-base}.woff@{version}') format('woff'), 
             url('@{font-path-base}.ttf@{version}') format('truetype');
        /*src: url('@{font-path-base}.eot@{version}');
        src: url('@{font-path-base}.eot@{version}#iefix') format('embedded-opentype'), 
             url('@{font-path-base}.woff@{version}') format('woff'), 
             url('@{font-path-base}.ttf@{version}') format('truetype'), 
             url('@{font-path-base}.svg@{version}#@{font-name}') format('svg');*/
        font-weight: @weight;
        font-style: @style;
    }
}

.import-font-svghack(@font-path-base: ''; @font-name: ''; @version: '') {

    // Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. 
    // Note, that will break hinting! In other OS-es font will be not as sharp as it could be 

    @media screen and (-webkit-min-device-pixel-ratio:0) {
        @font-face {
            font-family: '@{font-name}';
            src: url('@{font-path-base}.svg@{version}#@{font-name}') format('svg');
        }
    }
}


// support for the web-font loader - hides the icon fonts until they are loaded.  see StandardCssHeaders.ascx for the loader

/*html.wf-fontelloicons-n4-active .icon, 
html.wf-glyphiconshalflings-n4-active .glyphicon {
    color:@error-red;
}*/

html.wf-fontelloicons-n4-inactive .icon, 
html.wf-glyphiconshalflings-n4-inactive .glyphicon {
    visibility: hidden;
}

html.wf-fontelloicons-n4-loading .icon, 
html.wf-glyphiconshalflings-n4-loading .glyphicon {
    visibility: hidden;
}

