﻿@import "Variables.less";
@import "Utilities.less";



.resultitem
{
    margin: 0px 0px 4px 0px; /*outline: #777 solid 1px;*/
    border: 1px solid #BBB;
}

.resultitemhover
{
    background-color: #dddddd;
    border-color: #ff8888;
}

.resultitemkey
{
    border-color: #00ffff;
    border-width: 2px;
}

.resultitemhover.resultitemkey
{
    border-color: #ff8888;
}



#resultsWarningPane 
{
    margin: 0px 0px 4px 0px; 
    border: 1px solid #BBB;
    background-color: White;
    padding:4px;
}

.results-header {
        font-weight: bold;
        font-size: 12px;
        margin-top: 0px;
        margin-bottom: 16px;
        border-bottom: 1px solid rgb(211, 211, 211);

        #btnCloseResultsPane {
            cursor: pointer;
        }
    }