.beacon-header-container {
  background-color: #ffffff;
}
.beacon-header-container .map-page-row {
  padding: 0 0px;
}
.beacon-header-large {
  height: 56px;
}
.beacon-header-large .leftside .visible-large {
  display: none;
}
@media only screen and (min-width: 701px) and (max-width: 991.98px) {
  .beacon-header-large .leftside .visible-large {
    display: inherit;
  }
}
.beacon-header-large .leftside .visible-extra-large {
  display: none;
}
@media only screen and (min-width: 992px) {
  .beacon-header-large .leftside .visible-extra-large {
    display: inherit;
  }
}
.beacon-header-large .leftside img {
  float: left;
  height: 40px;
  margin-top: 6px;
}
.beacon-header-large .leftside .header-title {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 24px;
  line-height: 56px;
  padding: 0 10px;
  margin: 0;
  font-weight: normal;
}
@media only screen and (min-width: 701px) and (max-width: 991.98px) {
  .beacon-header-large .leftside .header-title {
    font-size: 18px;
    max-width: 385px;
  }
}
.beacon-header-large .rightside {
  float: right;
}
.beacon-header-large .rightside .search {
  float: right;
  width: 160px;
  margin-top: 12px;
  position: sticky;
}
.beacon-header-large .rightside .search .form-control-feedback {
  top: 0px;
}
.beacon-header-large .rightside .logo {
  float: left;
  padding-right: 10px;
  padding-top: 7px;
}
.beacon-header-large .rightside .myacct {
  float: left;
  height: 20px;
  margin-top: 20px;
  line-height: 20px;
  padding-right: 10px;
}
.beacon-header-large .rightside .myacct a,
.beacon-header-large .rightside .myacct a:visited,
.beacon-header-large .rightside .myacct a:active {
  text-decoration: none;
  color: #444;
}
.beacon-header-large .rightside .myacct a:hover {
  text-decoration: none;
  color: #222;
}
.beacon-header-large .rightside .myacct a.dropdown-toggle {
  padding: 4px;
}
.beacon-header-large .rightside .myacct a.dropdown-toggle:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.beacon-header-large .rightside .myacct .icon-user {
  padding-right: 4px;
  color: #7c1d21;
}
.beacon-header-small {
  height: 30px;
}
.beacon-header-small .leftside {
  float: left;
}
.beacon-header-small .leftside img {
  height: 30px;
  vertical-align: top;
}
.beacon-header-small .leftside .header-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  line-height: 30px;
  padding: 0 10px;
  display: inline;
  margin: 0;
  font-weight: normal;
}
.beacon-header-small-long-title {
  height: 30px;
}
.beacon-header-small-long-title .leftside {
  float: left;
}
.beacon-header-small-long-title .leftside img {
  height: 30px;
  vertical-align: top;
}
.beacon-header-small-long-title .leftside .header-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: inherit;
  line-height: 30px;
  padding: 0 10px;
  display: inline;
  margin: 0;
  font-weight: normal;
}
