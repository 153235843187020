@media screen {
  .icon-sg-logoPrint {
    display: none;
  }
  .icon-sg-logoScreen {
    display: block;
  }
}
.footer-container {
  color: white;
  background-color: #0F172A;
  padding-top: 14px;
  font-size: 9pt;
}
.footer-container .footer-disclaimer-text {
  padding-bottom: 14px;
}
.footer-container .row {
  display: flex;
  flex-wrap: wrap;
}
.footer-container .footer-sv-update {
  padding-top: 8px;
}
.footer-container .footer-credits {
  *zoom: 1;
  font-size: 7pt;
  padding-left: 0;
  padding-right: 0;
}
.footer-container .footer-credits:before,
.footer-container .footer-credits:after {
  display: table;
  content: "";
  line-height: 0;
}
.footer-container .footer-credits:after {
  clear: both;
}
.footer-container .footer-credits a {
  color: white;
}
.footer-container .footer-credits a:hover,
.footer-container .footer-credits a:link,
.footer-container .footer-credits a:active,
.footer-container .footer-credits a:visited {
  color: white;
  text-decoration: none;
}
.footer-container .footer-credits a:hover {
  text-decoration: underline;
}
.footer-container .footer-credits .icon {
  font-size: 37px;
  margin-top: -6px;
  margin-right: 6px;
}
.footer-container .footer-credits div {
  float: left;
}
.footer-container .row {
  display: flex;
  justify-content: center;
  margin-left: 16px;
  margin-right: 16px;
}
.footer-container .social {
  padding: 0px 32px 0px 80px;
}
.footer-container .social a {
  color: white;
}
.footer-container .social a .icon {
  font-size: 20px;
  padding-right: 7px;
}
.footer-container a {
  color: #84B3DC;
  padding-right: 4px;
}
.footer-container a:hover,
.footer-container a:focus {
  color: #c0d8ed;
  text-decoration: underline;
}
.logo {
  width: 100px;
}
.social-icon {
  width: 20px;
}
.social-icon-a {
  padding-right: 10px;
}
