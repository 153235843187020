﻿@import "Variables.less";
@import "Utilities.less";
@import '../bootstrap-3.4.1/less/variables.less';
@import '../bootstrap-3.4.1/less/mixins.less';

table {
    border-color: @table-border-color;
}


// This formats gridviews and datagrids - when produced by the beacon framework
// -- if no CssClass is specified for those controls, AppBase will override the class to tabular-data automatically
.normal-font-label {
    font-weight: normal
}
.tabular-data {
    width: 100%;
    margin-bottom: @baseLineHeight;
    // Cells
    th,
    td {
        padding: 3px 5px;
        line-height: @baseLineHeight;
        text-align: left;
        vertical-align: top;
        border-top: 1px solid @table-border-color;
        // take cells attributed for right-just and fix
        &[align='right'] {
            text-align: right;
        }
        // take cells attributed for center-just and fix
        &[align='center'] {
            text-align: center;
        }
    }

    thead, tfoot {
        th {
            font-weight: bold;
        }
    }

    tbody {
        th {
            font-weight: normal;
        }
        tr.sdw-grid-bold {
            th {
                font-weight: bold;
            }
        }
    }
    // Bottom align for column headings
    thead th {
        vertical-align: bottom;
    }
    // Remove top border from thead by default
    caption + thead tr:first-child th,
    caption + thead tr:first-child td,
    colgroup + thead tr:first-child th,
    colgroup + thead tr:first-child td,
    thead:first-child tr:first-child th,
    thead:first-child tr:first-child td {
        border-top: 0;
    }
    // Account for multiple tbody instances
    tbody + tbody {
        border-top: 2px solid @table-border-color;
    }
    // Nesting
    .tabular-data {
        background-color: white;
    }

    tbody {
        tr:hover > td,
        tr:hover > th {
            background-color: @table-row-hover;
        }

        tr.keyvalue > td,
        tr.keyvalue > th {
            background-color: @table-row-keyvalue;
        }

        tr.keyvalue:hover > td,
        tr.keyvalue:hover > th {
            background-color: @table-row-keyvalue-hover;
        }
    }
    //15% columns (tax years and valuations)
    th.value-column,
    td.value-column {
        width: 15%;
        text-align: right;
    }
}


/*table[ShowPointerOverRows] tbody tr
{
    cursor: pointer;
}*/

tr.double-bottom-line {
    border-bottom-style: double;
    border-bottom-width: 3px;
}

tr.double-total-line,
tr.double-total-line th /* We have to be specific in order to override the general rule for <th/> */ {
    border-top-style: double;
    border-top-width: 3px;
    font-weight: bold;
}

tr.single-total-line {
    border-top-style: solid;
    border-top-width: 2px;
    font-weight: bold;
}

tr.sdw-grid-bold {
    font-weight: bold;
}

tr.sdw-grid-italic {
    font-style: italic;
}

tr.sdw-grid-gray {
    background-color: whitesmoke;
}

tr.sdw-grid-doubleline-top {
    border-top-style: double;
    border-top-width: 3px;
}

tr.sdw-grid-doubleline-bottom {
    border-bottom-style: double;
    border-bottom-width: 3px;
}

tr.sdw-grid-singleline-top {
    border-top-style: solid;
    border-top-width: 2px;
}

tr.sdw-grid-singleline-bottom {
    border-bottom-style: solid;
    border-bottom-width: 2px;
}

table.tabular-data-small {
    width: 100%;
    max-width: 400px;
}


/* for classic key/value tables - will shift 2nd col under first on small devices */
table.tabular-data-two-column {
    td,th {
        vertical-align: top;

        &:first-child {
            font-weight: bold;
            padding-right: 10px;
        }
    }

    @media only screen and (min-width: @medium-layout-width) {
        td:first-child {
            width: 170px;
        }
    }

    @media only screen and (max-width: @medium-layout-width) {
        td:first-child {
            width: 120px;
        }
    }


    @media only screen and (max-width: @small-layout-width) {

        td {
            display: block;
            text-align: left;
        }

        td:first-child {
            // border-bottom: 1px dotted #888;
        }

        td:not(:first-child) {
            // border-bottom: 1px dotted #888;
            padding-left: 10px;
        }

        td:last-child {
            margin-bottom: 5px;
        }
    }
}

table.tabular-data-two-column-no-wrap {
    td {
        vertical-align: top;

        &:first-child {
            font-weight: bold;
            padding-right: 10px;
            width: 200px;
        }

        td:first-child {
            // border-bottom: 1px dotted #888;
        }

        td:not(:first-child) {
            // border-bottom: 1px dotted #888;
            padding-left: 10px;
        }

        td:last-child {
            margin-bottom: 5px;
        }
    }
}

.important-note {
    color: #DC2A2A;
}


// ZEBRA-STRIPING
// --------------

// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.tabular-data-striped {
    tbody {
        > tr:nth-child(odd) > td,
        > tr:nth-child(odd) > th {
            background-color: @table-row-accent;
        }
    }
}


tr.table-row-accent {
    background-color: @table-row-accent;
}

.module-content .tabular-data {
    clear: both;
}

.footable-column-picker-btn {
    .DontPrint();
    float: right;
    //padding-bottom:10px;
    span.glyphicon-ok {
        margin-left: -13px;
    }

    span.glyphicon-list {
        font-size: 8px;
    }
}

.footable-chart-btn {
    .DontPrint();
    //float: right;
    border: none;
    background-color: transparent;
    padding: 0 3px;
    margin-bottom: 10px;

    .icon {
        top: 1.5px;
    }
}

// CSS adapted from FooTables
//  -- this is programmatically added to tables if the hidden-small|hidden-medium
//     is a HeaderStyle CssClass for datagrids and gridviews
//  -- requires that tabular-data also be a class on the table


.footable.breakpoint > tbody > tr {
    > td, > th {
        > .footable-toggle {
            // this tweaks the formatting of the expander icon - so that it appears just off the left margin of the table
            padding-right: 5px;
            font-size: 11px; //CHANGED from stock - jdp
            line-height: 13px; // ADDED - jdp
            vertical-align: middle; // ADDED - jdp
            margin-left: -18px; // ADDED - jdp
            text-decoration: none;
        }
    }
}


// stack TD elements in a table (make 1st col above 2nd, etc)

@media only screen and (max-width: @medium-layout-width) {
    table.stack-table-medium > tbody > tr > td,
    table.stack-table-medium > tr > td {
        display: block;
        text-align: left;
    }

    table.stack-table-medium > tbody > tr > td:first-child,
    table.stack-table-medium > tr > td:first-child {
        //  border-bottom: 1px dotted #888;
    }
}

@media only screen and (max-width: @small-layout-width) {
    table.stack-table-small > tbody > tr > td,
    table.stack-table-small > tr > td {
        display: block;
        text-align: left;
    }

    table.stack-table-small {
        .clearfix();
    }

    table.stack-table-small > tbody > tr > td:first-child,
    table.stack-table-small > tr > td:first-child {
        // border-bottom: 1px dotted #888;
    }

    table.stack-table-small > tbody > tr > td:not(:first-child),
    table.stack-table-small > tr > td:not(:first-child) {
        // border-bottom: 1px dotted #888;
        padding-left: 10px;
    }

    table.stack-table-small > tbody > tr > td:last-child,
    table.stack-table-small > tr > td:last-child {
        margin-bottom: 5px;
    }
}


@col-gutter-width: 10px;

.block-row {
    .make-row(@col-gutter-width);
    .clearfix();
}

@media only print {
    .two-column-blocks {
        .make-xs-column(6, @col-gutter-width);
    }
}

// For 2-across items -
@media only screen and (min-width: @small-layout-width-plus-one) {
    .two-column-blocks {
        .make-xs-column(6, @col-gutter-width);
    }
}

@media only screen and (max-width: @small-layout-width) {
    .two-column-blocks {
        //stack
        margin-bottom: 12px;
    }
}

// For 3-across items - such as ownership names
@media only screen, print and (min-width: @small-layout-width-plus-one) {
    .three-column-blocks {
        .make-xs-column(4, @col-gutter-width);
    }
}

@media only screen, print and (max-width: @small-layout-width) {
    .three-column-blocks {
        //stack
        margin-bottom: 12px;
    }
}

// For 4-across items -
@media only screen and (min-width: @medium-layout-width) {
    .four-column-blocks {
        .make-sm-column(3, @col-gutter-width);
    }
}

@media only screen and (max-width: @medium-layout-width) {
    .four-column-blocks {
        //stack
        margin-bottom: 12px;
    }
}



// new stuff for footable perf enhancements

.hide-loop(@colnum) when (@colnum >= 0) {
    .hide-loop((@colnum - 1)); // next iteration
    @x: @colnum + 1;

    &.ft-hide-@{colnum} {
        > tbody > tr > :nth-child(@{x}),
        > thead > tr > :nth-child(@{x}),
        > tfoot > tr > :nth-child(@{x}) {
            display: none;
        }
    }
}


// these are enhancements to make the really big results lists fast enough:

table.footable {
    // hide entire table until its ready to show:
    &.footable-loaded {
        display: table;
    }

    & {
        display: none;
    }

    .hide-loop(50); // make col 1 through 50
}


// highlighting for div-based table rows

div.row.hover-highlight {
    background-color: white;

    &:hover {
        background-color: @table-row-hover;
    }
}

.footable-hidden-column {
    display: none;
}

.compSearchTable, .salesSearchTable, .advanced-search-table {
    fieldset.inline-fieldset {
        display: inline;
    }
}