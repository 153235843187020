.editorNewRowTempate {
  display: none;
}
.editorOriginalItem {
  float: left;
  padding: 1px;
}
.editorOriginalItemHover {
  border: 1px solid #eee;
  padding: 0px;
}
.editorOriginalItemChanged {
  text-decoration: line-through;
}
.editorEditButton {
  width: 10px;
  height: 10px;
  margin-right: 2px;
  float: left;
  background-repeat: no-repeat;
  background-position: center;
  padding: 1px;
}
.editorEditButtonUpdate {
  background-image: url('/Images/DataEditor/editmode10.gif');
}
.editorEditButtonAppend {
  background-image: url('/Images/DataEditor/editmode10plus.gif');
}
.editorEditButtonHover {
  border: 1px solid #eee;
  padding: 0px;
}
.editorUpdatedContainer {
  margin-left: 20px;
  float: left;
}
.editorUpdatedItem {
  color: #ED0000;
  float: left;
}
.editorDeleteItemButton {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  float: left;
  background-image: url('/Images/DataEditor/delete8.gif');
  background-repeat: no-repeat;
  background-position: center;
  padding: 1px;
}
.editorDeleteItemButtonHover {
  border: 1px solid #eee;
  padding: 0px;
}
.editorItemSelected {
  visibility: hidden;
}
.editorPickOneSelect .editorItemSelected {
  visibility: visible;
}
.editorUnknownItem {
  border: 1px dashed Red;
}
.editorHiddenForNormalUsers {
  display: none;
}
UL.editorPickOne {
  list-style-type: none;
  margin: 0px;
  padding: 10px;
}
UL.editorPickOne > LI {
  display: block;
  float: left;
  width: 150px;
  border: 1px solid #ddd;
  margin: 4px;
  padding: 5px;
  text-align: center;
  background-color: #eee;
}
Li.editorPickOneDefault {
  font-weight: bold;
}
UL.editorPickOne > LI.editorPickOneHover {
  background-color: #888;
}
UL.editorPickOne > LI.editorPickOneSelect {
  background-color: #A7D6ED;
  border-color: #3986AB;
}
.editorSaveButton {
  display: none;
  /*position: absolute;
    top: 12px;
    left: 12px;*/
  font-family: lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: bold;
  background-color: #bbb;
  border-color: #888;
  color: #000000;
}
.editorSaveButton:hover {
  background-color: #a5272c;
  border-color: #a5272c;
  color: #ffffff;
}
.editorSaveButtonHover {
  /*border-color:#008;*/
  background-color: #ffffff;
  color: #000000;
}
.editorNumberBox {
  text-align: right;
}
.editorNumberBox::-webkit-inner-spin-button,
.editorNumberBox::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
