﻿@import "Variables.less";
@import "Utilities.less";

@media screen {
    .icon-sg-logoPrint {
        display: none;
    }

    .icon-sg-logoScreen {
        display: block;
    }
}
.footer-container {
    color: white;
    background-color: @footer-block-color;
    padding-top: 14px;
    // padding-bottom:14px;
    font-size: 9pt;
    //min-height: 60px;
    .footer-disclaimer-text {
        padding-bottom: 14px;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .footer-sv-update {
        padding-top: 8px;
    }

    .footer-credits {
        .clearfix();
        .fn-link-color(white);
        font-size: 7pt;
        //padding-bottom: 30px;
        padding-left: 0;
        padding-right: 0;

        .icon {
            font-size: 37px;
            margin-top: -6px;
            margin-right: 6px;
        }

        div {
            float: left;
        }
    }

    .row {
        display: flex;
        justify-content: center;
        margin-left: 16px;
        margin-right: 16px;
    }

    .social {
        //padding: 0 0 10px 44px;
        padding: 0px 32px 0px 80px;

        a {
            color: white;

            .icon {
                font-size: 20px;
                padding-right: 7px;
            }
        }
    }

    a {
        color: @link-color-light;
        padding-right: 4px;
        //text-decoration: none;

        &:hover,
        &:focus {
            color: @link-hover-color-light;
            text-decoration: underline;
        }
    }
}
.logo {
    width: 100px;
}

.social-icon {
    width: 20px;
}

.social-icon-a {
    padding-right: 10px;
}