﻿@import "Variables.less";
@import "Utilities.less";
@import '../bootstrap-3.4.1/less/mixins.less';
@import '../schneider-colors.less';
@import '../bootstrap-3.4.1/less/variables.less';
@import "../bootstrap-3.4.1/less/utilities";

// new tab bar


#tabAffix .affix {
    top: 0;
    right: 0;
    left: 0;
    z-index: 2000;
}

@media (max-width: @medium-layout-width) {


    #tabAffix {
        height: auto !important;

        .affix {
            position: relative;
        }
    }
}

#tabRow {
    height: 50px;

    &.map-page-row {
        padding: 0 16px;
    }
}


//.beacon-tabs-new {
//
// Navbars - from bootstrap 3.1.1 navbar.less
// --------------------------------------------------
// Wrapper and base class
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.
.beacon-navbar {
    .DontPrint();
    position: relative;
    height: 52px;
    border: 1px solid transparent;
    // Prevent floats from breaking the navbar
    &:extend(.clearfix all);
    border-radius: 0;
    margin-bottom: 0;
}



// Navbar nav links
//
// Builds on top of the `.nav` components with its own modifier class to make
// the nav the full height of the horizontal nav (above 768px).
.beacon-navbar-nav {
    font-weight: bold;
    float: left;
    margin: 0;

    > li,
    > div {
        float: left;

        > a {
            padding: 13.5px 10px;
            margin: 4px 2px;
            border-radius: 4px;
            line-height: @line-height-computed;
        }
    }
}
// Component alignment
//
// Repurpose the pull utilities as their own navbar utilities to avoid specificity 
// issues with parents and chaining. Only do this when the navbar is uncollapsed
// though so that navbar contents properly stack and align in mobile.
// @media (min-width: @grid-float-breakpoint) {
.beacon-navbar-left {
    .pull-left();
}

.beacon-navbar-right {
    .pull-right();
}

// Dropdown menus
// Menu position and menu carets
.beacon-navbar-nav > li > .dropdown-menu,
.beacon-navbar-nav > div > .dropdown-menu {
    margin-top: 0;
    .border-top-radius(0);

    .active-layer-indicator {
        margin-left: -14px;
        width: 11px;
    }
}
// Menu position and menu caret support for dropups via extra dropup class
.beacon-navbar-fixed-bottom .beacon-navbar-nav > li > .dropdown-menu {
    .border-bottom-radius(0);
}

// Alternate navbars
// --------------------------------------------------
// Inverse navbar
.beacon-navbar-inverse {
    background-color: @schneider-red;
    border-color: darken(@schneider-red,10%);
    #gradient > .vertical(@schneider-red; darken(@schneider-red,10%));

    .beacon-navbar-nav {
        > li > a,
        > div > a {
            color: @navbar-inverse-link-color;
            text-decoration: none;

            &:hover,
            &:focus {
                color: @navbar-inverse-link-hover-color;
                background-color: lighten(@schneider-red, 10%);
            }

            &:focus {
                .tab-focus()
            }
        }

        > .active > a {
            &,
            &:hover,
            &:focus {
                color: @navbar-inverse-link-active-color;
                background-color: @navbar-inverse-link-active-bg;
            }
        }

        > .disabled > a {
            &,
            &:hover,
            &:focus {
                color: @navbar-inverse-link-disabled-color;
                background-color: @navbar-inverse-link-disabled-bg;
            }
        }
    }
    // Dropdowns
    .beacon-navbar-nav {
        > .open > a {
            &,
            &:hover,
            &:focus {
                background-color: @navbar-inverse-link-active-bg;
                color: @navbar-inverse-link-active-color;
            }
        }
    }
}


//}
#liVersionSwitcher {
    a {
        height: 42px;
    }

    img {
        width: 60px;
    }
}

// tab media queries to make them responsive

//@max-tab-count: 12;
//@tab-buffer: 75px + 100px + 25px; //leave space for beta button and fudge factor
//@tab-width: 80px;

///*.tab-item2(@n) {
//    @width1: @tab-buffer + @n * @tab-width;
//
//   .tab-item(@n) when (@width1 < 750px);
//
//}*/

//.tab-item(@n) {
//    @nPlusOne: @n + 1;
//
//    li.tab-item-bar:nth-child(@{nPlusOne}) when (@n < @max-tab-count) {
//        @media only screen and (max-width: (@tab-buffer + (@n * @tab-width))) {
//            display: none;
//        }
//    }
//
//    li.tab-item-menu:nth-child(@{n}) when (@n < @max-tab-count) {
//        @media only screen and (min-width: ((@tab-buffer + (@n * @tab-width)) + 1px)) {
//            display: none;
//        }
//    }
//
//    li.tab-item-bar:nth-child(@{nPlusOne}) when (@n >= @max-tab-count) {
//        display: none;
//    }
//}

///*.tab-item(1);
//.tab-item(2);
//.tab-item(3);
//.tab-item(4);
//.tab-item(5);
//.tab-item(6);
//.tab-item(7);
//.tab-item(8);
//.tab-item(9);
//.tab-item(10);
//.tab-item(11);
//.tab-item(12);
//.tab-item(13);
//.tab-item(14);
//.tab-item(15);
//.tab-item(16);
//.tab-item(17);
//.tab-item(18);
//.tab-item(19);
//.tab-item(20);*/

#tabRow .tab-overflow,
#tabRow .tab-underflow
{
    display: none;
}