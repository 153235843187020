@font-face {
  font-family: 'fontello-icons';
  src: url('../../fonts/fontello/fontello-icons.woff') format('woff'), url('../../fonts/fontello/fontello-icons.ttf') format('truetype');
  /*src: url('@{font-path-base}.eot@{version}');
        src: url('@{font-path-base}.eot@{version}#iefix') format('embedded-opentype'), 
             url('@{font-path-base}.woff@{version}') format('woff'), 
             url('@{font-path-base}.ttf@{version}') format('truetype'), 
             url('@{font-path-base}.svg@{version}#@{font-name}') format('svg');*/
  font-weight: normal;
  font-style: normal;
}
.icon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'fontello-icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*html.wf-fontelloicons-n4-active .icon, 
html.wf-glyphiconshalflings-n4-active .glyphicon {
    color:@error-red;
}*/
html.wf-fontelloicons-n4-inactive .icon,
html.wf-glyphiconshalflings-n4-inactive .glyphicon {
  visibility: hidden;
}
html.wf-fontelloicons-n4-loading .icon,
html.wf-glyphiconshalflings-n4-loading .glyphicon {
  visibility: hidden;
}
