@import "Variables.less";
@import "Utilities.less";
@import '../bootstrap-3.4.1/less/variables.less';
@import '../bootstrap-3.4.1/less/mixins.less';

/*** Print Page ***/


.print-mode {
    background-color: white;
    -webkit-print-color-adjust: exact;
    /*.module-header {
        border: none;
        background: none;

        .sprite {
            display: none;
        }

        .debug {
            display: none;
        }
    }*/
    .PrintModuleTitle {
        // these are the overview and legend titles
        color: black;
        font-size: 10pt;
        font-weight: bold;
    }


    .print-header-block {
        height: 56px;

        img {
            float: left;
            height: 40px;
            margin-top: 6px;
        }

        .header-title {
            float: left;
            .text-overflow();
            font-size: 24px;
            line-height: 56px;
            padding: 0 10px;
        }
    }

    .print-title-block {
        .clearfix();
        color: black;
        margin-bottom: 10px;

        .PrintHeaderTitle {
            font-size: 22pt;
        }

        .PrintTitle {
            font-size: 16pt;
        }

        .PrintSubtitle {
            font-size: 12pt;
        }

        .PrintText {
        }
    }

    .print-map-detail-block {
        padding-top: 10px;

        TD {
            padding-right: 4px;
        }
    }

    .print-footer-block {
        padding-top: 10px;

        .footer-disclaimer-text {
            font-size: 7pt;
            padding-top: 4px;
        }

        .footer-last-update {
            padding: 8px 0;
            font-size: 7pt;
        }

        .footer-credits {

            img {
                width: 160px;
                height: 38px;
            }

            .clearfix();
            font-size: 7pt;
            padding-left: 0;
            padding-right: 0;

            .icon {
                font-size: 37px;
                margin-top: -6px;
                margin-right: 6px;
            }

            div {
                float: left;
            }
        }
    }

    .print-overview-crosshair {
        //background-image: url(/Images/overview_replacement.gif); -- not supported for printing
        height: 15px;
        width: 15px;
        // SUP-R HACKY, go figure, this makes it work:
        &:before {
            content: url(/Images/overview_replacement.gif);
        }
    }

    .print-overview-rectangle {
        overflow: hidden;
        //background-image: url("/Images/blank.gif");
        cursor: move;
        border: 2px dotted red;
    }

    table.footable {
        display: table;
    }
}


// support for the dropdown menu for page layouts:

.printSettingsDropdownHighlighter(@n) {

    // by default hide check mark
    #printSettingsDropdown li a span.glyphicon-ok {
        display: none;
        width: 16px;
        font-size: 11px;
        margin-left: -16px;
        margin-right: 0px;
        padding:0;
    }

    // then display the one that makes sense:
    body.print-layout-@{n} #printSettingsDropdown li.print-layout-@{n} a span.glyphicon-ok {
        display: inline-block;
    }
}

//.printSettingsDropdownHighlighter(0);
.printSettingsDropdownHighlighter(1);
.printSettingsDropdownHighlighter(2);
.printSettingsDropdownHighlighter(3);


@media print {

    @page {
        margin: 0.5in;
        orphans: 3; //minimum number of lines in a block container that must be left at the bottom of the page (default is 2)
        widows: 3; //Denotes the minimum number of lines that can stay alone on the top of a new page when a paragraph is split. (default is 2)
    }

    .avoid-page-break {
        page-break-inside:avoid;
    }

    .print-layout(@fontScale, @photoAndSketchHeight, @mainPhotoHeight) {
        font-size: @baseFontSize * @fontScale;
        line-height: @baseLineHeight * @fontScale;

        #tabAffix {
            display: none;
        }

        .page-center-pane {
            width: 100%;
        }

        .page-right-pane {
            //width:0px;
        }

        .page-center-pane {

            .module-header {
                margin-bottom: 3px;
                height: 20px;

                .title, H2 {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            .module-content {
                margin-bottom: 5px;
                margin-left: 16px;
                page-break-inside: auto;
            }
        }

        .tabular-data td, .tabular-data th {
            padding: 1px 4px;
            font-size: @baseFontSize * @fontScale;
            line-height: @baseLineHeight * @fontScale;

            .footable-toggle {
                visibility: hidden;
            }
        }
        /*.sketch-thumbnail {
            img {
                height: @photoAndSketchHeight;
                width: auto;
            }
        }*/
        .photo-thumbnail, .gen-image-thumbnail {
            img {
                height: @photoAndSketchHeight;
            }
        }

        .primary-photo-rs {
        }

        .primary-photo-rs-print {
            height: @mainPhotoHeight;
            width: auto;
            border-radius: 5px;
        }

        .beacon-header-large {
            height: 32px;

            .leftside {
                img {
                    height: 32px;
                }

                .header-title {
                    font-size: 20px;
                    line-height: 46px;
                }
            }
        }



        .footer-container {
            padding-top: 10px;
            font-size: 7pt;
            page-break-inside: avoid;
        }
    }



    .print-layout-1 {
        .print-layout(0.80, 200px, 200px);
    }

    .print-layout-2 {
        .print-layout(0.80, 120px, 120px);

        .sketch-thumbnail {
            img {
                height: 120px;
                width: auto;
            }
        }
    }

    .print-layout-3 {
        .print-layout(0.80, 0, 120px);
        // hide module with .photo-thumbnail, .gen-image-thumbnail in them
        .sketch-thumbnail {
            display: none;
        }

        // this is added in Modules\Application\ModuleSection.ascx.vb for modules with the sketch or photo subtype
        SECTION[LAYOUT=IMAGE] {
            display: none;
        }
    }
    .icon-sg-logoPrint {
        display: block;
        img.icon-sg-logo {
            width: 100%;
        }
    }
    .icon-sg-logoScreen {
        display: none;
    }
}
// ok, so here is a lovely hack that resets the grid system so that everything over 3px now appears in 100% width
@media print {
    //
    // Grid system
    // --------------------------------------------------
    // Container widths
    //
    // Set the container width, and override it for fixed navbars in media queries.
    .container {
        .container-fixed();

        @media (min-width: 1px) {
            width: 10px;
        }

        @media (min-width: 2px) {
            width: 20px;
        }

        @media (min-width: 3px) {
            width: 100%;
        }
    }
    // Fluid container
    //
    // Utilizes the mixin meant for fixed width containers, but without any defined
    // width for fluid, full width layouts.
    .container-fluid {
        .container-fixed();
    }
    // Row
    //
    // Rows contain and clear the floats of your columns.
    .row {
        .make-row();
    }
    // Columns
    //
    // Common styles for small and large grid columns
    .make-grid-columns();
    // Extra small grid
    //
    // Columns, offsets, pushes, and pulls for extra small devices like
    // smartphones.
    .make-grid(xs);
    // Small grid
    //
    // Columns, offsets, pushes, and pulls for the small device range, from phones
    // to tablets.
    @media (min-width: 1px) {
        .make-grid(sm);
    }
    // Medium grid
    //
    // Columns, offsets, pushes, and pulls for the desktop device range.
    @media (min-width: 2px) {
        .make-grid(md);
    }
    // Large grid
    //
    // Columns, offsets, pushes, and pulls for the large desktop device range.
    @media (min-width: 3px) {
        .make-grid(lg);
    }
    // and stuff from ResponsiveUtilites.less
    // For desktops
    .visible-small {
        display: none !important;
    }

    .visible-medium {
        display: none !important;
    }

    .hidden-small {
    }

    .hidden-medium {
    }

    .hidden-desktop {
        display: none !important;
    }

    .visible-desktop {
        display: inherit !important;
    }
}
